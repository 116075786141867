import { React, useEffect, useState } from "react";
import { Col, Row, Button, Badge, Form } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import Forms from "../../Landing/Form/Forms";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import "./LoginView.css";
import CheckMark from "../../../Asserts/PaymentandLogin/Checkmark.png";
import { dynamicTimeSlotsApi } from "../../../services/PaymentService";
import { useLocation } from "react-router-dom";
import moment from "moment";

let count = 0;

const LoginView = ({
  getTimeSlotFlag,
  globalCheckoutCallback,
  globalCheckoutDetails,
  productItems,
}) => {
  const [showForms, setShowForms] = useState(false);
  const handleShowForms = () => {
    setShowForms(true);
  };
  let RestUrl = sessionStorage.getItem("identifier_key")
    ? sessionStorage.getItem("identifier_key")
    : null;

  const location = useLocation();
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };

  let user = JSON.parse(sessionStorage.getItem("otpResponse"));

  const [deliveryTime, setDeliveryTime] = useState();
  const [datevalue, setDateValue] = useState(dayjs(new Date()));
  const [timeSlotState, setTimeSlotState] = useState();

  const [timeValue, setTimeValue] = useState(0);
  const estDate = (d) => {
    d = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    return d;
  };

  function isDateToday(date) {
    const otherDate = new Date(date);
    const todayDate = new Date();

    if (
      otherDate.getDate() === todayDate.getDate() &&
      otherDate.getMonth() === todayDate.getMonth() &&
      otherDate.getYear() === todayDate.getYear()
    ) {
      return "1";
    } else {
      return "3";
    }
  }

  const handleChange = (newValue) => {
    setDateValue(newValue);
    setTimeValue(0);
    sessionStorage.setItem("estimatedDate", estDate(new Date(newValue)));
    sessionStorage.setItem("estimatedDateIsToday", isDateToday(newValue));
    dynamicTimeSlotsApiValues(newValue);
    setTimeout(() => {
      handlePickupFromTo(newValue, timeValue);
    }, 10);
    globalCheckoutCallback();
  };

  const handleTimeChange = (e) => {
    setTimeValue(e.target.value);

    sessionStorage.setItem("estimatedPickUp", e.target.value);
    setTimeout(() => {
      handlePickupFromTo(datevalue, e.target.value);
    }, 10);
    globalCheckoutCallback();
    if (OrderNowalidation == "1" && orderLaterValidation != "1") {
      sessionStorage.setItem(
        "estimatedPickUp",
        orderNowPickUpTimeFrom + "-" + orderNowPickUpTimeTo
      );
    }
  };

  const handlePickupFromTo = (date, time) => {
    if (date && time) {
      var d = new Date(date);
      sessionStorage.setItem(
        "pickupFrom",
        Date.parse(
          `${d.getFullYear()}-${
            d.getMonth() + 1
          }-${d.getDate()} ${time?.substring(0, 5)}`
        ) / 1000
      );
      sessionStorage.setItem(
        "pickupTo",
        Date.parse(
          `${d.getFullYear()}-${
            d.getMonth() + 1
          }-${d.getDate()} ${time?.substring(6, time.length)}`
        ) / 1000
      );
    }
  };

  const [deliveryMessage, setDeliveryMessage] = useState();

  const getTimestamp = (newValue) => {
    if (newValue) {
      return Date.parse(newValue) / 1000;
    } else {
      return Math.floor(Date.now() / 1000);
    }
  };

  const [selfPickUpPayload, setSelfPickUpPayLoad] = useState(0);

  const handleSelfPickupToggle = (e) => {
    setSelfPickUpPayLoad(e.target.checked ? 2 : 1);
    sessionStorage.setItem("selfPickup", e.target.checked ? 2 : 1);
    globalCheckoutCallback();
  };

  //let prodCart = JSON?.parse(sessionStorage.getItem("restaurant_product_inCart"))?JSON.parse(sessionStorage.getItem("restaurant_product_inCart")):null;
  // let restHomeId =JSON?.parse(sessionStorage.getItem("restaurantData"))?JSON.parse(sessionStorage.getItem("restaurantData")):null;
 
  // let restId = prodCart;
  // if (prodCart&& prodCart !== undefined && restHomeId !== undefined) {

  //   restId = restHomeId;
 
  // } else {
  
  //   restId = prodCart;
 
  // }
  const [prodCartVal, setProdCartVal] = useState();
  let prodCart = sessionStorage.getItem("restaurant_product_inCart");
  let restHomeId = sessionStorage.getItem("restaurantData");
  let restId = prodCart;
  if (prodCart != undefined && restHomeId != undefined) {
    restId = restHomeId;
   
  } else {
   
    restId = prodCart;
   
  }

  function isDateToday(date) {
    const otherDate = new Date(date);
    
    const todayDate = new Date();
    

    if (
      otherDate.getDate() === todayDate.getDate() &&
      otherDate.getMonth() === todayDate.getMonth() &&
      otherDate.getYear() === todayDate.getYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  const dynamicTimeSlotsApiValues = async (newValue) => {
    var date = new Date(newValue);
    var seconds = 0;
    if (isDateToday(newValue)) {
      // let time = moment(new Date())
      //   ?.tz("Asia/Singapore")
      //   .format("M-D-YYYY hh:mm:ss a");
      let singaporeTime = Date.now() / 1000;
      seconds = singaporeTime;
    } else {
      // let time = moment(date)?.format("M-D-YYYY");
      let singaporeTime = new Date(date) / 1000;
      seconds = singaporeTime;
    }
    setProdCartVal(prodCart);
    let postDynamicTimeSlotsObj = {
      //  sessionStorage.getItem("restaurant_product_inCart") != "undefined"
      //     ? sessionStorage.getItem("restaurant_product_inCart")
      rest_id:
        prodCart && prodCart != "undefined"
          ? prodCart
          : sessionStorage.getItem("restaurantData")
          ? sessionStorage.getItem("restaurantData")
          : null,
      date_timestamp: seconds, //getTimestamp(newValue),
      // date_timestamp: Date.now() / 1000,
      is_self_pickup: selfPickUpPayload,
    };
    try {
      if (
        postDynamicTimeSlotsObj.rest_id != "undefined" &&
        postDynamicTimeSlotsObj.rest_id != null
      ) {
        let dynamicTimeSlotsApiResponse = await dynamicTimeSlotsApi(
          postDynamicTimeSlotsObj
        );
        setDeliveryTime(dynamicTimeSlotsApiResponse.data.data);
        setDeliveryMessage(dynamicTimeSlotsApiResponse.data?.message);
        if (
          count === 0 &&
          dynamicTimeSlotsApiResponse.data.data &&
          !window.location.href.includes("/payment")
        ) {
          let time = dynamicTimeSlotsApiResponse.data.data[0];
          var d = newValue ? new Date(newValue) : new Date();
          setTimeValue(time);
          sessionStorage.setItem(
            "pickupFrom",
            Date.parse(
              `${d.getFullYear()}-${
                d.getMonth() + 1
              }-${d.getDate()} ${time?.substring(0, 5)}`
            ) / 1000
          );
          sessionStorage.setItem(
            "pickupTo",
            Date.parse(
              `${d.getFullYear()}-${
                d.getMonth() + 1
              }-${d.getDate()} ${time?.substring(6, time.length)}`
            ) / 1000
          );
          sessionStorage.setItem("estimatedPickUp", time);
          if (time) {
            count = count + 1;
          }
        }
        // else if (
        //   dynamicTimeSlotsApiResponse.data.data &&
        //   !window.location.href.includes("/payment")
        // ) {
        //   let time = dynamicTimeSlotsApiResponse.data.data[0];
        //   setTimeValue(
        //     sessionStorage.getItem("estimatedPickUp")
        //       ? sessionStorage.getItem("estimatedPickUp")
        //       : time
        //   );
        //   sessionStorage.setItem(
        //     "estimatedPickUp",
        //     sessionStorage.getItem("estimatedPickUp")
        //       ? sessionStorage.getItem("estimatedPickUp")
        //       : time
        //   );
        // }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (!window.location.href.includes("/payment")) {
      sessionStorage.setItem(
        "estimatedDate",
        sessionStorage.getItem("estimatedDate")
          ? sessionStorage.getItem("estimatedDate")
          : estDate(new Date())
      );
      sessionStorage.setItem("estimatedDateIsToday", "1");
    }
    setDateValue(
      sessionStorage.getItem("estimatedDate")
        ? sessionStorage.getItem("estimatedDate")
        : datevalue
    );
    setTimeValue(
      sessionStorage.getItem("estimatedPickUp")
        ? sessionStorage.getItem("estimatedPickUp")
        : timeValue
    );
  }, []);

  let timeSlot = sessionStorage.getItem("restaurant_product_inCart")
    ? sessionStorage.getItem("restaurant_product_inCart")
    : sessionStorage.getItem("restaurantData")
    ? sessionStorage.getItem("restaurantData")
    : null;
  let cartRestId = sessionStorage.getItem("restaurant_product_inCart")
    ? sessionStorage.getItem("restaurant_product_inCart")
    : null;

  useEffect(() => {
    if (location.pathname != "/payment") {
      dynamicTimeSlotsApiValues(
        sessionStorage.getItem("estimatedDate")
          ? sessionStorage.getItem("estimatedDate")
          : datevalue
      );
    }
  }, [
    selfPickUpPayload,
    sessionStorage.getItem("restaurantData"),
    sessionStorage.getItem("restaurant_product_inCart"),
  ]);

  useEffect(() => {
    let timeSlotData =
      (deliveryTime && Object.keys(deliveryTime).length === 0) || !deliveryTime
        ? true
        : false;
    getTimeSlotFlag(timeSlotData);
  }, [deliveryTime]);
  let selfPickUpValidation = globalCheckoutDetails?.restaurant_product_inCart
    ?.is_self_pickup_accept
    ? globalCheckoutDetails?.restaurant_product_inCart?.is_self_pickup_accept
    : productItems?.is_self_pickup_accept;
  let OrderNowalidation = globalCheckoutDetails?.restaurant_product_inCart
    ?.is_order_now_accept
    ? globalCheckoutDetails?.restaurant_product_inCart?.is_order_now_accept
    : productItems?.is_order_now_accept;
  let orderLaterValidation = globalCheckoutDetails?.restaurant_product_inCart
    ?.is_order_later_accept
    ? globalCheckoutDetails?.restaurant_product_inCart?.is_order_later_accept
    : productItems?.is_order_later_accept;
  const dateString = globalCheckoutDetails?.pickup_time_from;
  const pickupTimeTo = globalCheckoutDetails?.pickup_time_to;

  let orderNowPickUpTimeFrom = new Date(dateString * 1000).toLocaleTimeString(
    [],
    { hour: "2-digit", minute: "2-digit", hour12: false }
  );
  let orderNowPickUpTimeTo = new Date(pickupTimeTo * 1000).toLocaleTimeString(
    [],
    { hour: "2-digit", minute: "2-digit", hour12: false }
  );

  return (
    <>
      <>
        {selfPickUpValidation == "1" ? (
          <div className="BadgeContactLessPayment">
            <Badge className="contactless mt-4">
              <div className="contentContainerPayment">
                <p className="contactLessPayment slfPckupTxt">
                  Self Pick-up &{" "}
                  <span className="saveMoreTxtcolor">Save More</span>
                </p>
                {/* <p className="contactLessInformationPayment">
                {" "}
                To keep You safe,the rider will place your order at your
              </p> */}
              </div>
              <div>
                <Switch
                  onChange={(e) => {
                    handleSelfPickupToggle(e);
                  }}
                  value={selfPickUpPayload}
                  checked={
                    sessionStorage.getItem("selfPickup") == 2 ? true : false
                  }
                  disabled={
                    window.location.href.includes("/payment") ||
                    ((sessionStorage.getItem("isDirectLanding")
                      ? JSON.parse(sessionStorage.getItem("isDirectLanding"))
                        ? JSON.parse(
                            sessionStorage.getItem("isDirectLanding")
                          ) == 1
                        : null
                      : null) &&
                      (sessionStorage.getItem("userLocation")
                        ? Object.keys(
                            JSON.parse(sessionStorage.getItem("userLocation"))
                          )?.length == 0
                        : null))
                  }
                />
              </div>
            </Badge>
          </div>
        ) : null}
        <div
          className={
            globalCheckoutDetails?.restaurant_detail?.is_self_pickup_accept ==
            "1"
              ? ""
              : "spaceSelfPickup"
          }
        ></div>
        <Row className="deliveryTimePayment">
          <Col lg="12" md="12" sm="12" xs="12">
            <small>
              <b>Estimated Time:</b>
            </small>
          </Col>
        </Row>
        {window.location.href.includes("/payment") ? (
          <div className="formSelectContainer chckOtCntnr">
            <div className="all-date LoginAllTimeDate">
              {sessionStorage.getItem("estimatedDate")}
            </div>
            <div>{sessionStorage.getItem("estimatedPickUp")}</div>
          </div>
        ) : (
          <div className="formSelectContainer">
            <div>
              <div className="EstimatedDate">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack
                    component="form"
                    className="allign"
                    noValidate
                    spacing={3}
                  >
                    <DesktopDatePicker
                      label=""
                      disabled={
                        (OrderNowalidation == "1" &&
                          orderLaterValidation != "1") ||
                        ((sessionStorage.getItem("isDirectLanding")
                          ? JSON.parse(
                              sessionStorage.getItem("isDirectLanding")
                            )
                            ? JSON.parse(
                                sessionStorage.getItem("isDirectLanding")
                              ) == 1
                            : null
                          : null) &&
                          (sessionStorage.getItem("userLocation")
                            ? Object.keys(
                                JSON.parse(
                                  sessionStorage.getItem("userLocation")
                                )
                              )?.length == 0
                            : null))
                      }
                      inputFormat="MM/DD/YYYY"
                      value={datevalue}
                      minDate={new Date()}
                      onChange={(e) => handleChange(e)}
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
            </div>

            {deliveryTime && Object.keys(deliveryTime).length !== 0 ? (
              <div>
                {OrderNowalidation == "1" &&
                orderLaterValidation != "1" &&
                orderNowPickUpTimeFrom != "Invalid Date" ? (
                  <Form.Select
                    aria-label="Default select example"
                    className="selectBoxPaymentTwo"
                    onChange={handleTimeChange}
                    value={timeValue}
                    disabled={
                      window.location.href.includes("/payment") ||
                      (OrderNowalidation == "1" && orderLaterValidation != "1")
                    }
                  >
                    <option>
                      {orderNowPickUpTimeFrom + "-" + orderNowPickUpTimeTo}
                    </option>
                  </Form.Select>
                ) : (
                  <Form.Select
                    aria-label="Default select example"
                    className="selectBoxPaymentTwo"
                    onChange={handleTimeChange}
                    value={timeValue}
                    disabled={
                      window.location.href.includes("/payment") ||
                      (OrderNowalidation == "1" && orderLaterValidation != "1")
                    }
                  >
                    {deliveryTime?.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Form.Select>
                )}
              </div>
            ) : (
              <p>{deliveryMessage} </p>
            )}
          </div>
        )}

        <hr className="hrLinepayment mb-4 mt-5" />
      </>

      <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />
    </>
  );
};
export default LoginView;
