import { React, useState, useEffect } from "react";
import { Container, Col, Row, Button, Card, Badge } from "react-bootstrap";
import "./AllRestaurent.css";
import { useNavigate } from "react-router-dom";
import {
  searchRestaurantsApi,
  whishListPostApi,
} from "../../../services/HomePageServices";
import { useSelector, useDispatch } from "react-redux";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { GrStar } from "react-icons/gr";
import { restaurantIdDataMethod } from "../../../containers/app/features/CounterSlice";
import Carousel from "react-bootstrap/Carousel";
import RatingStart from "../../../Asserts/RestaurentList/RatingStar.png";
import {
  deliverLatLngData,
  sessionLocationData,
} from "../../../constants/Utils";

function All_restaurent({}) {
  let navigate = useNavigate();
  let delLattitude = deliverLatLngData()?.lat;
  let delLongtitude = deliverLatLngData()?.lng;

  const [postAllRestaurant, setPostAllRestaurant] = useState({
    page: 0,
    lat: delLattitude,
    lng: delLongtitude,
    identifier_key: "",
  });
  const [postAllResponse, setPostAllResponse] = useState([]);
  const [lessMoreCount, setLessMoreCount] = useState(0);
  const params = new URLSearchParams();
  let Navigate = useNavigate();

  const ShowRestaurantData = (item) => {
    sessionStorage.setItem("setTabValue", 0);

    // navigate("/restaurants");
    dispatch(restaurantIdDataMethod(item.restaurant_id));
    sessionStorage.setItem("restaurantData", item.restaurant_id);
    sessionStorage.setItem("identifier_key", item.rest_identifier);
    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;
    sessionStorage.setItem("isDirectLanding", 2);

    Navigate({
      pathname: `/restaurants/${RestUrl}`,
      search: params.toString(), // '?name=John&age=32
    });
  };

  const postAllRestaurantDataApi = async (flag, data) => {
    try {
      let bestSellerDataResponse = await searchRestaurantsApi(data);
      let resultResp = bestSellerDataResponse.data.data.all_restaurant;
      if (flag) {
        setPostAllResponse(resultResp);
      } else {
        if (resultResp.length > 0) {
          setPostAllResponse([...postAllResponse, ...resultResp]);
          //
        }
      }

      let lessMoreCountValue = 0;
      if (resultResp?.length > 0) {
        lessMoreCountValue = resultResp?.length % 8;
        setLessMoreCount(lessMoreCountValue);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (postAllRestaurant?.page > 0) {
      postAllRestaurantDataApi(undefined, postAllRestaurant);
    }
  }, [postAllRestaurant]);

  useEffect(() => {
    setPostAllRestaurant({
      ...postAllRestaurant,
      lat: delLattitude,
      lng: delLongtitude,
    });
    postAllRestaurantDataApi(undefined, {
      page: 0,
      lat: delLattitude,
      lng: delLongtitude,
      identifier_key: "",
    });
  }, [delLattitude, delLongtitude]);

  const viewMoreButton = () => {
    setPostAllRestaurant({
      ...postAllRestaurant,
      page: postAllRestaurant.page + 1,
    });
  };

  const dispatch = useDispatch();
  const [isMobileAllRestaurant, setIsMobileAllRestaurant] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsMobileAllRestaurant(true);
    }
  }, []);

  const handleFavourites = async (isfavourite, item) => {
    let postData = {
      restaurant_id: item.restaurant_id,
      action_type: isfavourite,
    };
    let wishListResp = await whishListPostApi(postData);
    if (wishListResp) {
      postAllRestaurantDataApi(true, postAllRestaurant);
    }
  };
  return (
    <>
      <Container>
        <h2 className="mb-3">All Restaurants</h2>
        {!isMobileAllRestaurant ? (
          <Row className="mt-4">
            {postAllResponse?.map((item, index) => (
              //Card Size splits 3 - 3
              <Col
                lg="3"
                mb="3"
                sm="3"
                className="restaurant-card d-flex grid-margin mb-5 gap-3"
              >
                <Card
                  className="TrendingCardHomepage d-flex align-items-stretch"
                  onClick={() => {
                    ShowRestaurantData(item);
                  }}
                >
                  <img
                    src={item.banner_image}
                    alt=""
                    className="
   trendingImage 
   img-responsive img-portfolio img-hover
   img-fluid "
                  />
                  {item.min_order_value > 0 ? (
                    <Badge
                      className="minimum_value_trending mb-3"
                      variant="outlined"
                    >
                      Minimum Order Value: S${item.min_order_value}
                    </Badge>
                  ) : null}

                  {item.delivery_handled_by === "1" ? (
                    <Badge
                      className="DeliveryHandledByTrending mb-3"
                      variant="outlined"
                    >
                      <marquee> Delivery Handled By {item.rest_name}</marquee>
                    </Badge>
                  ) : null}
                  {item.is_order_later_accept == "1" &&
                  item.restro_is_open != "1" ? (
                    <Badge className="PreOrderLable">Preorder</Badge>
                  ) : null}
                  <Row
                    className={item.restro_is_open != "1" ? "RestCloseDiv" : ""}
                  >
                    {/* inside card splitting size 8-4 */}
                    <Col lg="8" md="8" sm="8" xs="8">
                      <div className="ms-2 mt-2 ">
                        <p className=" Restaurent_Title"> {item.rest_name}</p>
                        {/* <br /> */}
                        {item.next_open_time !== "" ? (
                          <Badge className="openBackTimeBadge">
                            <small className="OpenBackTime">
                              {item.next_open_time !== ""
                                ? "Opening Back" + " " + item.next_open_time
                                : null}
                            </small>
                          </Badge>
                        ) : null}

                        <p className="mb-2 Restaurent_SubTitle text-muted">
                          {item.res_description}
                        </p>
                      </div>

                      <Badge
                        className="delivery_by mt-2 mb-5"
                        variant="outlined"
                      >
                        {item.Delivery_by}
                      </Badge>
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      {item.restro_is_open === "1" ? (
                        <Badge className="restOpenTrending Restaurent_Open_Close  mt-2">
                          <small>Open</small>
                        </Badge>
                      ) : (
                        <Badge className="Restaurent_Close_home ">
                          <small>Closed</small>
                        </Badge>
                      )}
                      <br />
                      <small>
                        <img
                          src={RatingStart}
                          alt=""
                          className="Restaurent_Rating_star RatingStarTrending mx-1 pb-1"
                        />
                        <small className="RatingCountItemsRestaurant">
                          {item.avg_rating}/5
                        </small>
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="7" xs="8" md="6" sm="7">
                      {item.open_time !== "" || item.close_time !== "" ? (
                        <Badge
                          className="open_hours_badge openHoursTrending  ms-2"
                          variant="outlined"
                        >
                          {"Open Hours-" +
                            item.open_time +
                            "-" +
                            item.close_time}
                        </Badge>
                      ) : null}
                    </Col>
                    <Col lg="4" md="4" xs="4" sm="4">
                      <Badge className="deliveryTrendRest delivery_time  deliveryTrending">
                        {item.del_prep_time} | {item.distance}km{" "}
                      </Badge>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="7" xs="6" md="7" sm="6">
                      <div className="ms-2 ">
                        <Badge
                          className="break_hours_badge openHoursTrending  mb-3"
                          variant="outlined"
                        >
                          {item.break_start_time !== ""
                            ? "Break Hours-" +
                              item.break_start_time +
                              "-" +
                              item.break_end_time
                            : null}
                        </Badge>
                      </div>
                    </Col>
                    <Col lg="3" />
                  </Row>
                  <Badge
                    className="Favourite_Badge_HomePage"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.isWishList === "1" ? (
                      <AiFillHeart
                        className="favourite"
                        onClick={() => handleFavourites(2, item)}
                      />
                    ) : (
                      <AiOutlineHeart
                        className="favourite"
                        onClick={() => handleFavourites(1, item)}
                      />
                    )}
                  </Badge>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="mobileViewScrollTrending mt-4">
            {/* <Carousel className="trendingCarousel"> */}
            {postAllResponse?.map((item, index) => (
              //Card Size splits 3 - 3
              // <Carousel.Item>
              <div>
                <Col
                  lg="3"
                  mb="3"
                  sm="3"
                  xs="12"
                  className="TrendingCard_Homepage d-flex grid-margin mb-3 gap-3"
                >
                  <Card
                    className="TrendingCardHomepage"
                    onClick={() => {
                      ShowRestaurantData(item);
                    }}
                  >
                    <img
                      src={item.banner_image}
                      alt=""
                      width={250}
                      height={250}
                      className="trendingImage"
                    />
                    {item.min_order_value > 0 ? (
                      <Badge
                        className="minimum_value_trending mb-3"
                        variant="outlined"
                      >
                        Minimum Order Value: S${item.min_order_value}
                      </Badge>
                    ) : null}

                    {item.delivery_handled_by === "1" ? (
                      <Badge
                        className="DeliveryHandledByTrending mb-3"
                        variant="outlined"
                      >
                        <marquee> Delivery Handled By {item.rest_name}</marquee>
                      </Badge>
                    ) : null}
                    {item.is_order_later_accept == "1" &&
                    item.restro_is_open != "1" ? (
                      <Badge className="PreOrderLable">Preorder</Badge>
                    ) : null}
                    <Row
                      className={
                        item.restro_is_open != "1" ? "RestCloseDiv" : ""
                      }
                    >
                      <Col lg="8" sm="8" md="8" xs="9">
                        <div className="ms-2 mt-2 ">
                          <p className=" Restaurent_Title"> {item.rest_name}</p>
                          {/* <br /> */}
                          {item.next_open_time !== "" ? (
                            <Badge className="openBackTimeBadge">
                              <small className="OpenBackTime">
                                {item.next_open_time !== ""
                                  ? "Openning Back" + " " + item.next_open_time
                                  : null}
                              </small>
                            </Badge>
                          ) : null}

                          <p className="mb-2 Restaurent_SubTitle text-muted">
                            {item.res_description}
                          </p>
                        </div>

                        <Badge
                          className="delivery_by mt-2 mb-5"
                          variant="outlined"
                        >
                          {item.Delivery_by}
                        </Badge>
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="3">
                        {item.restro_is_open === "1" ? (
                          <Badge className="Restaurent_Open_Close restaurantCloseMobile mt-2">
                            <small>Open</small>
                          </Badge>
                        ) : (
                          <div className="RestCloseDiv">
                            <Badge className="Restaurent_Close_home ">
                              <small>Closed</small>
                            </Badge>
                          </div>
                        )}
                        <br />
                        <small className="rating_count_homepage">
                          <GrStar className="Restaurent_Rating_star mx-1" />
                          <small className="itemRatingRestHome">
                            {item.avg_rating}/5
                          </small>
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7" xs="7" md="6" sm="6">
                        {item.open_time !== "" || item.close_time !== "" ? (
                          <Badge
                            className="open_hours_badge ms-2"
                            variant="outlined"
                          >
                            {"Open Hours-" +
                              item.open_time +
                              "-" +
                              item.close_time}
                          </Badge>
                        ) : null}
                      </Col>
                      <Col lg="4" xs="5">
                        <Badge className="delivery_time deliveryTimeMobile">
                          {item.del_prep_time} | {item.distance}km{" "}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7">
                        <div className="ms-2">
                          <Badge
                            className="break_hours_badge  mb-3"
                            variant="outlined"
                          >
                            {item.break_start_time !== ""
                              ? "Break Hours-" +
                                item.break_start_time +
                                "-" +
                                item.break_end_time
                              : null}
                          </Badge>
                        </div>
                      </Col>
                      <Col lg="3" />
                    </Row>
                    <Badge
                      className="Favourite_Badge_HomePage"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.isWishList === "1" ? (
                        <AiFillHeart
                          className="favourite"
                          onClick={() => handleFavourites(2, item)}
                        />
                      ) : (
                        <AiOutlineHeart
                          className="favourite"
                          onClick={() => handleFavourites(1, item)}
                        />
                      )}
                    </Badge>
                  </Card>
                </Col>
              </div>
              // </Carousel.Item>
            ))}
            {/* </Carousel> */}
          </div>
        )}
      </Container>

      {/* --------------------------------------- VIEW MORE BUTTON ---------------------------------------------- */}
      {lessMoreCount === 0 ? (
        <section>
          <Container>
            <Row>
              <Col xs="12" className="mb-3 text-center">
                <Button
                  className="view_more"
                  id="viewmore"
                  onClick={viewMoreButton}
                >
                  <small className="viewMore_AllRestaurant">View More</small>
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
      ) : null}
    </>
  );
}
// const mapDispatchToProps = (dispatch) => {
//   return {
//     restaurentValues: (data) => dispatch(restaurentValues(data)),
//   };
// };
// connect(null,mapDispatchToProps)
export default All_restaurent;
