import React from 'react'
import {Container,Row} from'react-bootstrap'

function HorizantalBorder() {
  return (
    <>
    <Container>
    <Row>
<hr className='mx-1' />
</Row>
    </Container>
   
    </>
  )
}

export default HorizantalBorder