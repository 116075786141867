import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import desi from "../../../Asserts/Frame 37 (1).png";
import Location from "../Location/Location";
import "./DesiEatsImage.css";


function DesiEatsImage() {
  let navigate = useNavigate();
  
  const restaurantLocation = useLocation();
  const [showLocation, setShowLocation] = useState(false); //Location modal
  const LocationPopUp = (value) => {
    setShowLocation(value);
  };

  const navigateHome = () => {
    if (JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&  Object.keys(JSON.parse(sessionStorage.getItem("userLocation"))).length==0  ) {
      setShowLocation(true);

    }else if (restaurantLocation.pathname !== "/"&& restaurantLocation.pathname !== "/home") {
      navigate("/home");
      window.scrollTo(0, 0);

    }
    const otpResponse = sessionStorage.getItem("emailVerified");
  if (otpResponse) {
    // If otpResponse exists in sessionStorage, navigate to "/home"
    navigate("/home");
    window.scrollTo(0, 0);
  } else {
    
    navigate("/");
    window.scrollTo(0, 0);
  }

  };

  return (
    <>
      <div className="logo-container">
        <img
          src={desi}
          className="DesiEatsImageLanding"
          alt=""
          onClick={navigateHome}
        ></img>
      </div>
      <Location
    showLocation={showLocation}
    LocationPopUp={LocationPopUp}/>
    </>
  
  
  );
}

export default DesiEatsImage;
