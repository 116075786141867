import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "../src/containers/app/App.js";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router basename='/'>
      <App />
    </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
