import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import { getUserData } from '../../constants/Utils'
import { useSelector } from "react-redux";

function ProtectedRoute({children}) {
    let location = useLocation();
    const itemTotal = sessionStorage.getItem("itemTotal")
   // itemTotal===""||itemTotal===0||itemTotal===null||itemTotal===undefined

    if(sessionStorage.getItem("paymentPage")==1){
        return <Navigate to="/" state={{ from: location}} replace />

    }
    return children

};

export default ProtectedRoute