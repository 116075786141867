import { Container, Col, Row, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {FaRegEye, FaRegEyeSlash, FaTelegramPlane } from "react-icons/fa";

import LandingHeader from "../../Landing_header/Landingheader";

import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

import { ResetPasswordApi } from "../../../../services/Landingservice";
import { useState } from "react";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [togglePass, setTogglePass] = useState(false);
  const [toggleConfirmPass, setToggleConfirmPass] = useState(false);
  const { id,role } = useParams(); 

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      cnfpassword: "",
    },
    validationSchema: yup.object({
      newpassword: yup
        // .string()
        // .required("New Password is required")
        // .min(8, "Password must be at least 8 characters")
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        //   "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        // ),
        .string()
        .required("New Password is required!"), 
      cnfpassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("newpassword"), null], "Passwords must match"),
    }),
  });

  const resetPaswordApi = async (e) => {
    e.preventDefault();

    // console.log("formik values",postConatctUsObj);

    let postConatctUsObj = {
    
      //  user_id: 14,
      confirm_password: formik.values.cnfpassword,
      new_password: formik.values.newpassword,
      token:id,
      app_id: "2",
      role:role

    };

    console.log("formik values", postConatctUsObj);

    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {

        let resetPasswordResponse = await ResetPasswordApi(postConatctUsObj);

        console.log("resetPassword",resetPasswordResponse);
       if (resetPasswordResponse.data.status === 200) {
        swal({
          title: "Success!",
          text: resetPasswordResponse.data.message,
          type: "success",
          timer: 2500,
          showCancelButton: true,
          showConfirmButton: true,
          // confirmButtonText: "OK",
          button: false,
          className: "popuptetx",
          confirmButtonColor: "#8CD4F5",
          icon: "success",
        });
        formik.resetForm();
        navigate("/");

       } else {
        swal({
          title: "Error!",
          text: resetPasswordResponse.data.message,
          type: "Error",
          timer: 2500,
          showCancelButton: true,
          showConfirmButton: true,
          // confirmButtonText: "OK",
          button: false,
          className: "popuptetx",
          confirmButtonColor: "#8CD4F5",
          icon: "error",
        });
        formik.resetForm();
        // navigate("/");
        
       }
       
      }
    } catch (e) {}
  };

  return (
    <>
      <LandingHeader />
      <div>
        <Container style={{ marginTop: "150px" }}>
          <section className="bg-ct-blue-600 min-h-screen grid place-items-center">
            <div className="w-full">
              {/* <h1 className="text-4xl xl:text-6xl text-center font-[600] text-ct-yellow-600 mb-7 contact">
                Reset Password
              </h1> */}
              <h1 className="contact">Reset Password</h1>
            <hr size="8" width="90%" className="contactUsformLine" />
              {/* <h1 className="contact">Contact Us</h1> */}
            <hr size="8" width="90%" className="contactUsformLine" />
              <Row>
                <form
                  //    onSubmit={formik.handleSubmit}
                  className="ContactUsForm"
                >
                   <div className="name mt-4" style={{position:"relative"}}>
                   <Form.Control
                    type={togglePass ? "text" : "password"}
                    className=""
                    placeholder="New Password"
                    id="newpassword"
                    name="newpassword"
                    {...formik.getFieldProps("newpassword")}
                  />
                  <div
                className="toggle-pass pass-change-toggle"
                onClick={() => {
                  setTogglePass(!togglePass);
                }}
              >
                {togglePass ? <FaRegEye size={25} /> : <FaRegEyeSlash size={25} />}
              </div>
                  {formik.touched.newpassword && formik.errors.newpassword && (
                    <div className="ms-3 mt-1" style={{ color: "red" }}>
                      {formik.errors.newpassword}
                    </div>
                  )}
                   </div>
                 
                   <div className="name mt-4" style={{position:"relative"}}>
                   <Form.Control
                    type={toggleConfirmPass ? "text" : "password"}
                    className=""
                    placeholder="Confirm Password"
                    id="cnfpassword"
                    name="cnfpassword"
                    {...formik.getFieldProps("cnfpassword")}
                  />
                  <div
                className="toggle-pass pass-change-toggle"
                onClick={() => {
                  setToggleConfirmPass(!toggleConfirmPass);
                }}
              >
                {toggleConfirmPass ? <FaRegEye size={25} /> : <FaRegEyeSlash size={25} />}
              </div>
                  {formik.touched.cnfpassword && formik.errors.cnfpassword && (
                    <div className="ms-3 mt-1 " style={{ color: "red" }}>
                      {formik.errors.cnfpassword}
                    </div>
                  )}

                   </div>
                  

                  <Button
                    className="message_button"
                    type="submit"
                    onClick={resetPaswordApi}
                  >
                    <FaTelegramPlane className="tele_icon" /> Submit
                  </Button>
                </form>
              </Row>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
};

export default ResetPasswordPage;
