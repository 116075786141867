import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getName, getUserType, landingFormsData } from "../../../constants/Utils";
import { sessionLocationData } from "../../../constants/Utils";
import Forms from "../Form/Forms";
import Location from "../Location/Location";
import LandingHeader from "./Landingheader";

function UserProfileButton() {
  const [showForms, setShowForms] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  let userName = getName() ? getName()[0] : null;
  let navigate = useNavigate();
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };
  const [resetForm, setResetForm] = useState(false);

  const LocationPopUp = (value) => {
    setShowLocation(value);
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };
  const handleShowForms = () => {
    if (landingFormsData() === null || landingFormsData() === undefined||landingFormsData()?.user_type === null||landingFormsData()?.user_type === undefined) {
      setShowForms(true);
    } else {
      let locationData = Object.keys(sessionLocationData())?.length;
      if (locationData === 0 && landingFormsData()?.user_type !== 2) {
        setShowLocation(true);
      } else {
        if (landingFormsData().user_type === 2) {
          setShowForms(true);
        } else {
          navigateToProfile();
        }

      }
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    if (windowWidth < 750) {
      setWindowWidth(false);
    }
  }, []);
  return (
    <>
      <div className="user-container">
        
          {windowWidth ? (
            <>
                      <button className="sign" onClick={handleShowForms}>
             {getUserType()!==2?userName ? <FaUserAlt className="userNameSignIn" /> : null:null}
            <small>
              {getUserType()!==2?userName ?userName :"Sign Up / Login":"Sign Up / Login"}
            </small>
            {userName === null ? (
              <BsArrowRight className="userNameSignIn" />
            
             
              ) : null}
               </button>
            </>):
          (<>
          <button className="mobileUserNameHeader" onClick={handleShowForms}>
          <FaUserAlt  className="userIconMobile"/> 

          </button>
           </>)
            }
        
      </div>
      <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />
      <Location
        showLocation={showLocation}
        LocationPopUp={LocationPopUp}
        resetForm={resetForm}
      />
    </>
  );
}

export default UserProfileButton;
