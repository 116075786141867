import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { recordTransactionDetailsApi } from "../../../services/PaymentService";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import {
  deliverLatLngData,
  deliveryAddressData,
  getName,
  getUserData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../../constants/Utils";
import { addMoneyWalletApi } from "../../../services/ProfilePageServices";
import LandingHeader from "../../Landing/Landing_header/Landingheader";
import { checkoutDetailsPostApi } from "../../../services/CartCheckOutServices";

function PaymentReview() {
  let paymentOption;
  if (sessionStorage.getItem("paymentMode")) {
    paymentOption = sessionStorage.getItem("paymentMode");
  } else if (localStorage.getItem("paymentMode")) {
    paymentOption = localStorage.getItem("paymentMode");
  }
  let navigate = useNavigate();

  const OrderPlacedSweetAlert = (status, msg) => {
    swal({
      title: `${status}!`,
      text: msg,
      type: status,
      timer: 2500,
      button: false,
      className: "popuptetx",
      icon: status,
    });
    navigate("/profile");
  };

  const AddedMoneySweetAlert = (status, msg) => {
    swal({
      title: `${status}!`,
      text: msg,
      type: status,
      timer: 2500,
      button: false,
      className: "popuptetx",
      icon: status,
    });
    navigate("/profile", { state: { payNowWallet: true } });
  };
  const [loading, setLoading] = useState(false);

  const pageRedirect = new URLSearchParams(window.location.search);
  const txn_number = pageRedirect.get("payment_intent");
  const txn_status = pageRedirect.get("redirect_status");
  const txn_client_secret = pageRedirect.get("payment_intent_client_secret");
  let userName = getName() ? getName()[0] : null;
  let dc_Amount = JSON.parse(sessionStorage.getItem("Dc_Amount"));
  const generateQuantityCartValue = (customerCheckoutScreenApiResponse) => {
    let resultList = [];
    if (customerCheckoutScreenApiResponse?.data?.data) {
      customerCheckoutScreenApiResponse.data.data.cart_products?.map((obj) => {
        let object = {
          id: obj.product_id,
          qty: obj.product_quantity,
          price: obj.offer_price > 0 ? obj.offer_price : obj.price,
          name: obj.product_name,
        };
        resultList.push(object);
      });
    }
    let sum = resultList.reduce((totalValue, currentValue) => {
      return totalValue + parseInt(currentValue.qty);
    }, 0);
    return { data: JSON.stringify(resultList), quantiy: sum };
  };
  

  const getVariantTypeData = (customerCheckoutScreenApiResponse) => {
    let variantArray = [];
    if (customerCheckoutScreenApiResponse?.data?.data) {
      customerCheckoutScreenApiResponse.data.data.cart_products?.map(
        (products) => {
          products.variants.map((variants) => {
            let varObj = {
              id: products.product_id,
              var_id: variants.variant_id,
              name: variants.variant_name,
              type_name: variants.variant_type_name,
              var_type_id: variants.variant_type_id,
              price: variants.variant_type_price,
            };
            variantArray.push(varObj);
          });
        }
      );
    }
    return JSON.stringify(variantArray);
  };

  const recordTransactionDetailsApiValues = async (
    customerCheckoutScreenApiResponse
  ) => {
    setLoading(true);

    let globalCheckoutDetails = "";
    if (sessionStorage.getItem("globalCheckoutDetails")) {
      globalCheckoutDetails = JSON.parse(
        sessionStorage.getItem("globalCheckoutDetails")
      );
    }
  

    let recordTransactionDetailsApiObject = {
      restaurant_id: sessionStorage.getItem("restaurant_product_inCart")
        ? sessionStorage.getItem("restaurant_product_inCart")
        : null,
      street_address: deliverLatLngData()?.street_address
        ? deliverLatLngData()?.street_address
        : sessionLocationData()?.street_address,
      delivery_address: JSON.parse(
        sessionStorage.getItem("deliveryAddressPay")
      ),
      is_cutlery_needed: sessionStorage.getItem("isCutlery") === "true" ? 1 : 2,
      lalamove_order_failed_reason:
        globalCheckoutDetails?.lalamove_order_failed_reason,
      lalamove_order_id: globalCheckoutDetails?.lalamove_order_reference_id,
      ordering_platform: 3,
      delivery_name: userName ? userName : null,
      remark: sessionStorage.getItem("instructionInput")
        ? sessionStorage.getItem("instructionInput")
        : "",
      delivery_mobile: getUserData()?.mobile,
      unit_number: deliverLatLngData()?.unit_number
        ? deliverLatLngData()?.unit_number
        : sessionLocationData()?.unit_number,
      delivery_charge_paid: dc_Amount,
      promo_subtotal_discounted_value: sessionStorage.getItem("subtotalPromo")
        ? sessionStorage.getItem("subtotalPromo")
        : 0, //promocode_details?.discount_value
      is_wallet_used: 2,
      pickup_time_from: sessionStorage.getItem("pickupFrom")
        ? sessionStorage.getItem("pickupFrom")
        : singaporeTimeConverter(),
      order_type:
        sessionStorage.getItem("selfPickup") &&
        sessionStorage.getItem("selfPickup") === "2"
          ? sessionStorage.getItem("selfPickup")
          : sessionStorage.getItem("estimatedDateIsToday"),
      delivery_latitude: deliverLatLngData()?.lat
        ? deliverLatLngData()?.lat
        : sessionLocationData()?.latitude,
      promo_subtotal_code_id: sessionStorage.getItem("subtotalPromoId")
        ? sessionStorage.getItem("subtotalPromoId")
        : globalCheckoutDetails?.promocode_details[0]?.id
        ? globalCheckoutDetails?.promocode_details[0]?.id
        : "0", //promocode_details id
      actual_dc_amount:
        globalCheckoutDetails?.delivery_handled_by == "1"
          ? dc_Amount
          : globalCheckoutDetails?.lalamove_order_amount,
      payment_mode: paymentOption, // 1 Credit Card, 2: PayNow
      promo_dc_is_applied: globalCheckoutDetails?.delivery_charge_promotion?.id
        ? "1"
        : "2", //1 apply 2 not apply
      business_category: JSON.parse(sessionStorage.getItem("catagoryType"))
        ? JSON.parse(sessionStorage.getItem("catagoryType"))
        : "1", //1,2,3,4
      id_qty_price: generateQuantityCartValue(customerCheckoutScreenApiResponse)
        ?.data,
      transaction_unique_number: JSON.parse(
        sessionStorage.getItem("IntentPaymentId")
      )?.paymentId,
      promo_dc_code_id: globalCheckoutDetails?.delivery_charge_promotion?.id
        ? globalCheckoutDetails?.delivery_charge_promotion?.id
        : "0", //delivery_charge_promotion?.id
      pickup_time_to: sessionStorage.getItem("pickupTo")
        ? sessionStorage.getItem("pickupTo")
        : singaporeTimeConverter(),
      promo_subtotal_is_applied: globalCheckoutDetails?.promocode_details[0]?.id
        ? "1"
        : "2", //subtotal promo applied 1.yes 2.no
      lalamove_order_status: globalCheckoutDetails?.lalamove_order_status,
      wallet_debited_value: 0.0,
      track_link: sessionStorage.getItem("lalamove_track_link"),
      total_amount: sessionStorage.getItem("ActualAutoTotal"),
      delivery_email: getUserData()?.email,
      sub_total: globalCheckoutDetails?.item_total
        ? globalCheckoutDetails.item_total
        : "",
      promo_dc_discounted_value: globalCheckoutDetails
        ?.delivery_charge_promotion[0]?.discount_value
        ? globalCheckoutDetails?.delivery_charge_promotion?.discount_value
        : "0", //delivery_charge_promotion?.discount_value
      id_varid_vartype_id: getVariantTypeData(
        customerCheckoutScreenApiResponse
      ),
      postal_code: deliverLatLngData()?.postal_code
        ? deliverLatLngData()?.postal_code
        : sessionLocationData()?.postal_code,
      delivery_longitude: deliverLatLngData()?.lng
        ? deliverLatLngData()?.lng
        : sessionLocationData()?.longitude,
      item_quantity: generateQuantityCartValue(
        customerCheckoutScreenApiResponse
      )?.quantiy,
    };
   
    try {
      let recordTransactionDetailsApiResponse =
        await recordTransactionDetailsApi(recordTransactionDetailsApiObject);
      if (recordTransactionDetailsApiResponse.status === 200) {
        OrderPlacedSweetAlert("success", "Order Placed successfully!!");
        sessionStorage.setItem("selfPickup", JSON.stringify("1"));
        sessionStorage.removeItem("subtotalPromoId");
        sessionStorage.removeItem("subtotalPromo");
      } else {
        OrderPlacedSweetAlert("error", "Something went wrong!");
      }
    } catch (e) {}
    if (sessionStorage.getItem("manualpromovalues") != undefined) {
      sessionStorage.removeItem("manualpromovalues");
    }
  };

  const customerCheckoutScreenApiValues = async () => {
    let postCheckoutScreenApiObject = {
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
      order_type: selfPickUpValue() ? selfPickUpValue() : "1",
      pickup_time: sessionStorage.getItem("pickupFrom")
        ? sessionStorage.getItem("pickupFrom")
        : singaporeTimeConverter(),
      latitude: deliverLatLngData()?.lat
        ? deliverLatLngData()?.lat
        : sessionLocationData()?.latitude,
      longitude: deliverLatLngData()?.lng
        ? deliverLatLngData()?.lng
        : sessionLocationData()?.longitude,
      pin_address: deliverLatLngData()?.pin_address
        ? deliverLatLngData()?.pin_address
        : sessionLocationData()?.pin_address,
      delivery_name: userName ? userName : null,
      delivery_email: getUserData()?.email,
      delivery_mobile: getUserData()?.mobile,
      unit_number: deliverLatLngData()?.unit_number
        ? deliverLatLngData()?.unit_number
        : sessionLocationData()?.unit_number,
      street_address: deliverLatLngData()?.street_address
        ? deliverLatLngData()?.street_address
        : sessionLocationData()?.street_address,
      postal_code: deliverLatLngData()?.postal_code
        ? deliverLatLngData()?.postal_code
        : sessionLocationData()?.postal_code,
      date_timestamp: Date.now() / 1000,
    };

    try {
      let customerCheckoutScreenApiResponse = await checkoutDetailsPostApi(
        postCheckoutScreenApiObject
      );
      if (
        customerCheckoutScreenApiResponse &&
        JSON.parse(sessionStorage.getItem("IntentPaymentId"))?.paymentStatus ===
          "succeeded"
      ) {
        recordTransactionDetailsApiValues(customerCheckoutScreenApiResponse);
      }
      if (customerCheckoutScreenApiResponse?.data?.status === 200) {
        sessionStorage.removeItem("IntentPaymentId");
        sessionStorage.removeItem("PaymentMsg");
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleAddMoneyWalletApi = async () => {
    let paymentOption = JSON.parse(sessionStorage.getItem("paymentMode"));
    let obj = {
      amount: sessionStorage.getItem("walletMoney"),
      payment_method: paymentOption,
      transaction_unique_number: sessionStorage.getItem("clientSecret"),
    };
    let resp = await addMoneyWalletApi(obj);
    if (resp.data.message) {
      AddedMoneySweetAlert("success", resp.data.message);
    } else {
      AddedMoneySweetAlert("error", "Something went wrong!");
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("isWallet") === "true") {
      handleAddMoneyWalletApi();
    } else {
      customerCheckoutScreenApiValues();
    }
  }, []);

  return (
    <>
      <Row>
        <LandingHeader />
      </Row>
      <Row>
        <Col lg="12">
          <h4 className="paymentTrans">
            {loading ? <Spinner /> : null}
            Please wait while we are processing your request!
          </h4>
        </Col>
      </Row>
    </>
  );
}

export default PaymentReview;
