import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import StripePayment from "./StripePayment";
import {
  addressValuesSession,
  deliverLatLngData,
  getName,
  getUserData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../../constants/Utils";
import { checkoutDetailsPostApi } from "../../../services/CartCheckOutServices";

function Payment({
  cartValue,
  globalCheckoutDetails,
  handleClose,
  timeSlotFlag,
  item_total,
}) {
  const [temData, setTempData] = useState();
  let userName = getName() ? getName()[0] : null;
  useEffect(() => {
    const customerCheckoutScreenApiValues = async () => {
      let postCheckoutScreenApiObject = {
        restaurant_id: sessionStorage.getItem("restaurantData")
          ? sessionStorage.getItem("restaurantData")
          : null,
        order_type: selfPickUpValue() ? selfPickUpValue() : "1",
        pickup_time: sessionStorage.getItem("pickupFrom")
          ? sessionStorage.getItem("pickupFrom")
          : singaporeTimeConverter(),
        latitude: deliverLatLngData()?.latitude,
        longitude: deliverLatLngData()?.longitude,
        pin_address: sessionLocationData()?.pin_address,
        delivery_name: userName ? userName : null,
        delivery_email: getUserData()?.email,
        delivery_mobile: getUserData()?.mobile,
        unit_number: sessionLocationData()?.unit_number,
        street_address: sessionLocationData()?.street_address,
        postal_code: sessionLocationData()?.postal_code,
        date_timestamp: Date.now() / 1000,
      };

      try {
        let customerCheckoutScreenApiResponse = await checkoutDetailsPostApi(
          postCheckoutScreenApiObject
        );
        setTempData(customerCheckoutScreenApiResponse.data.data);
      } catch (e) {}
    };
    customerCheckoutScreenApiValues();
  }, []);

   sessionStorage.setItem("isWallet", "false");
  // sessionStorage.setItem("ActualAutoTotal",1)
  const data = sessionStorage.getItem("ActualAutoTotal")
    ? parseInt(sessionStorage.getItem("ActualAutoTotal"))
    : 0;
  return (
    <>
      <Row>
        <Col lg="1" />
        <Col lg="11">
          <p
            className="mb-1 choosePaymentMethod text "
            style={{
              fontWeight: "700",
              fontSize: "120%",
              textAlign: "left",
              color: "black",
            }}
          >
            Choose Payment Method
          </p>
        </Col>
      </Row>
      {data > 0 ? (
        <Row>
          <Col lg="2" />
          <Col lg="6">
            <StripePayment
              payAmount={sessionStorage.getItem("ActualAutoTotal")}
              cartValue={cartValue}
              globalCheckoutDetails={globalCheckoutDetails}
              handleClose={handleClose}
              // timeSlotFlag={timeSlotFlag}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
}

export default Payment;
