import React from 'react'
import {  Col, Row } from "react-bootstrap";
import PaymentReview from '../../components/PaymentandLogin/Payment/PaymentReview'

function PayReview() {
  return (
    <>
        <PaymentReview />
    </>
  )
}

export default PayReview