import { React, useEffect, useState } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import catagory1 from "../../../Asserts/RestaurentList/Rice Bowl.png";
import catagory2 from "../../../Asserts/RestaurentList/Shop.png";
import catagory3 from "../../../Asserts/RestaurentList/Champagne.png";
import catagory4 from "../../../Asserts/RestaurentList/Tableware.png";
import Bestseller from "../Foods/Bestseller";
import Trending from "../Foods/Trending";
import ActiveGroceries from "../../../Asserts/RestaurentList/Shop-1.png";
import ActiveDinein from "../../../Asserts/RestaurentList/Tableware-1.png";
import ActiveFoods from "../../../Asserts/RestaurentList/Rice Bowl-1.png";
import ActiveBeverages from "../../../Asserts/RestaurentList/Champagne-1.png";
import AllRestaurent from "../Foods/AllRestaurent";
import GroceryDelivery from "../Groceries/GroceryDelivery";
import Beverages from "../Beverages/Beverages";
import { useSelector, useDispatch } from "react-redux";
import DineinRestaurents from "../Dinein/DineinRestaurents";
import { restaurentByCatagoryDatas } from "../../../containers/app/features/CounterSlice";
import FoodsCatagory from "../Foods/FoodsCatagory";
import {
  restaurentByCatagory,
  productListingApi,
  homePageDetailsApi,
} from "../../../services/HomePageServices";
import "./MenuItems.css";
import BeveragesTerms from "../PopUP/BeveragesTerms";
import Carousel from "react-bootstrap/Carousel";
import {
  deliverLatLngData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../../constants/Utils";

const Menu = ({ itemIdData, foodIdData }) => {
  const [food, setFood] = useState([
    {
      imgUrl: catagory1,
      title: "Food Delivery",
      id: "1",
    },
    {
      imgUrl: catagory2,
      title: "Groceries",
      id: "2",
    },
    {
      imgUrl: catagory3,
      title: "Alcohol Delivery",
      id: "3",
    },
    {
      imgUrl: catagory4,
      title: "Dine-In",
      id: "4",
    },
  ]);

  let InActiveImgObj = {
    1: catagory1,
    2: catagory2,
    3: catagory3,
    4: catagory4,
  };
  let activeImgObj = {
    1: ActiveFoods,
    2: ActiveGroceries,
    3: ActiveBeverages,
    4: ActiveDinein,
  };

  //------------------RestaurentByCatagory API--------------------------------
  //---------------------------------------------------------
  const [restByCatagory, setRestByCatagory] = useState();
  const [isActive, setIsActive] = useState();
  const [defaultValue, setDefaultValue] = useState("all");
  const [activeIconImage, setActiveIconImage] = useState(false);
  const [restaurantId, setRestaurantId] = useState();
  const [beverageTerms, setBeverageTerms] = useState(false);
  const beverageTermsPopUpData = () => setBeverageTerms(true);
  const [popUp, setPopUpImage] = useState(true);

  const beverageTermsData = (value) => {
    setBeverageTerms(value);
  };
  const restaurantTab = (data) => {
    restaurantByCatagory(restaurantId, data);
  };

  let delLattitude = deliverLatLngData()?.lat;
 
  let delLongtitude = deliverLatLngData()?.lng;
  const [foodDatas, setFoodDatas] = useState();

  const restaurantByCatagory = async (itemId, foodTypeId) => {
    let postRestaurentCatagoryObject = {
      page: "0",
      limit: "50",
      order_type: selfPickUpValue() ? selfPickUpValue() : "1",
      lat: delLattitude,
      lng: delLongtitude,
      category_type: itemId ? itemId : "1",
      food_type: foodTypeId ? foodTypeId : "1",
      date_timestamp: singaporeTimeConverter(),
      identifier_key: "",
    };
    setFoodDatas(foodTypeId ? foodTypeId : "1");
    

    if (itemIdDatas && foodDatas) {
          try {
      let restaurantCatagoryValues = await restaurentByCatagory(
        postRestaurentCatagoryObject
      );
      sessionStorage.setItem("isBevarageVeg", itemId ? itemId : "");
      setRestByCatagory(restaurantCatagoryValues.data.data);
      itemIdData(itemId); //callback function
      foodIdData(foodTypeId ? foodTypeId : "1");
    } catch (e) {
      // console.log(e);
    }
  }
  };

  // const restaurantByCatagory = (itemId, foodTypeId) => {
  //   let postRestaurentCatagoryObject = {
  //     page: "0",
  //     limit: "10",
  //     order_type: selfPickUpValue() ? selfPickUpValue() : "1",
  //     lat: delLattitude,
  //     lng: delLongtitude,
  //     category_type: "1",
  //     food_type: "1",
  //     date_timestamp: "1648044959",
  //   };
  //   try {
  //     let restaurantCatagoryValues = restaurentByCatagory(
  //       postRestaurentCatagoryObject
  //     );
  //     setRestByCatagory(restaurantCatagoryValues.data.data);
  //   } catch (e) {
  //     // console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   restaurantByCatagory();
  // }, []);

  const [bannerImage, setBannerImage] = useState();

  useEffect(() => {
    const homePageDetailsApiVals = async (itemId, foodTypeId) => {
      let postHomePageDetailsyObject = {
        page: "0",
        limit: "100",
        order_type: selfPickUpValue() ? selfPickUpValue() : "1",
        lat: delLattitude,
        lng: delLongtitude,
        category_type: itemId,
        food_type: foodTypeId ? foodTypeId : "1",
        date_timestamp: singaporeTimeConverter(),
      };
      try {
        let homePageDetailsValues = await homePageDetailsApi(
          postHomePageDetailsyObject
        );
        setBannerImage(homePageDetailsValues.data.data);
      } catch (e) {}
    };
    homePageDetailsApiVals();
  }, []);
  const BannerImageData = bannerImage?.ad_banners;
  const dispatch = useDispatch();
  dispatch(restaurentByCatagoryDatas(restByCatagory));

  const [checkBoxState, setChckBoxState] = useState(false);
  const [acceptButtonPopup, setAcceptButtonPopup] = useState();

  useEffect(() => {
    if (acceptButtonPopup && restaurantId === "3") {
      setDefaultValue("beverages");
      setIsActive("3");
    } else {
      setDefaultValue("all");
      setIsActive(null);
      HandleClick({ id: null });
    }

    if (acceptButtonPopup && checkBoxState) {
      sessionStorage.setItem("DontShowAgain", "true");
    }
  }, [acceptButtonPopup]);

  const checkBoxFunc = (data) => {
    setChckBoxState(data);
  };

  const acceptButtonDontShow = (data) => {
    setAcceptButtonPopup(data);
  };


  const [itemIdDatas, setItemIdData] = useState(0);
  
  useEffect(() => {
    if (itemIdDatas && foodDatas) {
      restaurantByCatagory();
    }
  }, [delLattitude]);
  const HandleClick = (item) => {
    setItemIdData(item.id ? item.id : "1");
   

    let itemId = isActive == item.id ? null : item.id;
    sessionStorage.setItem("catagoryType", JSON.stringify(itemId));

    setActiveIconImage(true);
    if (itemId) {
      switch (itemId) {
        case "1":
          setIsActive(itemId);
          setDefaultValue("restaurent");
          break;
        case "2":
          setIsActive(itemId);
          setDefaultValue("grocery");
          break;
        case "3":
          if (
            sessionStorage.getItem("DontShowAgain") === "false" ||
            sessionStorage.getItem("DontShowAgain") === "undefined" ||
            sessionStorage.getItem("DontShowAgain") === "" ||
            sessionStorage.getItem("DontShowAgain") === null
          ) {
            if (!acceptButtonPopup) {
              beverageTermsPopUpData();
              setIsActive(null);
              setDefaultValue("all");
            } else {
              setIsActive(itemId);
              setDefaultValue("beverages");
            }
          } else {
            setIsActive(itemId);
            setDefaultValue("beverages");
          }

          break;
        case "4":
          setIsActive(itemId);
          setDefaultValue("dinein");
          break;

        default:
          return null;
      }
    } else {
      setDefaultValue("all");
      setIsActive(itemId);
    }
   
    food.map((data, index) => {
      if (item.id == data.id) {
        food[index] = {
          ...data,
          imgUrl: itemId ? activeImgObj[item.id] : InActiveImgObj[item.id],
        };
      } else {
        food[index] = {
          ...data,
          imgUrl: InActiveImgObj[data.id],
        };
      }
      return food[index];
    });

    setRestaurantId(itemId);
    restaurantByCatagory(itemId, "1");
    if (!acceptButtonPopup) {
      setAcceptButtonPopup(null);
    }
  };
  sessionStorage.setItem("isCartRedirect", JSON.stringify(0));
  return (
    <>
      {/*----- ----------------------------CATEGORY---------------------------------------------- */}

      <Container>
        <Row className="mt-5">
          <Carousel className="BannerCarasoul mt-2">
            {BannerImageData?.map((item, index) => (
              <Carousel.Item>
                <Col lg="12" md="11" sm="11" className="mt-5 ">
                  <img
                    src={item.image_url}
                    alt=""
                    className="
         BannerImageHome banner-image
         img-responsive img-portfolio img-hover
         img-fluid "
                  />
                </Col>
              </Carousel.Item>
            ))}
          </Carousel>
        </Row>
      </Container>
      <Container>
        <div className="menu-item-container">
          {food.map((item, index) => (
            <Card
              className={
                isActive === item.id
                  ? "activeclass menu-item-card"
                  : "menu-item-card menu-item-card"
              }
              onClick={() => {
                HandleClick(item);
              }}
            >
              <div>
                <img
                  src={item.imgUrl}
                  alt=""
                  className="imagesMenuItemsHome"
                />
              </div>
              <div>
                <small className="MenuitemsTitleHome">{item.title}</small>
              </div>
            </Card>
          ))}
        </div>
      </Container>
      {defaultValue === "all" ? (
        <>
          <Trending />
          <Bestseller />
          <AllRestaurent />
        </>
      ) : null}
      {defaultValue === "restaurent" ? (
        <FoodsCatagory
          restByCatagory={restByCatagory}
          restaurantTab={restaurantTab}
        />
      ) : null}
      {defaultValue === "grocery" ? (
        <GroceryDelivery
          restByCatagory={restByCatagory}
          restaurantTab={restaurantTab}
        />
      ) : null}
      {defaultValue === "beverages" ? (
        <Beverages
          restByCatagory={restByCatagory}
          restaurantTab={restaurantTab}
        />
      ) : null}
      {defaultValue === "dinein" ? (
        <DineinRestaurents
          restByCatagory={restByCatagory}
          restaurantTab={restaurantTab}
        />
      ) : null}
      {sessionStorage.getItem("DontShowAgain") === "false" ||
      sessionStorage.getItem("DontShowAgain") === "undefined" ||
      sessionStorage.getItem("DontShowAgain") === "" ||
      sessionStorage.getItem("DontShowAgain") === null ? (
        <BeveragesTerms
          acceptButtonDontShow={acceptButtonDontShow}
          checkBoxFunc={checkBoxFunc}
          checkBoxState={checkBoxState}
          beverageTermsData={beverageTermsData}
          beverageTerms={beverageTerms}
          popUp={popUp}
        />
      ) : null}
    </>
  );
};
export default Menu;
