import { AllAddressApi } from "../../../services/ProfilePageServices";
import { React, useState, useEffect } from "react";
import { Col, Row, Button, Card, Badge } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { FaPencilAlt } from "react-icons/fa";
import {
  addDeliveryAddressApi,
  updateDeliveryAddressApi,
  deleteDeliveryAddressApi,
} from "../../../services/ProfilePageServices";
import UpdateAddressPopup from "../Popup/Address/UpdateAddressPopup";
import AddAddressPopup from "../Popup/Address/AddAddressPopup";
import Location from "../../Landing/Location/Location";
import { AllDeliveryAddress } from "../../../constants/ProfilePageResponse";
import "./Address.css";
import DeleteAccountPopUp from "../Popup/MyOrder/DeleteAccountPopUp";
import DeleteAddressPopUp from "../Popup/Address/DeleteAddressPopUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddressTypes,getUserData } from "../../../constants/Utils";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";
import moment from "moment";
import "moment-timezone";

function Address({ addressView }) {
  const [showLocation, setShowLocation] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [allAddressData, setAllAddressData] = useState([]);
  const [addressData, setAddressData] = useState();
  const [deleteAddress, setDeleteAddress] = useState(false);
  const showDeleteAddressPopupData = () => setDeleteAddress(true);

  const firebaseConfig = {
    apiKey: "AIzaSyD_FMTXLsC_dBkNPGa4BnaP65L7JvE9AO0",
    authDomain: "kerala-eats-312105.firebaseapp.com",
    projectId: "kerala-eats-312105",
    storageBucket: "kerala-eats-312105.appspot.com",
    messagingSenderId: "987804453892",
    appId: "1:987804453892:web:8f61f10109ac0707787951",
    measurementId: "G-Q6R84M0DDZ"
  };

  const DeleteAddressPopUpData = (value) => {
    setDeleteAddress(value);
  };
  const AllAddressDataApi = async () => {
    let postAllAddressObj = {
      page: "0",
      limit: "100",
    };

    try {
      let allAddressApidataResponse = await AllAddressApi(postAllAddressObj);
      setAllAddressData(allAddressApidataResponse.data.data);
    } catch (e) {}
  };

  useEffect(() => {
    AllAddressDataApi();

    if (addressView) {
      setShowLocation(true);
    }
  }, []);
  const CloseDeleteAccountPopUp = () => {
    DeleteAddressPopUpData(false); //callback function
  };
  const handleAdd = () => {
    setShowLocation(true);
  };

  const LocationPopUp = (value) => {
    setShowLocation(value);
  };

  const handleEdit = (item) => {
    setAddressData(item);
    setEditLocation(true);
  };

  const editPopUp = (value) => {
    setEditLocation(value);
  };

  //delete_delivery_address
  const [addressId, setAddressId] = useState();
  const [addressDatasVals, setAddressDatasVals] = useState();

  const handleDelete = async (item) => {
    setAddressId(item.id);


    setAddressDatasVals(item)
    
  };
  const handleDeleteAddress = async () => {

    let userId = getUserData()?.id;
    let location= getUserData()?.user_pin_address+', '+getUserData()?.user_street_address+', '+getUserData()?.user_postal_code;
    let today = moment(new Date())?.tz("Asia/Singapore").format("L");
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Analytics and get a reference to the service
    const analytics = getAnalytics(app);
    setUserId(analytics, userId);
    let time = moment(new Date())?.tz("Asia/Singapore").format("LT");
    logEvent(analytics, 'Delivery-Address', {
      event_occurred:'Before API Calling',
      user_id: userId,
      date: today,
      time: time,
      response:'',
      order_id:'',
      transaction_id:'',
      location:location,
      message: 'Trying to delete delivery address!!'
    });

    let postdeleteAddressObj = {
      address_id: addressId,
    };
    try {
      let deleteDeliveryAddressApiResponse = await deleteDeliveryAddressApi(
        postdeleteAddressObj
      );
      if (deleteDeliveryAddressApiResponse.status === 200) {
        AllAddressDataApi();
        CloseDeleteAccountPopUp();

        let time1 = moment(new Date())?.tz("Asia/Singapore").format("LT");
        logEvent(analytics, 'Delivery-Address', {
          event_occurred:'After API Calling',
          user_id: userId,
          date: today,
          time: time1,
          response:'',
          order_id:'',
          transaction_id:'',
          location:location,
          message: 'Delete delivery address successfully!!'
        });
      }
      else{
        notify(deleteDeliveryAddressApiResponse.data.message)
      }
    } catch (e) {}
  };

  const notify = (message) => toast(message);
  return (
    <>
      <div>
        <div className="title-container">
          <h2>Manage Address</h2>
        </div>
        <div>
          <div className="transaction-header-container">
            <div className="transaction-title">
              <b>All Address</b>
            </div>
          </div>
        </div>
        <div className="address-list">
          {allAddressData.map((item, index) => (
            <div className="transaction-content">
              <div className="address-display">
                <b>
                  {item.label_type === "1"
                    ? "Home"
                    : item.label_type === "2"
                    ? "Office"
                    : item.label_type === "3"
                    ? "Others"
                    : null}
                </b>
                <p>
                  {item.unit_number}, {item.street_address}, {item.pin_address},{" "}
                  {item.postal_code}
                </p>
              </div>
              <div className="address-icon">
                <AiFillDelete
                  className="Deleteicon"
                  // onClick={() => handleDelete(item)}
                  onClick={() => {
                    showDeleteAddressPopupData();
                    handleDelete(item);
                  }}
                ></AiFillDelete>
                <FaPencilAlt
                  className="EditIcon"
                  onClick={() => handleEdit(item)}
                ></FaPencilAlt>
              </div>
            </div>
          ))}
        </div>
        <div className="add-money-container">
          <Button className="add-money-btn" onClick={handleAdd}>
            Add
          </Button>
        </div>
      </div>

      <AddAddressPopup
        showLocation={showLocation}
        LocationPopUp={LocationPopUp}
        AllAddressDataApi={AllAddressDataApi}
      />
      <UpdateAddressPopup
        editLocation={editLocation}
        editPopUp={editPopUp}
        AllAddressDataApi={AllAddressDataApi}
        addressData={addressData}
      />
      <DeleteAddressPopUp
        deleteAddress={deleteAddress}
        DeleteAddressPopUpData={DeleteAddressPopUpData}
        handleDelete={handleDeleteAddress}
        addressId={addressId}
        addressDatasVals={addressDatasVals}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default Address;
