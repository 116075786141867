import React from 'react'
import "../../login.css"
import LandingHeader from '../Landing/Landing_header/Landingheader'
import axios from 'axios';
import { base_url } from '../../services/Landingservice';
import { ToastContainer, toast } from 'react-toastify';
import { getUrl } from '../../services/URl/Url';
import { useNavigate } from 'react-router-dom';

function DeleteAccount() {
  let navigate = useNavigate();
  let submitHandle = async (e) => {
    try {
      e.preventDefault();
      let token = JSON.parse(sessionStorage.getItem("otpResponse")).token;
      let id = JSON.parse(sessionStorage.getItem("otpResponse")).id;
      let header = {
        headers: {
          Authorization: token,
        },
      };
      let data = {
        user_id: id
      }
      await axios({
        // Endpoint to send files
        url: getUrl() + "/customerLogin/delete_user_account",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },

        method: "POST",
        // Attaching the form data
        data: data,
      })
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === 200) {
            navigate("/");
            sessionStorage.clear();
            window.location.reload(true);

          } else {
            toast.error(res.data.message);
          }
        });


    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <LandingHeader />

      <section className="registation_form">

        <div className="container">
          <div className="row">
            <div className="registationForm">
              {/* <h2>Account Delete</h2> */}
              <p>To delete your account please click on the button</p>
              <form
                className="regfrom"
                id="form-contact"
                action=""
                method="post"
                data-oc-toggle="ajax"
              >

                <a href="" style={{ textDecoration: "none" }} onClick={submitHandle}>
                  <div className="form-group sing del-btn" style={{ textDecoration: "none" }}>Delete Account</div>
                </a>


              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  )
}

export default DeleteAccount