import { React, useEffect, useState } from "react";
import { Container, Col, Row, Card, Badge } from "react-bootstrap";
import "./SearchItems.css";
import { useNavigate } from "react-router-dom";
import RatingStart from "../../Asserts/RestaurentList/RatingStar.png";
import { useDispatch } from "react-redux";
import { restaurantIdDataMethod } from "../../containers/app/features/CounterSlice";
import {
  homePageDetailsApi,
  whishListPostApi,
} from "../../services/HomePageServices";
import { AiFillHeart, AiOutlineHeart, AiOutlineSearch } from "react-icons/ai";

const SearchItems = ({
  names,
  searchDatas,
  searchStatus,
  searchByKeywordValuesApi,
  searchKey,
  setRecentClick,
}) => {
  function handleClick() {
    navigate("/restaurent");
  }
  let navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    }
  }, []);

  const [clickedItems, setClickedItems] = useState([]);
  const HandleClick = (item) => {
    setClickedItems((prevState) => {
      if (prevState.includes(item.restaurant_id)) {
        return prevState.filter((value) => value !== item.restaurant_id);
      }
      return [...prevState, item.restaurant_id];
    });
  };

  const dispatch = useDispatch();
  const params = new URLSearchParams();
  let Navigate = useNavigate();
  const [searchHistory, setSearchHistory] = useState([]);

  const ShowRestaurantData = (item) => {
    sessionStorage.setItem("restaurantData", item.restaurant_id);
    sessionStorage.setItem("identifier_key", item.rest_identifier);
    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;
    Navigate({
      pathname: `/restaurants/${RestUrl}`,
      search: params.toString(), // '?name=John&age=32
    });
    dispatch(restaurantIdDataMethod(item.restaurant_id));
  };

  const handleFavourites = async (isfavourite, item) => {
    let postData = {
      restaurant_id: item.restaurant_id,
      action_type: isfavourite,
    };
    let wishListResp = await whishListPostApi(postData);
    if (wishListResp) {
      searchByKeywordValuesApi();
    }
  };

  const dataClick = (item) => {};

  useEffect(() => {
    if (sessionStorage.getItem("searchHistory") != undefined) {
      let searchValue = JSON.parse(sessionStorage.getItem("searchHistory"));
      if (searchValue.length > 0) {
        setSearchHistory(searchValue.reverse());
      }
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col lg="5"></Col>
          <Col lg="5">
            <h2 className="mt-4">Search Results</h2>
          </Col>
        </Row>
        {searchStatus == 200 ? (
          <Row className="scrollbutton mt-4">
            {searchDatas?.map((item, index) => (
              <Col
                xl="4"
                lg="6"
                md="6"
                sm="6"
                xs="11"
                className="favouriteCardProfile d-flex grid-margin mb-5 gap-3"
              >
                <Card
                  className="TrendingCardHomepage"
                  onClick={() => {
                    ShowRestaurantData(item);
                  }}
                >
                  <img
                    src={item.banner_image}
                    alt=""
                    className="
trendingImage 
img-responsive img-portfolio img-hover
img-fluid "
                  />
                  {item.min_order_value > 0 ? (
                    <Badge
                      className="minimum_value_trending  minValueSearch mb-3"
                      variant="outlined"
                    >
                      Minimum Order Value: S${item.min_order_value}
                    </Badge>
                  ) : null}
                  {item.delivery_handled_by === "1" ? (
                    <Badge
                      className="DeliveryHandledByTrending  searchDeliveryHandle mb-3"
                      variant="outlined"
                    >
                      <marquee> Delivery Handled By {item.rest_name}</marquee>
                    </Badge>
                  ) : null}
                  {item.is_order_later_accept == "1" &&
                  item.restro_is_open != "1" ? (
                    <Badge className="PreOrderLable">Preorder</Badge>
                  ) : null}
                  <Row>
                    {/* inside card splitting size 8-4 */}
                    <Col lg="8" md="8" xs="9">
                      <div className="ms-2 mt-2 ">
                        <p className=" Restaurent_Title"> {item.rest_name}</p>
                        {/* <br /> */}
                        {item.next_open_time !== "" ? (
                          <Badge className="openBackTimeBadge">
                            <small className="OpenBackTime">
                              {item.next_open_time !== ""
                                ? "Opening Back" + " " + item.next_open_time
                                : null}
                            </small>
                          </Badge>
                        ) : null}

                        <p className="mb-2 Restaurent_SubTitle text-muted">
                          {item.res_description}
                        </p>
                      </div>

                      <Badge
                        className="delivery_by mt-2 mb-5"
                        variant="outlined"
                      >
                        {item.Delivery_by}
                      </Badge>
                    </Col>
                    <Col lg="4" md="4" xs="3">
                      {item.restro_is_open === "1" ? (
                        <Badge className="Restaurent_Open_Close mt-2">
                          <small>Open</small>
                        </Badge>
                      ) : (
                        <Badge className="Restaurent_Close_home restCloseSearch mt-2">
                          <small>Closed</small>
                        </Badge>
                      )}
                      <br />
                      <small>
                        <img
                          src={RatingStart}
                          alt="RatingCount"
                          className="Restaurent_Rating_star starSearchPayment mx-1 pb-1"
                        />
                        <small className="RatingCountItemsRestaurant">
                          {item.avg_rating}/5
                        </small>
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="7" md="4" sm="6" xs="7">
                      {item.open_time !== "" || item.close_time !== "" ? (
                        <Badge
                          className="open_hours_badge openHoursSearch ms-2"
                          variant="outlined"
                        >
                          {"Open Hours-" +
                            item.open_time +
                            "-" +
                            item.close_time}{" "}
                        </Badge>
                      ) : null}
                    </Col>
                    <Col lg="4" md="4" sm="4" xs="4">
                      <Badge className="delivery_time deliveryTimeSearch">
                        {item.del_prep_time} | {item.distance}km{" "}
                      </Badge>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="7">
                      <div className="ms-2">
                        <Badge
                          className="break_hours_badge openHoursSearch mt-2 mb-3"
                          variant="outlined"
                        >
                          {item.break_start_time !== ""
                            ? "Break Hours-" +
                              item.break_start_time +
                              "-" +
                              item.break_end_time
                            : null}
                        </Badge>
                      </div>
                    </Col>
                    <Col lg="3" />
                  </Row>
                  <Badge
                    className="Favourite_Badge_HomePage"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.isWishList === "1" ? (
                      <AiFillHeart
                        className="favourite"
                        onClick={() => handleFavourites(2, item)}
                      />
                    ) : (
                      <AiOutlineHeart
                        className="favourite"
                        onClick={() => handleFavourites(1, item)}
                      />
                    )}
                  </Badge>
                  {/* <Container>

                <div className="mobileViewScrollTrending mt-4">

               {item?.products?.map((data, index) => (

                <Card className="ProductItemCart">
                   <img
                  src={data?.product_image}
                  alt=""
                />
                  <small className="ProductNameSearch">{data?.product_name}</small>
                  <small className="PriceItemSearch">S${data?.price}</small>
                  </Card>
          ))} 
          </div>
          </Container> */}

                  <div className="container-fluid displayyy py-2">
                    <Card className="searchDataCards d-flex flex-row flex-nowrap">
                      {item?.products?.map((data, index) => {
                        return (
                          <div className="trendCard card card-body">
                            <div className="UnavailableDiv">
                              {data?.product_image ? (
                                <img
                                  src={data?.product_image}
                                  className="searchItemImage"
                                  alt=""
                                />
                              ) : null}
                              <div className="ItemDetailsSear">
                                <small className="ProductNameSearch">
                                  {data?.product_name}
                                </small>
                                <br />
                                <small className="PriceItemSearch">
                                  S${data?.price}
                                </small>
                              </div>

                              {data?.is_product_avl === 0 ? (
                                <div className="currently-unavailableSearch">
                                  Currently Unavailable
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </Card>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : !searchDatas || searchDatas.length == 0 ? (
          <>
            <p className="no-dataSearchItems">Search for restaurants or food</p>
            {searchHistory?.length > 0 && (
              <>
                <p>Recently Searched</p>
                {searchHistory?.map((element, index) => {
                  return (
                    <div key={index}>
                      {/* <div className="SearchItemHistoryDiv"> */}
                      <Row onClick={() => setRecentClick(element)}>
                        <Col lg="8">
                          <h5 className="searchHistoryName">{element}</h5>
                        </Col>
                        <Col lg="4">
                          <AiOutlineSearch className="Seach_HistoryIcon" />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </>
            )}
          </>
        ) : null}
      </Container>
    </>
  );
};

export default SearchItems;
