import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./SearchPopup.css";
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  Badge,
  Form,
  ModalTitle,
} from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { searchAutocomplete } from "../../../services/SearchPageServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchValue } from "../../../containers/app/features/CounterSlice";

function CartIconPopup({
  LocationPopUp,
  closePopUp,
  show,
  itemIdState,
  beverageTermsData,
  foodIdState,
}) {
  const [searchKey, setSearchKey] = useState();
  const [searchResp, setSearchResp] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {

  // }, [searchKey]);
 
  const searchAutocompleteValuesApi = async () => {
    let postsearchAutocompleteObject = {
      business_type: itemIdState != null ? itemIdState : 0,
      food_type:(itemIdState!= null)&&(itemIdState==1)? foodIdState :"0" ,
      keyword:searchKey?searchKey:null,
    };

    try {
      let searchAutocompleteResponse = await searchAutocomplete(
        postsearchAutocompleteObject
      );

      const searchList = [];
      if (searchAutocompleteResponse?.data?.data) {
        searchAutocompleteResponse?.data?.data.map((item, i) => {
          let obj = {
            label: item.display_name,
            id: i + 1,
          };
          searchList.push(obj);
        });
      }
      setSearchResp(searchList);
    } catch (e) {}
  };
  

  const handleClose = () => {
    closePopUp(false);
  };
 
  const location=useLocation()

  useEffect(() => {
     let sessLoc = sessionStorage.getItem("userLocation")
      ? Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))
          ?.length != 0
      : null;
    if ((show == true)  && (sessLoc) ) {
       
      beverageTermsData(searchAutocompleteValuesApi());

    }
  }, [show]);
  const handleOptionSelect = (event, newValue) => {
    setSearchKey(newValue.label);
    if(searchKey!=="undefined"){
      sessionStorage.setItem("searchKey",newValue.label)
  
    }

  };
  
  const handleNavigateSearch = () => {
    if (searchKey) {
      dispatch(searchValue(searchKey));
      navigate("/search",{ state: {  business_type: itemIdState != null ? itemIdState : 0, color: 'green' } });
    }
  };

  return (
    <>
      <Modal
        className="search-modal-container"
        show={show}
        onHide={handleClose}
        animation={true}
      >
        <Modal.Header closeButton>
          <ModalTitle>Search</ModalTitle>
        </Modal.Header>

        <Modal.Body className="SearchHeaderRow">
          <Row>
            <Col lg="9">
              <Autocomplete
                noOptionsText={"No Data Found"}
                disablePortal
                id="searchKey"
                options={searchResp}
                sx={{ width: 300 }}
                onChange={handleOptionSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="end">
                          {/* <AiOutlineSearch /> */}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="What would You like to eat today?"
                    // label="Search"
                  />
                )}
              />
            </Col>
            <Col lg="3">
              <Button
                className="SearchButtonSE"
                onClick={handleNavigateSearch}
                disabled={!searchKey}
              >
                {" "}
                <AiOutlineSearch className="SearchIconSE" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CartIconPopup;
