import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import StripePayment from "../../../PaymentandLogin/Payment/StripePayment";
import { getName } from "../../../../constants/Utils";
function PaymentPopup({ amount, closePopUp, show, refreshData }) {
  const handleClose = () => {
    closePopUp(false);
  };

  const walletClose = () => {
    closePopUp(false);
    refreshData();
  };
  let userName = getName() ? getName()[0] : null;

  sessionStorage.setItem("isWallet", "true");
  sessionStorage.setItem("walletMoney", amount)
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Row>
            <Col lg="4" />
            <Col lg="5">
              <b>Add Money</b>
            </Col>
          </Row>
          <Row>
            <Col lg="1" />
            <Col lg="7">
              <b>S${amount}</b>
              <br />
              <p>Add Money to {userName}</p>
            </Col>
          </Row>
          <Row>
            <Col lg="1" />
            <Col lg="7">
              <b>Payment Methods</b>
            </Col>
          </Row>
          <Row>
            <StripePayment
              payAmount={amount}
              isWallet={true}
              handleClose={walletClose}
            />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaymentPopup;
