import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import {
  addRemoveProductCartApi,
  addRemoveVariantCartApi,
} from "../../../../services/CartCheckOutServices";
import "./VariantPopup.css";
import Loader from "../../../Loaders/Loader";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

function VariantPopUp({
  productValues,
  itemvalues,
  closeVariantPopUp,
  isVariantAvailable,
  variantDatas,
  handleIncrement,
  handleDecrement,
  handleAdd,
  setaddRemoveVariantCount,
  globalCheckoutDetails,
  checkVariant,
  setCheckVariant,
  defaultVariant,
  setDefaultVariant,
  globalCheckoutCallback,
}) {
  const [value, setValue] = React.useState();
  const [tempObj, setTempObj] = React.useState({});
  const [variantValues, setVariantValues] = React.useState();
  const [varloading, setVarLoading] = React.useState(false);
  const [mandatory, setMandatory] = React.useState("");
  const [defVari, setDefVari] = React.useState([]);
  const [initialState, setInitialState] = useState([]);
  const [validationSchema, setValidationSchema] = useState([]);

  useEffect(() => {
    let initObj = {};
    let valSchema = {};
    variantDatas.items.map((data, index) => {
      return (initObj[data.variant_name] = "");
    });
    setInitialState(initObj);
    variantDatas.items.map((data, index) => {
      ;
      if (data.is_mandatory == "1") {
        if (data.single_select == "1") {
          return (valSchema[data.variant_name] = Yup.string().required(
            "This is a required field"
          ));
        } else {
          return (valSchema[data.variant_name] = Yup.array()
            .of(Yup.string())
            .min(1)
            .required("This is a required field"));
        }
      }
    });
    setValidationSchema(valSchema);
    
  }, [variantDatas]);

 

  const variantFormik = useFormik({
    initialValues: initialState,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({ ...validationSchema }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setVarLoading(true);
      let findProductindex = globalCheckoutDetails?.cart_products?.findIndex(
        (ele) => ele.product_id === productValues?.product_id
      );

      let formValues = [];

      if (findProductindex < 0 || findProductindex === undefined) {
       
        Object.keys(values).length > 0 &&
          Object.keys(values).map((data) => {
            let objIndex = variantDatas?.items?.findIndex(
              (ele) => ele.variant_name == data
            );
            if (typeof values[data] == "string") {
              if (objIndex >= 0) {
                let indexValue = variantDatas?.items[
                  objIndex
                ]?.sub_name?.findIndex(
                  (ele) => ele.variant_type_name == values[data]
                );
                if (indexValue >= 0) {
                  let filteredValue = variantDatas?.items[
                    objIndex
                  ]?.sub_name?.filter(
                    (ele) => ele.variant_type_name == values[data]
                  );
                  if (filteredValue.length > 0) {
                    let finalValue = filteredValue.map((data) => {
                      return {
                        product_id: productValues?.product_id,
                        variant_id: data.variant_id,
                        variant_type_id: data.variant_type_id,
                      };
                    });
                  
                    formValues.push(...finalValue);
                  }
                }
              }
            } else {
              let arrVal = variantDatas?.items[objIndex];
              let mulArray = values[data]?.map((curData) => {
                let curIndex = arrVal?.sub_name?.findIndex(
                  (ele) => ele.variant_type_name == curData
                );
                if (curIndex >= 0) {
                  return {
                    product_id: productValues?.product_id,
                    variant_id: arrVal?.sub_name[curIndex]?.variant_id,
                    variant_type_id:
                      arrVal?.sub_name[curIndex]?.variant_type_id,
                  };
                }
              });
              
              formValues.push(...mulArray);
            }
          });
      }

   
      let postpostProductListingValuesObjectValuesObject = {
        product_id: productValues?.product_id,
        restaurant_id: sessionStorage.getItem("restaurantData")
          ? sessionStorage.getItem("restaurantData")
          : null,
        is_variant_selected: formValues.length > 0 ? "1" : "2",
        variant_info: JSON.stringify(formValues),
        prod_quantity: 1,
      };
      try {
        let addRemoveProductCartApiValuesApiValuesResponse =
          await addRemoveProductCartApi(
            postpostProductListingValuesObjectValuesObject
          );

        if (addRemoveProductCartApiValuesApiValuesResponse) {
          globalCheckoutCallback();
        }
      } catch (e) {}
      setVarLoading(false);
    },
  });

  const getVariantId = (data) => {
    let name = "";
    variantDatas?.items?.map((obj1) => {
      obj1.sub_name.map((obj2) => {
        if (obj2.variant_type_name === data) {
          name = obj2;
        }
      });
    });
    return name;
  };

  useEffect(() => {
    globalCheckoutDetails?.cart_products?.map((obj, i) => {
      obj?.variants?.map((variant, i) => {
        const array1 = [variant?.variant_type_price];

        // 0 + 1 + 2 + 3 + 4
        const initialValue = 0;
        const sumWithInitial = array1.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );

       
      
      });
    });
  }, [globalCheckoutDetails]);

  useEffect(() => {
    if (checkVariant) {
      if (Object.keys(tempObj).length > 0 && tempObj.data != undefined) {
        handleAddRemoveVariantCartApi(
          tempObj.productValues,
          tempObj.obj1,
          tempObj.data,
          tempObj.checked
        );
      }
    }
  }, [checkVariant, tempObj]);

  const handleAddRemoveVariantCartApi = async (
    productValues,
    obj1,
    data,
    checked
  ) => {
    let obj = {
      product_id: productValues?.product_id,
      variant_id: getVariantId(data)?.variant_id,
      variant_type_id: getVariantId(data)?.variant_type_id,
      single_select: obj1?.single_select,
      action: checked ? "1" : "2",
      multi_select_limit: obj1?.multi_select_limit,
    };

    let findProductindex = globalCheckoutDetails?.cart_products?.findIndex(
      (ele) => ele.product_id === productValues?.product_id
    );

    if (findProductindex < 0 || findProductindex === undefined) {
      setTempObj({ productValues, obj1, data, checked });
    }

    if (findProductindex >= 0) {
      setVarLoading(true);
      try {
        let addRemoveVariantCartApiValuesResponse =
          await addRemoveVariantCartApi(obj);

       

        setaddRemoveVariantCount((f) => f + 1);
        setTempObj({});
        setCheckVariant(false);
        setTimeout(() => {
          setVarLoading(false);
        }, 800);
      } catch (e) {}
    }
  };
  const [radioBoxSelect, setRadioBoxSelect] = useState(false);
  const [requiredState, setRequiredState] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [reqDfault, setReqDefault] = useState(false);

  const handleOnChange = (event, productValues, obj1, itemvalues) => {
    setRequiredState(obj1?.is_mandatory);
    // if (obj1.is_mandatory == "1") {
    //   if (mandatory.hasOwnProperty(event.target.name.replace(/\s+/g, ""))) {
    //     let tempObj = mandatory;
    //     tempObj[event.target.name.replace(/\s+/g, "")] = event.target.value;
    //     setMandatory(tempObj);
    //   }
    // }
    let checked = event.target.checked;
    setRadioBoxSelect(checked);
    let data = event.target.value;

    setValue(data);
    let findProductindex = globalCheckoutDetails?.cart_products?.findIndex(
      (ele) => ele.product_id === productValues?.product_id
    );

    if (findProductindex < 0 || findProductindex === undefined) {
      handleAdd(productValues, itemvalues);
    }
    handleAddRemoveVariantCartApi(productValues, obj1, data, checked);
  };
  const [checkSelected, setCheckSelected] = useState();

  const handleOnChangeCheckBox = (event, productValues, obj1) => {
    // if (obj1.is_mandatory == "1") {
    //   if (mandatory.hasOwnProperty(event.target.name.replace(/\s+/g, ""))) {
    //     let tempObj = mandatory;
    //     tempObj[event.target.name.replace(/\s+/g, "")] = event.target.value;
    //     setMandatory(tempObj);
    //   }
    // }
    let checked = event.target.checked;
    let data = event.target.value;
    setCheckSelected(data);

    let findProductindex = globalCheckoutDetails?.cart_products?.findIndex(
      (ele) => ele.product_id === productValues?.product_id
    );

    if (findProductindex < 0 || findProductindex === undefined) {
      handleAdd(productValues, itemvalues);
    }
    // setValue(data);
    handleAddRemoveVariantCartApi(productValues, obj1, data, checked);
  };
  const [addValueOnClick, setAddValueOnClick] = useState(false);
  if (requiredState !== "1" || radioBoxSelect === false) {
    sessionStorage.setItem("addValueOnClick", "true");
  } else {
    sessionStorage.setItem("addValueOnClick", "false");
  }
  const handleAddButtonClick = () => {
    setAddValueOnClick();
  };

  useEffect(() => {
    
    if (
      globalCheckoutDetails &&
      Object.keys(globalCheckoutDetails).length > 0
    ) {
      if (
        globalCheckoutDetails?.cart_products &&
        globalCheckoutDetails?.cart_products?.findIndex(
          (ele) => ele.product_id == productValues?.product_id
        ) >= 0
      ) {
        let variantValues =
          globalCheckoutDetails?.cart_products[
            globalCheckoutDetails?.cart_products?.findIndex(
              (ele) => ele.product_id == productValues?.product_id
            )
          ].variants;
        setVariantValues(variantValues);
      } else {
        setVariantValues([]);
      }
    } else {
      setVariantValues([]);
    }
  }, [globalCheckoutDetails, productValues]);

  useEffect(() => {
    let newArray = [];
    if (variantDatas?.items?.length > 0) {
      let mandatoryFields = variantDatas?.items
        ?.filter((ele) => ele.is_mandatory == "1")
        .map((data) => newArray.push(data.variant_id));
      setMandatory(newArray);
    }
  }, [variantDatas]);

  const checkRequiredState = () => {
    let findProductindex = globalCheckoutDetails?.cart_products?.findIndex(
      (ele) => ele.product_id === productValues?.product_id
    );
    if (errorMessage != "" && findProductindex >= 0) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      closeVariantPopUp(false);
    }
  };

  const handleDec = (productValues, itemvalues) => {
    if (errorMessage != "") {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      handleDecrement(productValues, itemvalues);
    }
  };

  const handleInc = (productValues, itemvalues) => {
    if (errorMessage != "") {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      handleIncrement(productValues, itemvalues);
    }
  };

  useEffect(() => {
    let newMandate;
    if (variantValues && variantValues.length > 0) {
      newMandate = mandatory.map(
        (data) => variantValues.findIndex((ele) => ele.variant_id == data) >= 0
      );
      let requiredValue = newMandate.findIndex((ele) => ele == false);
      if (requiredValue >= 0) {
        setErrorMessage("Please select the required options");
      } else {
        setErrorMessage("");
      }
    } else if (
      variantValues &&
      variantValues.length < 1 &&
      mandatory &&
      mandatory.length > 0
    ) {
      setErrorMessage("Please select the required options");
    } else {
      setErrorMessage("");
    }
  }, [variantValues, errorMessage, mandatory]);

  useEffect(() => {
    let defVar = [];
    if (variantDatas?.items?.length > 0) {
      let filteredVariant = variantDatas?.items?.map((data, index) => {
        if (
          data.sub_name.filter((ele) => ele.default_variant_status == "1")
            ?.length > 0
        ) {
          if (data.single_select == "1") {
           
            let filteredValues = data.sub_name.filter(
              (ele) => ele.default_variant_status == "1"
            );
            if (filteredValues?.length > 0) {
              variantFormik.setFieldValue(
                data.variant_name,
                filteredValues[0].variant_type_name
              );
            }
            return defVar.push(
              ...data.sub_name.filter(
                (ele) => ele.default_variant_status == "1"
              )
            );
          } else {
            let filteredValues = data.sub_name.filter(
              (ele) => ele.default_variant_status == "1"
            );
            let tempArray = [];
            if (filteredValues?.length > 0) {
              filteredValues?.map((data) => {
                return tempArray.push(data.variant_type_name);
              });
            }
            variantFormik.setFieldValue(data.variant_name, tempArray);
          }
        } else return null;
      });
      if (defVar?.length > 0) {
        let finalDef = defVar.map((data, index) => {
          return {
            product_id: productValues?.product_id,
            variant_id: data.variant_id,
            variant_type_id: data.variant_type_id,
          };
        });
        // setDefaultVariant(finalDef);
      }
    }
  }, [variantDatas, initialState]);


  return (
    <>
      {varloading ? <Loader /> : ""}
      <Modal
        show={isVariantAvailable}
        onHide={checkRequiredState}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="ms-2 mt-1"></Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="variantHeaderPopup">
            <img
              className="ProductImagePopup"
              src={productValues?.product_image}
              alt=""
            />
          </div>
          {errorMessage != "" && displayError && (
            <p className="text-danger mb-0">
              Please select the required options
            </p>
          )}

          {globalCheckoutDetails?.cart_products?.findIndex(
            (ele) => ele.product_id === productValues?.product_id
          ) < 0 ||
          globalCheckoutDetails?.cart_products?.findIndex(
            (ele) => ele.product_id === productValues?.product_id
          ) == undefined ? (
            <>
              <form onSubmit={variantFormik.handleSubmit}>
                {variantDatas?.items?.map((obj1, index) => {
                  return (
                    <div className="variant-container">
                      <div className="variant-title-container">
                        <div>
                          {obj1.variant_name}
                          {obj1.single_select === "1" ? `(Select any 1)` : ""}
                        </div>

                        {obj1.is_mandatory === "1" ? (
                          <div>
                            <button className="reqrdButton">Required</button>
                          </div>
                        ) : null}
                      </div>
                      {obj1.single_select === "1" ? (
                        <>
                          {" "}
                          <div className="option-container">
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name={obj1.variant_name}
                              value={variantFormik.values[obj1.variant_name]}
                              onChange={variantFormik.handleChange}
                            >
                              {obj1.sub_name.map((obj2, i) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      value={obj2.variant_type_name}
                                      control={
                                        <Radio
                                          checked={
                                            variantFormik.values[
                                              obj1.variant_name
                                            ]?.includes(obj2.variant_type_name)
                                              ? true
                                              : false
                                          }
                                          disabled={varloading ? true : false}
                                        />
                                      }
                                      label={
                                        <div className="d-flex align-items-center justify-content-between">
                                          <p className="mb-0 text-left d-flex align-items-center">
                                            {obj2.variant_type_name}
                                          </p>
                                          <span className="amount-variant text-right">
                                            ${obj2.variant_type_price}
                                          </span>
                                        </div>
                                      }
                                    />
                                  </>
                                );
                              })}
                            </RadioGroup>
                          </div>
                          {variantFormik.touched[obj1.variant_name] &&
                            variantFormik.errors[obj1.variant_name] && (
                              <p className="text-danger mb-0">
                                {variantFormik.errors[obj1.variant_name]}
                              </p>
                            )}
                        </>
                      ) : (
                        <>
                          <div className="option-container">
                            <FormGroup name={obj1.variant_name}>
                              {obj1.sub_name.map((obj2, i) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      name={obj1.variant_name}
                                      value={obj2.variant_type_name}
                                      control={
                                        <Checkbox
                                          disabled={varloading ? true : false}
                                          checked={
                                            variantValues &&
                                            variantValues.length > 0 &&
                                            variantValues.findIndex(
                                              (ele) =>
                                                ele.variant_type_name ==
                                                obj2.variant_type_name
                                            ) >= 0
                                              ? true
                                              : false ||
                                                variantFormik.values[
                                                  obj1.variant_name
                                                ]?.includes(
                                                  obj2.variant_type_name
                                                )
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label={
                                        <div className="d-flex align-items-center justify-content-between">
                                          <p className="mb-0 text-left d-flex align-items-center">
                                            {obj2.variant_type_name}
                                          </p>
                                          <span className="amount-variant text-right">
                                            ${obj2.variant_type_price}
                                          </span>
                                        </div>
                                      }
                                      onChange={variantFormik.handleChange}
                                      // onChange={(e) =>
                                      //   handleVariantChange(
                                      //     e,
                                      //     productValues,
                                      //     obj1,
                                      //     itemvalues
                                      //   )
                                      // }
                                    />
                                  </>
                                );
                              })}
                            </FormGroup>
                          </div>
                          {variantFormik.touched[obj1.variant_name] &&
                            variantFormik.errors[obj1.variant_name] && (
                              <p className="text-danger mb-0">
                                {variantFormik.errors[obj1.variant_name]}
                              </p>
                            )}
                        </>
                      )}
                    </div>
                  );
                })}
              </form>

              <div className="add-btn AddVarientButton">
                {productValues.counter && productValues.counter > 0 ? (
                  <Button>
                    <div
                      className="in-de-btn"
                      onClick={() => {
                        handleDecrement(productValues, itemvalues);
                      }}
                    >
                      -
                    </div>
                    <div className="count">{productValues.counter} </div>
                    <div
                      className="in-de-btn"
                      onClick={() => {
                        handleIncrement(productValues, itemvalues);
                      }}
                    >
                      +
                    </div>
                  </Button>
                ) : productValues.counter == 0 || !productValues.counter ? (
                  <Button
                    onClick={() => variantFormik.handleSubmit()}
                    disabled={varloading}
                  >
                    <div className="count"> Add </div>
                  </Button>
                ) : null}
              </div>
            </>
          ) : (
            <>
              {variantDatas?.items?.map((obj1, index) => {
                return (
                  <div className="variant-container">
                    <div className="variant-title-container">
                      <div>
                        {obj1.variant_name}
                        {obj1.single_select === "1" ? `(Select any 1)` : ""}
                      </div>

                      {obj1.is_mandatory === "1" ? (
                        <div>
                          <button className="reqrdButton">Required</button>
                        </div>
                      ) : null}
                    </div>
                    {obj1.single_select === "1" ? (
                      <div className="option-container">
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name={obj1.variant_name}
                          value={value}
                          onChange={(e) =>
                            handleOnChange(e, productValues, obj1, itemvalues)
                          }
                        >
                          {obj1.sub_name.map((obj2, i) => {
                            return (
                              <>
                                <FormControlLabel
                                  value={obj2.variant_type_name}
                                  control={
                                    <Radio
                                      disabled={varloading ? true : false}
                                      checked={
                                        variantValues &&
                                        variantValues.length > 0 &&
                                        variantValues.findIndex(
                                          (ele) =>
                                            ele.variant_type_name ==
                                            obj2.variant_type_name
                                        ) >= 0
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label={
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="mb-0 text-left d-flex align-items-center">
                                        {obj2.variant_type_name}
                                      </p>
                                      <span className="amount-variant text-right">
                                        ${obj2.variant_type_price}
                                      </span>
                                    </div>
                                  }
                                />
                              </>
                            );
                          })}
                        </RadioGroup>
                      </div>
                    ) : (
                      <div className="option-container">
                        <FormGroup name={obj1.variant_name}>
                          {obj1.sub_name.map((obj2, i) => {
                            return (
                              <>
                                <FormControlLabel
                                  value={obj2.variant_type_name}
                                  control={
                                    <Checkbox
                                      disabled={varloading ? true : false}
                                      checked={
                                        variantValues &&
                                        variantValues.length > 0 &&
                                        variantValues.findIndex(
                                          (ele) =>
                                            ele.variant_type_name ==
                                            obj2.variant_type_name
                                        ) >= 0
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label={
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="mb-0 text-left d-flex align-items-center">
                                        {obj2.variant_type_name}
                                      </p>
                                      <span className="amount-variant text-right">
                                        ${obj2.variant_type_price}
                                      </span>
                                    </div>
                                  }
                                  onChange={(e) =>
                                    handleOnChangeCheckBox(
                                      e,
                                      productValues,
                                      obj1,
                                      itemvalues
                                    )
                                  }
                                />
                              </>
                            );
                          })}
                        </FormGroup>
                      </div>
                    )}
                  </div>
                );
              })}

              <div className="add-btn AddVarientButton">
                {productValues.counter && productValues.counter > 0 ? (
                  <Button>
                    <div
                      className="in-de-btn"
                      onClick={() => handleDec(productValues, itemvalues)}
                    >
                      -
                    </div>
                    <div className="count">{productValues.counter} </div>
                    <div
                      className="in-de-btn"
                      onClick={() => handleInc(productValues, itemvalues)}
                    >
                      +
                    </div>
                  </Button>
                ) : productValues.counter == 0 || !productValues.counter ? (
                  <Button
                    disabled={
                      reqDfault == false &&
                      (requiredState !== "1" || radioBoxSelect === false
                        ? true
                        : false)
                    }
                  >
                    <div
                      className="count"
                      onClick={() => {
                        handleAdd(productValues, itemvalues);
                        handleAddButtonClick();
                      }}
                    >
                      {" "}
                      Add{" "}
                    </div>
                  </Button>
                ) : null}
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default VariantPopUp;
