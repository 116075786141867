import axios from "axios";
import { getUrl } from "./URl/Url";
//import { getUserType } from "../constants/Utils";


//SignUp Module GetOtp API :-
//-----------------------------
export const getOtpSignUpApi = async (userData) => {
  try {
    let signUpApiOtpResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/customerSignup/get_otp",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      // Attaching the form data
      data: userData,
    });
    //setItem storing the data in session
    return signUpApiOtpResponse;
  } catch (e) {
    console.log(e);
  }
};
//--------------------------------------------------------------------

//SignUp Module Signup to APP API :-
//----------------------------------
export const signupVerifyContinueApi = async (userData) => {
  try {
    let signUpApiVerfifyResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/customerSignup/signup",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
 
    // console.log("signUpApiVerfifyResponse",signUpApiVerfifyResponse);

    if (signUpApiVerfifyResponse.data.data.id) {
      sessionStorage.setItem(
        "otpResponse",
        JSON.stringify(signUpApiVerfifyResponse.data.data.id)
      );
    } 
    // else {
      
    // }

   

    const locat = {
      pin_address: signUpApiVerfifyResponse.data.data.user_pin_address,
      street_address: signUpApiVerfifyResponse.data.data.user_street_address,
      postal_code: signUpApiVerfifyResponse.data.data.user_postal_code,
      unit_number: signUpApiVerfifyResponse.data.data.user_unit_number,
      lat: signUpApiVerfifyResponse.data.data.latitude,
      lng: signUpApiVerfifyResponse.data.data.longitude,
      // id:signUpApiVerfifyResponse.data.data.id, 
    };
 
    sessionStorage.setItem("userLocation", JSON.stringify(locat));
  
    //setItem storing the data in session
    return signUpApiVerfifyResponse;
  } catch (e) {
    console.log(e);
  }
};
//--------------------------------------------------------------------

//Login Module GetOtp API :-
//-----------------------------
export const getOtpLoginApi = async (userData) => {
  try {
    let loginApiOtpResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/customerLogin/send_otp",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    // sessionStorage.setItem(
    //   "otpResponse",
    //   JSON.stringify(loginApiOtpResponse.data.data)
    // );
    //setItem storing the data in session
    return loginApiOtpResponse;
  } catch (e) {
    console.log(e);
  }
};

//Login Module GetCountryCode API :-
//-----------------------------
export const getCountryCodeApi = async () => {
  try {
    let getCountryCodeResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/data/countries_phone_code",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "GET",
    });
    // sessionStorage.setItem(
    //   "otpResponse",
    //   JSON.stringify(loginApiOtpResponse.data.data)
    // );
    //setItem storing the data in session
    return getCountryCodeResponse;
  } catch (e) {
    console.log(e);
  }
};

//Login Module Login to APP API :-
//-----------------------------
export const LoginVerifyContinueApi = async (userData) => {
  try {
    let LoginApiVerifyResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/customerLogin/verify_and_proceed",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    sessionStorage.setItem(
      "otpResponse",
      JSON.stringify(LoginApiVerifyResponse.data.data)
    );
    const locat = {
      pin_address: LoginApiVerifyResponse.data.data.user_pin_address,
      street_address: LoginApiVerifyResponse.data.data.user_street_address,
      postal_code: LoginApiVerifyResponse.data.data.user_postal_code,
      unit_number: LoginApiVerifyResponse.data.data.user_unit_number,
      lat: LoginApiVerifyResponse.data.data.latitude,
      lng: LoginApiVerifyResponse.data.data.longitude,
      id:LoginApiVerifyResponse.data.data.address_id, 

    };
    sessionStorage.setItem("userLocation", JSON.stringify(locat));
    //setItem storing the data in session
    return LoginApiVerifyResponse;
  } catch (e) {
    console.log(e);
  }
};
//------------------LogOut API--------------------------------
//---------------------------------------------------------
export const logOutApi = async (userData) => {
  try {
    let logOutResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/base/logout",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    // sessionStorage.setItem(
    //   "otpResponse",
    //   JSON.stringify(logOutResponse.data.data)
    // );
    return logOutResponse;
  } catch (e) {
    console.log(e);
  }
};

//------------------Location API--------------------------------
//---------------------------------------------------------
export const LocationPopupApi = async (userData) => {
  try {
    let locationPopUpResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/customerSignup/confirm_location",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "POST",
      // Attaching the form data
      data: userData,
    });

    // var idStringified = JSON.stringify(id);
    
    const locat = {
      user_id: locationPopUpResponse.data.data.id,
      pin_address: locationPopUpResponse.data.data.pin_address,
      street_address: locationPopUpResponse.data.data.street_address,
      postal_code: locationPopUpResponse.data.data.postal_code,
      unit_number: locationPopUpResponse.data.data.unit_number,
      lat: locationPopUpResponse.data.data.lat,
      lng: locationPopUpResponse.data.data.lng,
      id:locationPopUpResponse.data.data.address_id, 
    };
    sessionStorage.setItem("userLocation", JSON.stringify(locat));
    //setItem storing the data in session
    return locationPopUpResponse;
  } catch (e) {
    console.log(e);
  }
};

export const postalcodeAPI = async (lat, lng) => {
  try {
    let zipcodeResponse = await axios({
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${"AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I"}`,
      method: "GET",
    });
    return zipcodeResponse;
  } catch (e) {
    console.log(e);
  }
};

//------------------Contact Us API--------------------------------
//---------------------------------------------------------
export const contactUsLandingApi = async (userData) => {
  try {
    let locationPopUpResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/ContactUs/send_message",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    //setItem storing the data in session
    return locationPopUpResponse;
  } catch (e) {
    console.log(e);
  }
};

//GET Requests
//----------------

export const getHearAboutUsValue = async (userData) => {
  try {
    let getHearAboutUsValueResponse = await axios({
      // Endpoint to send files
      url: getUrl() + "/data/hear_about_us",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: userData,
    });
    //setItem storing the data in session
    return getHearAboutUsValueResponse;
  } catch (e) {
    console.log(e);
  }
};

export const listAllNotificationApi = async (userData) => {
  try {
    let listAllNotificationResponse = await axios({
      // Endpoint to send files
      url:
        getUrl() + "/customerNotification/list_all_notification?page=0&limit=2",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: userData,
    });
    //setItem storing the data in session
    return listAllNotificationResponse;
  } catch (e) {
    console.log(e);
  }
};


//dummyApi
export const base_url = async (userData) => {
  try {
    let base_urlResponse = await axios({
      // Endpoint to send files
      url:
        getUrl() + "/data/base_url",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: userData,
    });
    //setItem storing the data in session
    return base_urlResponse;
  } catch (e) {
    console.log(e);
  }
};



//------------------Forgot API--------------------------------
//---------------------------------------------------------
export const forgotApi = async (userData) => {
  try {
    let forgotApiResponse = await axios({
      // Endpoint to send fileshttps://ifdapi.onedigitaldemo.com/customerLogin/forget_password
      url: getUrl() + "/customerLogin/forget_password",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    // sessionStorage.setItem(
    //   "otpResponse",
    //   JSON.stringify(logOutResponse.data.data)
    // );
    return forgotApiResponse;
  } catch (e) {
    console.log(e);
  }
};


//------------------Reset API--------------------------------
//---------------------------------------------------------
export const ResetPasswordApi = async (userData) => {
  try {
    let forgotApiResponse = await axios({
      // Endpoint to send fileshttps://ifdapi.onedigitaldemo.com/customerLogin/forget_password
      url: getUrl() + "/customerLogin/reset_password",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    return forgotApiResponse;
  } catch (e) {
    console.log(e);
  }
};


//------------------Reset API--------------------------------
//---------------------------------------------------------
export const VerifyAccountEmailApi = async (userData) => {
  try {
    let forgotApiResponse = await axios({

      url: getUrl() + "/customerLogin/confirm_email_update_password",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      method: "POST",
      // Attaching the form data
      data: userData,
    });
    return forgotApiResponse;
  } catch (e) {
    console.log(e);
  }
};