import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { cancelDineinApi } from "../../../../../services/HomePageServices";
import "./CancelDineInPopup.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CancelDineinPopup({
  show,
  closePopUp,
  selectedItem,
  myOrdersValuesApi,
}) {
  const notify = (message) => toast(message);

  const handleClose = () => {
    closePopUp(false);
  };
  const cancelDineinValuesApi = async () => {
    let postcancelDineinObj = {
      dine_in_id: selectedItem?.dinein_id,
    };

    try {
      let cancelDineinApidataResponse = await cancelDineinApi(
        postcancelDineinObj
      );
      if (cancelDineinApidataResponse) {
        notify(cancelDineinApidataResponse.data.message);
        handleClose();
        myOrdersValuesApi();
      }
    } catch (e) {}
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="cancelDineInButton" closeButton></Modal.Header>
        <Row className="mt-5"></Row>

        <Modal.Body>
          <Row>
            <Col lg="2" />
            <Col lg="9" className="mb-3">
              <p className="cancelDinein">
                Do You Want to Cancel the Dine - in
              </p>
              <Row>
                <Col lg="1" />
                <Col lg="4" md="4"xs="4" className="ms-3 mt-5">
                  <Button className="cancelButton" onClick={handleClose}>
                    Cancel
                  </Button>
                </Col>
                <Col lg="4"  md="4"xs="4" className="mt-5">
                  <Button
                    className="okButton"
                    onClick={() => {
                      cancelDineinValuesApi();
                    }}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default CancelDineinPopup;
