import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ChangeDineinPopup.css";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addEditDineinApi } from "../../../../../services/HomePageServices";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { dineInScreenApi } from "../../../../../services/ProfilePageServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
function ChangeDineinPopup({
  closePopUp,
  show,
  selectedItem,
  myOrdersValuesApi,
}) {
  const [tabValue, setTabValue] = useState("");
  const [datevalue, setDateValue] = React.useState(dayjs(new Date()));
  const [tabSlotValue, setTabSlotValue] = useState();
  const [displayTimeSlot, setDisplayTimeSlot] = useState([]);
  const [noOfPeople, setNoOfPeople] = useState("");

  function isDateToday(date) {
    const otherDate = new Date(date);
    const todayDate = new Date();
    if (
      otherDate.getDate() === todayDate.getDate() &&
      otherDate.getMonth() === todayDate.getMonth() &&
      otherDate.getYear() === todayDate.getYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [dineTime, setDineInTime] = useState();
  const slotsAvailable = async (dateObj) => {
    var date = new Date(dateObj);
    var seconds = date.getTime() / 1000;
    setDineInTime(seconds);

    if (isDateToday(dateObj)) {
      // let time = moment(date)
      //   ?.tz("Asia/Singapore")
      //   .format("M-D-YYYY hh:mm:ss a");
      let singaporeTime = Date.now() / 1000;
      seconds = singaporeTime;
    } else {
      // let time = moment(date)?.format("M-D-YYYY");
      let singaporeTime = new Date(date) / 1000;
      seconds = singaporeTime;
    }

    

    let postSlotAvailableObj = {
      date: parseInt(seconds),
      time_slot: parseInt(seconds),
      // time_slot: selectedItem?.booking_id
      //   ? selectedItem.time_slot
      //   : parseInt(seconds),
      is_today: isDateToday(dateObj) ? "1" : "2",
      restaurant_id: selectedItem?.restaurant_id,
    };

    try {
      let dineInScreenResp = await dineInScreenApi(postSlotAvailableObj);
      let result = dineInScreenResp?.data?.data;
      setDisplayTimeSlot(result);
    } catch (e) {}
  };

  useEffect(() => {
    if (selectedItem?.booking_id) {
      var d = new Date(selectedItem.booking_date * 1000);
      setDateValue(d);
      setTabValue(parseInt(selectedItem?.no_of_people));
      slotsAvailable(d);
    } else {
      slotsAvailable(dayjs(new Date()));
    }
  }, [selectedItem]);

  const handleClose = () => {
    setTabValue("");
    setTabSlotValue("");
    setNoOfPeople("");
    closePopUp(false);
  };

  const handleClicked = (value) => {
    setTabValue(value);

    handleChecked();

  

    setNoOfPeople("");
  };
  const [handleActive, setHandleActive] = useState("1");
  const handleChange = (newValue) => {
    const todayDate = new Date();
    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    

    if (new Date(newValue)?.getDate() == todayDate?.getDate()) {
      setHandleActive("1");
    } else if (new Date(newValue)?.getDate() == currentDate?.getDate()) {
      setHandleActive("2");
    } else {
      setHandleActive("");
    }

  
    setDateValue(newValue);
    slotsAvailable(newValue);
  };

  const handleToday = () => {
    setDateValue(dayjs(new Date()));
    slotsAvailable(dayjs(new Date()));
    setHandleActive("1");
  };

  const handleTommorrow = () => {
    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    setDateValue(dayjs(currentDate));
    slotsAvailable(dayjs(currentDate));
    setHandleActive("2");
  };

  const notify = (message) => toast(message);
  const navigate = useNavigate();
  function changeDatetimeByTimezone(datetime, timezone) {
    const parsedDateAsUtc = moment
      .utc()
      .startOf("day")
      .add(datetime.substring(0, 2), "hours")
      .add(datetime.substring(3, 5), "minutes");
    return parsedDateAsUtc.clone().tz(timezone).format("hh:mm");
  }
  const [CheckedError, setCheckedError] = useState("");

  const addEditDineinValuesApi = async () => {
    var date = new Date(datevalue);
    let seconds = date.getTime() / 1000;
    var dineInDate = Math.floor(seconds);
    handleChecked();
    let postaddEditDineinObj = {};
    if (tabValue == "" && noOfPeople == "") {
    } else {
      if (selectedItem.booking_id) {
        postaddEditDineinObj = {
          dine_in_id: selectedItem.dinein_id,
          date: dineInDate,
          no_of_people: tabValue ? tabValue : noOfPeople ? noOfPeople : 0,
          time_slot: tabSlotValue,
          action: "2",
          restaurant_id: selectedItem?.restaurant_id
            ? selectedItem?.restaurant_id
            : null,
        };
      } else {
        postaddEditDineinObj = {
          date: dineInDate,
          no_of_people: tabValue ? tabValue : noOfPeople ? noOfPeople : 0,
          time_slot: tabSlotValue,
          action: "1",
          restaurant_id: sessionStorage.getItem("restaurantData")
            ? sessionStorage.getItem("restaurantData")
            : null,
        };
      }
    }
    if (tabValue == "" && noOfPeople == "") {
    } else {
      try {
        let addEditDineinApidataResponse = await addEditDineinApi(
          postaddEditDineinObj
        );
        handleClose();
        if (addEditDineinApidataResponse?.data?.status === 200) {
          swal({
            title: "Success!",
            text: addEditDineinApidataResponse.data.message,
            type: "success",
            timer: 2500,
            button: false,
            confirmButtonText: "OK",
            className: "popuptetx",
            confirmButtonColor: "#8CD4F5",
            icon: "success",
          });
          sessionStorage.setItem("myOrdersState", 2);
          navigate("/profile", { state: { passKey: 1 } });
        } else {
          swal({
            title: "Error!",
            text: addEditDineinApidataResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,

            icon: "error",
            className: "popuptetx",
          });
        }
        myOrdersValuesApi();
      } catch (e) {}
    }
  };

  const handleClickSlot = (value) => {
    setTabSlotValue(value.time_slot);
  };

  const handleNoChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNoOfPeople(e.target.value);
      setTabValue(null);
      handleChecked();
    }
  };

  const handleChecked = () => {
   
    if (tabValue == "" && noOfPeople == "") {
      // setChecked(false)
      setCheckedError("Please select no of people!");
    } else {
      // setChecked(true)
      setCheckedError("");
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="notification-header">
          <Modal.Title className="tittle">Dine-In</Modal.Title>
          <Modal.Header closeButton></Modal.Header>
        </div>
        <div className="dinein-title">
          <div className="Modal_Title">{selectedItem?.rest_name}</div>
          <p className="Dinein_Address_Change">
            {selectedItem?.rest_unit_number
              ? selectedItem?.rest_unit_number + ","
              : null}
            {selectedItem?.rest_pin_address
              ? selectedItem?.rest_pin_address + ","
              : null}
            {selectedItem?.rest_street_address
              ? selectedItem?.rest_street_address + ","
              : null}
            {selectedItem?.rest_postal_code
              ? selectedItem?.rest_postal_code
              : null}
          </p>
        </div>
        <div className="date-header">
          <b>Date</b>
        </div>
        <div className="date-container">
          <div className="today-tmr">
            <Button
              className={
                handleActive == "1" ? "today_dinein " : "activeDateDineIn "
              }
              onClick={() => handleToday()}
            >
              Today
            </Button>
          </div>
          <div className="today-tmr">
            <Button
              className={
                handleActive == "2" ? "tomorrow_dinein" : "  activeDateDineIn"
              }
              onClick={() => handleTommorrow()}
            >
              Tomorrow
            </Button>
          </div>
          <div className="all-date dateDinePopup">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                component="form"
                className="date date2"
                noValidate
                spacing={3}
              >
                <DesktopDatePicker
                  label=""
                  inputFormat="MM/DD/YYYY"
                  value={datevalue}
                  onChange={handleChange}
                  minDate={new Date()}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </div>
        </div>
        <div className="date-header">
          <p className="people_count">How Many people?</p>
          <div className="count-display">
            {[...Array(10)].map((_, i) => (
              <div
                className={
                  tabValue === i + 1
                    ? "activePersonTabValue dinein_count"
                    : "dinein_count"
                }
                onClick={() => {
                  handleClicked(i + 1);
                }}
              >
                {i + 1}
              </div>
            ))}
          </div>
        </div>
        <div className="count-person">
          <p className="people_count_change_dinein">or</p>
          <TextField
            className="inptCntFld"
            label="Add People Manually"
            variant="standard"
            onChange={handleNoChange}
            value={noOfPeople}
          />
        </div>
        {tabValue == "" && noOfPeople == "" ? (
          <p className="noOfPeopleError">{CheckedError}</p>
        ) : null}
        <div className="date-header">
          <p className="people_count">Time</p>
        </div>
        <div className="time-slot-btn-container">
          {displayTimeSlot?.map((timeSlotObj) => (
            <div className="time-slot-data">
              <Button
                className={
                  tabSlotValue == timeSlotObj?.time_slot
                    ? "activePersonTabValue slot_dinein"
                    : "slot_dinein"
                }
                onClick={() => handleClickSlot(timeSlotObj)}
                disabled={displayTimeSlot?.is_full === 1 ? true : false}
              >
                {timeSlotObj.display}
              </Button>
            </div>
          ))}
          {displayTimeSlot?.length === 0
            ? "Time slot not available for this date.Please select another date"
            : null}
        </div>
        <div className="book-btn-container">
          <Button
            className="book-btn"
            onClick={() => {
              addEditDineinValuesApi();
            }}
          >
            BOOK
          </Button>
        </div>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default ChangeDineinPopup;
