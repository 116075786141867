import { React, useState, useEffect } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Geocode from "react-geocode";
import { useSelector } from "react-redux";
import "../../../Landing/Location/Location.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { addDeliveryAddressApi } from "../../../../services/ProfilePageServices";
import { AddressTypes,getUserData } from "../../../../constants/Utils";
import { postalcodeAPI } from "../../../../services/Landingservice";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";
import moment from "moment";
import "moment-timezone";

function AddAddressPopup({ LocationPopUp, showLocation, AllAddressDataApi }) {
  Geocode.setApiKey("AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const firebaseConfig = {
    apiKey: "AIzaSyD_FMTXLsC_dBkNPGa4BnaP65L7JvE9AO0",
    authDomain: "kerala-eats-312105.firebaseapp.com",
    projectId: "kerala-eats-312105",
    storageBucket: "kerala-eats-312105.appspot.com",
    messagingSenderId: "987804453892",
    appId: "1:987804453892:web:8f61f10109ac0707787951",
    measurementId: "G-Q6R84M0DDZ"
  };

  const [value, setValue] = useState(null);
  const [latLong, setLatLong] = useState();

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  useEffect(() => {
    if (value && value.label) {
      Geocode.fromAddress(value.label).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLatLong({ lat, lng });
          postalcodeAPI(lat, lng).then((response) => {
            let postalCodeResp =
              response.data.results[0].address_components[
                response.data.results[0].address_components.length - 1
              ].long_name;
            formik.setFieldValue(
              "postal_code",
              containsOnlyNumbers(postalCodeResp) ? postalCodeResp : ""
            );
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [value]);

  const initialValues = {
    house: "",
    UnitNumber: "",
    postal_code: "",
  };

  const [tabValue, setTabValue] = useState("");
  const [tabId, setTabId] = useState("");
  const [markusError, setMarkUsError] = useState();

  const CloseLocationPopUp = () => {
    formik.resetForm({
      values: initialValues,
    });
    setTabValue("");
    LocationPopUp(false);
    setValue(""); //callback function
  };
  const handleClicked = (item) => {
    setTabValue(item.value);
    setTabId(item.id);
  };
  const showmarkUsError = () => {
    if (tabValue === "") {
      setMarkUsError("Please Select Address Type!");
    } else {
      setMarkUsError();
    }
  };

  const validationSchema = yup.object({
    house: yup.string().required("This Field Can't Be Empty!"),
    UnitNumber: yup.string().required("This Field Can't Be Empty!"),
    postal_code: yup
      .string()
      .required("Enter Your Postal Code")
      .matches(/^[0-9\b]+$/, "Please Enter Digits Only")
      .min(6, "Enter 6 digits PostalCode"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });
  const handleBlur = () => {
    if (tabValue !== "") {
      setMarkUsError();
    } else {
      setMarkUsError("Please Select Address Type!");
    }
  };
  const [handleConfirm, setHandleConfirm] = useState(false);

  //add_delivery_address Api
  const addAddressDataApi = async () => {
    setHandleConfirm(true);

    let userId = getUserData()?.id;
    let location= getUserData()?.user_pin_address+', '+getUserData()?.user_street_address+', '+getUserData()?.user_postal_code;
    let today = moment(new Date())?.tz("Asia/Singapore").format("L");
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Analytics and get a reference to the service
    const analytics = getAnalytics(app);
    setUserId(analytics, userId);
    let time = moment(new Date())?.tz("Asia/Singapore").format("LT");
    logEvent(analytics, 'Delivery-Address', {
      event_occurred:'Before API Calling',
      user_id: userId,
      date: today,
      time: time,
      response:'',
      order_id:'',
      transaction_id:'',
      location:location,
      message: 'Trying to add delivery address!!'
    });

    let postAddAddressObj = {
      latitude: latLong.lat,
      longitude: latLong.lng,
      pin_address: value?.label,
      unit_number: formik.values.UnitNumber,
      street_address: formik.values.house,
      postal_code: formik.values.postal_code,
      address_type: tabId,
    };

    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0 &&
        tabValue !== ""
      ) {
        let addDeliveryAddressApiResponse = await addDeliveryAddressApi(
          postAddAddressObj
        );
        if (addDeliveryAddressApiResponse.status === 200) {
          CloseLocationPopUp();
          AllAddressDataApi();
          let time1 = moment(new Date())?.tz("Asia/Singapore").format("LT");
          logEvent(analytics, 'Delivery-Address', {
            event_occurred:'After API Calling',
            user_id: userId,
            date: today,
            time: time1,
            response:'',
            order_id:'',
            transaction_id:'',
            location:location,
            message: 'Add delivery address successfully!!'
          });

        }
      }
    } catch (e) {}
  };

  return (
    <>
      <Modal show={showLocation} onHide={CloseLocationPopUp} animation={true}>
        <Modal.Header className="locationPopUp_Header border-0" closeButton>
          <Col lg="10">
            <Modal.Title className="ms-2 mt-1">
              <b>Choose location</b>
            </Modal.Title>
            <hr width="80%" className="ms-2"></hr>
          </Col>
        </Modal.Header>
        <Form
          onSubmit={formik.handleSubmit}
          id="users"
          className="locationPopupForm"
        >
          <Modal.Body>
            <Container>
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="input-style">
                    <Form.Label> Address Line</Form.Label>
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["sg"],
                        },
                      }}
                      apiKey="AIzaSyAZNYje65H5kEiuMuF_gFmDwloZLmuIv-I"
                      apiOptions={{ language: "en" }}
                      selectProps={{
                        value,
                        onChange: setValue,
                        components: "country:us",
                      }}
                    ></GooglePlacesAutocomplete>
                  </div>
                  {handleConfirm == true && value?.label == undefined ? (
                    <p className="DeliveryAddressError">
                      Please enter a delivery address
                    </p>
                  ) : null}

                  <div className="input-style">
                    <Form.Label>BLK/House/Apartment No</Form.Label>
                    <Form.Control
                      name="house"
                      id="house"
                      className="inputLocation2 mt-2 mb-2"
                      placeholder="Enter Your BLK/House/Apartment No"
                      {...formik.getFieldProps("house")}
                    ></Form.Control>
                    {formik.touched.house && formik.errors.house && (
                      <div className="mb-2" style={{ color: "red" }}>
                        {formik.errors.house}
                      </div>
                    )}
                  </div>
                  <div className="input-style">
                    <Form.Label>Unit Number</Form.Label>
                    <Form.Control
                      name="UnitNumber"
                      id="UnitNumber"
                      className="form-control inputLocation3 mt-2 mb-2"
                      placeholder="Enter Your Unit Number"
                      {...formik.getFieldProps("UnitNumber")}
                    ></Form.Control>
                    {formik.touched.UnitNumber && formik.errors.UnitNumber && (
                      <div className="mb-2" style={{ color: "red" }}>
                        {formik.errors.UnitNumber}
                      </div>
                    )}
                  </div>
                  <div className="input-style">
                    <Form.Label> Postal Code</Form.Label>
                    <Form.Control
                      name="lastname"
                      className="form-control inputLocation4 mt-2 mb-2"
                      placeholder="Enter Your Postal Code"
                      id="postal_code"
                      maxLength="6"
                      {...formik.getFieldProps("postal_code")}
                    ></Form.Control>
                    <br />
                    {formik.touched.postal_code &&
                      formik.errors.postal_code && (
                        <div className="mb-2" style={{ color: "red" }}>
                          {formik.errors.postal_code}
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
            <p className="ms-2"> Mark as</p>
            <Container>
              <Row className="mb-3">
                {AddressTypes.map((data) => {
                  return (
                    <Col lg="4" md="4" sm="4" xs="4">
                      <Button
                        id="home"
                        onBlur={handleBlur}
                        className={
                          tabValue === data.value
                            ? "activeclassTabValue "
                            : "office_button"
                        }
                        onClick={() => handleClicked(data)}
                      >
                        {data.value}
                      </Button>
                    </Col>
                  );
                })}
                <p className="mt-2 col-12" style={{ color: "red" }}>
                  {markusError}
                </p>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col className="text-center mt-4" lg="12" md="12" sm="12">
                  <Button
                    className="confirm_btn "
                    type="submit"
                    onClick={() => {
                      showmarkUsError();
                      addAddressDataApi();
                    }}
                  >
                    Confirm & Proceed
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

export default AddAddressPopup;
