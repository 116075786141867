import { React, useState, useEffect } from "react";
import "./CartItems.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  cartValueApi,
  applyPromoCodeApi,
  emptyCartApi,
  addRemoveProductCartApi,
  productAvailabilityCheckApi,
} from "../../../services/CartCheckOutServices";
import CartItemPopupHeader from "./CartItemPopupHeader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  cartQuantityChangeViceVersa,
  itemTotalData,
  payAmounMobileMethod,
} from "../../../containers/app/features/CounterSlice";
import LoginView from "../../PaymentandLogin/Login/LoginView";
import {
  selfPickUpValue,
  singaporeTimeConverter,
} from "../../../constants/Utils";
import swal from "sweetalert";
import Forms from "../../Landing/Form/Forms";
import { getUserType } from "../../../constants/Utils";
import CartLoading from "../../../Asserts/cartloading.gif";
function CartItems({
  globalCheckoutDetails,
  globalCheckoutCallback,
  addRemoveVariantCount,
  deliveryChargeCallBack,
  totalPaymentAmont,
  prodCartVal,
  productListingValuesApi,
  promoManual,
  productItems,
  restStatus,
  checkoutLoading,
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const restaurantLocation = useLocation();
  let RestUrl = sessionStorage.getItem("identifier_key")
    ? sessionStorage.getItem("identifier_key")
    : null;
  let isRestaurentPage =
    restaurantLocation.pathname === `/restaurants/${RestUrl}`;
  const [cartValue, setCartValue] = useState();
  const [stateValue, setStateValue] = useState(0);
  const [promocodeMessage, setPromoCodeMessage] = useState();
  const [minOrderMessage, setMinOrderMessage] = useState();
  const [promocodeManualDetails, setPromocodeManualDetails] = useState([]);
  const [prodCount, setProductCount] = useState(0);
  const [autoApplyPromocode, setAutoApplyPromocode] = useState();
  const [manualApplyPromocode, setManualApplyPromocode] = useState(0);
  const [, setAutoApplySubTotal] = useState();
  const [disCountAutoApplyPromo, setDisCountAutoApplyPromo] = useState(0);
  const [showSubTotalAutoApply, setShowSubTotalAutoApply] = useState(0);
  const [finalDeliveryCharge, setFinalDeliveryCharge] = useState(0);
  const [fixedDeliveryCharge, setFixedDeliveryCharge] = useState(0);
  const [justifyDelivery, setJustifyDelivery] = useState(0.0);
  const [actualTotal, setActualTotal] = useState(0.0);
  const [finalDataState, setFinalDataState] = useState();
  const [productAvailable, setIsProductAvailbale] = useState();
  const [showForms, setShowForms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manualRestPromo, setManualRestPromo] = useState(0);
  const [manualDelPromo, setManualDelPromo] = useState(0);
  const closeFormsPopUp = (value) => {
    setShowForms(value);
  };

  function handleClickProfile(cartValue, item_total) {
    if (cartValue) {
      let { min_order_value } = cartValue;
      if (parseInt(item_total) < min_order_value) {
        setMinOrderMessage(
          `Minimum order value should be S$ ${min_order_value}`
        );
      } else {
        if (productAvailable != 0) {
          navigate("/payment", { state: { item_total: item_total } });
          sessionStorage.setItem("paymentPage", 2);
        }
      }
    }
    const productAvailabilityApi = async () => {
      let productAvailabilityObject = {
        restaurant_id: sessionStorage.getItem("restaurant_product_inCart")
          ? sessionStorage.getItem("restaurant_product_inCart")
          : sessionStorage.getItem("restaurantData")
          ? sessionStorage.getItem("restaurantData")
          : null,
        order_value: actualTotal,
      };
      try {
        let productAvailabilityResponse = await productAvailabilityCheckApi(
          productAvailabilityObject
        );
        setIsProductAvailbale(
          productAvailabilityResponse?.data?.data?.isProductsAvailable
        );

        if (productAvailabilityResponse?.data?.data?.isProductsAvailable == 0) {
          swal({
            title: "Error!",
            text: "Selected Product in Cart Item Is Not Available,please remove that Item",
            type: "Error",
            timer: 2500,
            button: false,
            icon: "error",
            className: "popuptetx",
          });
          globalCheckoutCallback();
          productListingValuesApi();
        }
      } catch (e) {}
    };
    productAvailabilityApi();

    item_total = item_total ? parseFloat(item_total)?.toFixed(2) : 0;
  }
  const cartValueValuesApi = async (data) => {
    try {
      let cartValueResponse = await cartValueApi();
      setCartValue(cartValueResponse.data.data);
      setMinOrderMessage("");
    } catch (e) {}
  };
  useEffect(() => {
    if (isRestaurentPage) {
      sessionStorage.setItem("finallyAppliedPromoCode", null);
    }
    dispatch(
      itemTotalData(cartValue?.item_total > 0 ? cartValue?.item_total > 0 : 0)
    );
    if (sessionStorage.getItem("userLocation")) {
      // if (restStatus && restStatus?.status == 200) {
      cartValueValuesApi();
      // }
    }
  }, [restStatus?.status]);
  //  const applyPromoManualCallBack = () => {
  //   promoManual(applyPromoCodeApiValuesApi()); //callback function

  // };

  useEffect(() => {
    // if (location.pathname !== "/payment") {
    //   cartValueValuesApi();
    // }
    sessionStorage.setItem(
      "restaurant_product_inCart",
      globalCheckoutDetails?.restaurant_product_inCart?.rest_id
    );
    if (globalCheckoutDetails) {
      // handleRemovePromo();
    }
  }, [globalCheckoutDetails, addRemoveVariantCount]);
  //loading the cart Item What We added That Will be Show
  useEffect(() => {
    if (location.pathname !== "/payment") {
      sessionStorage.setItem(
        "restaurant_product_inCart",
        globalCheckoutDetails?.restaurant_product_inCart?.rest_id
      );
      globalCheckoutCallback(); //API default call
    }
  }, [addRemoveVariantCount]);
  //
  const formik = useFormik({
    initialValues: {
      promocode: "",
    },
    validationSchema: yup.object({
      promocode: yup.string(),
    }),
  });

  useEffect(() => {
    if (location.pathname !== "/payment") {
      dispatch(cartQuantityChangeViceVersa(stateValue));
    }
  }, [stateValue]);

  const addRemoveProductCartApiValuesApi = async (data) => {
    setLoading(true);
    setProductCount(parseInt(data.product_quantity));
    let postpostProductListingValuesObjectValuesObject = {
      product_id: data?.product_id,
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
      is_variant_selected: "2",
      prod_quantity:
        parseInt(data.product_quantity) > 0
          ? parseInt(data.product_quantity)
          : 0,
    };

    try {
      let addRemoveProductCartApiValuesApiValuesResponse =
        await addRemoveProductCartApi(
          postpostProductListingValuesObjectValuesObject
        );

      if (
        addRemoveProductCartApiValuesApiValuesResponse?.data?.status === 200
      ) {
        setStateValue((f) => f + 1);
        // cartValueValuesApi();
        // globalCheckoutCallback();
        // applyPromoCodeApiValuesApi();
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (e) {}
  };

  const emptyCartApiValuesApi = async () => {
    try {
      let emptyCartApiResponse = await emptyCartApi();
      if (emptyCartApiResponse) {
        setStateValue((f) => f + 1);
        globalCheckoutCallback();
        // if (!globalCheckoutDetails?.cart_products) {
        // }
      }
      if (emptyCartApiResponse?.data?.status == 200) {
        productListingValuesApi();
      }
    } catch (e) {}
  };

  const handleIncrement = (obj) => {
    obj.product_quantity = parseInt(obj.product_quantity) + 1;
    addRemoveProductCartApiValuesApi(obj);
  };

  const handleDecrement = (obj) => {
    if (obj.product_quantity && obj.product_quantity > 0) {
      obj.product_quantity = parseInt(obj.product_quantity) - 1;
      addRemoveProductCartApiValuesApi(obj);
    }
  };

  useEffect(() => {
    cartValueValuesApi();
  }, [window.location.pathname, globalCheckoutDetails]);

  //Apply PromoCode
  //-----------------
  const [manualResponse, setManualResponse] = useState();
  const applyPromoCodeApiValuesApi = async (data) => {
    let applyPromoCodeValuesObject = {
      promo_code: formik.values.promocode,
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
      item_total: globalCheckoutDetails?.item_total,
      order_type: selfPickUpValue() ? selfPickUpValue() : "1",
      pickup_time: sessionStorage.getItem("pickupFrom")
        ? sessionStorage.getItem("pickupFrom")
        : singaporeTimeConverter(),
    };

    sessionStorage.setItem(
      "manualpromovalues",
      JSON.stringify(applyPromoCodeValuesObject)
    );

    try {
      let applyPromoCodeResponse = await applyPromoCodeApi(
        applyPromoCodeValuesObject
      );
      if (getUserType() == 2) {
        setShowForms(true);
      }

      globalCheckoutCallback();

      if (applyPromoCodeResponse?.data?.status === 201) {
        setManualResponse(applyPromoCodeResponse?.data?.status);
        setPromoCodeMessage(applyPromoCodeResponse.data.message);
        sessionStorage.setItem("finallyAppliedPromoCode", null);
        sessionStorage.setItem(
          "PromoCode",
          applyPromoCodeResponse?.data?.status
        );
        setDisCountAutoApplyPromo(0);
        setDeliveryDiscount(0);
        setManualApplyPromocode(0);
        // Restore delivery amount

        //DELIVERY CHARGE
        // let deliveryByRestaurant = globalCheckoutDetails?.delivery_handled_by;
        // let deliveyDistanceKm = globalCheckoutDetails?.distance_in_km;
        // //change double deliveyDistanceKm
        // let perKmCharge = globalCheckoutDetails?.per_km_charge;

        // // Lala merchent delivery charge calculation
        // let LalaAmoutMerchant = globalCheckoutDetails?.lalamove_order_amount;
        // let finalDeliveryCharge = 0;
        // if (deliveryByRestaurant === "1") {
        //   if (deliveyDistanceKm !== "" || deliveyDistanceKm !== null) {
        //     if (OrderTypeSession === "2") {
        //       finalDeliveryCharge = 0;
        //     } else {
        //       finalDeliveryCharge = deliveyDistanceKm * perKmCharge;
        //     }
        //   } else {
        //     finalDeliveryCharge = 0;
        //   }
        // } else if (deliveryByRestaurant === "2") {
        //   //deliveryBy Merchant
        //   if (LalaAmoutMerchant !== "" || LalaAmoutMerchant !== null) {
        //     finalDeliveryCharge = LalaAmoutMerchant;
        //   } else {
        //     finalDeliveryCharge = 0;
        //   }
        // }
        // setFinalDeliveryCharge(finalDeliveryCharge);
        // setActualTotal(
        //   parseFloat(globalCheckoutDetails?.item_total) +
        //     parseFloat(finalDeliveryCharge)
        // );
      } else {
        sessionStorage.setItem(
          "finallyAppliedPromoCode",
          formik.values.promocode
        );
        setPromoCodeMessage("");
        formik.setFieldValue("promocode", "");
        setPromocodeManualDetails(applyPromoCodeResponse.data.data);
        setManualApplyPromocode(1);
        if (
          Object.keys(applyPromoCodeResponse?.data?.data)[0] ===
          "promocode_details"
        ) {
          setManualRestPromo(1);
          setManualDelPromo(0);
        } else if (
          Object.keys(applyPromoCodeResponse?.data?.data)[0] ===
          "delivery_charge_promotion"
        ) {
          setManualDelPromo(1);
          setManualRestPromo(0);
        }
        setDisCountAutoApplyPromo(0);
        setDeliveryDiscount(0);
        sessionStorage.setItem(
          "PromoCode",
          applyPromoCodeResponse?.data?.status
        );
        // globalCheckoutCallback();
      }

      //if((location.pathname!="/payment") && (sessionStorage.getItem("ManualPromo")!="undefined") &&  (parseFloat(disCountAutoApplyPromo).toFixed(2))>0){

      // }
    } catch (e) {}
  };

  const autoTriggerManualPromo = async () => {
    let applyPromoCodeValuesObject = JSON.parse(
      sessionStorage.getItem("manualpromovalues")
    );
    try {
      let applyPromoCodeResponse = await applyPromoCodeApi(
        applyPromoCodeValuesObject
      );
      if (getUserType() == 2) {
        setShowForms(true);
      }

      if (applyPromoCodeResponse?.data?.status === 201) {
        setManualResponse(applyPromoCodeResponse?.data?.status);
        setPromoCodeMessage(applyPromoCodeResponse.data.message);
        sessionStorage.setItem("finallyAppliedPromoCode", null);
        sessionStorage.setItem(
          "PromoCode",
          applyPromoCodeResponse?.data?.status
        );
        setDisCountAutoApplyPromo(0);
        setDeliveryDiscount(0);
        setManualApplyPromocode(0);
        // Restore delivery amount

        //DELIVERY CHARGE
        // let deliveryByRestaurant = globalCheckoutDetails?.delivery_handled_by;
        // let deliveyDistanceKm = globalCheckoutDetails?.distance_in_km;
        // //change double deliveyDistanceKm
        // let perKmCharge = globalCheckoutDetails?.per_km_charge;

        // // Lala merchent delivery charge calculation
        // let LalaAmoutMerchant = globalCheckoutDetails?.lalamove_order_amount;
        // let finalDeliveryCharge = 0;
        // if (deliveryByRestaurant === "1") {
        //   if (deliveyDistanceKm !== "" || deliveyDistanceKm !== null) {
        //     if (OrderTypeSession === "2") {
        //       finalDeliveryCharge = 0;
        //     } else {
        //       finalDeliveryCharge = deliveyDistanceKm * perKmCharge;
        //     }
        //   } else {
        //     finalDeliveryCharge = 0;
        //   }
        // } else if (deliveryByRestaurant === "2") {
        //   //deliveryBy Merchant
        //   if (LalaAmoutMerchant !== "" || LalaAmoutMerchant !== null) {
        //     finalDeliveryCharge = LalaAmoutMerchant;
        //   } else {
        //     finalDeliveryCharge = 0;
        //   }
        // }
        // setFinalDeliveryCharge(finalDeliveryCharge);
        // setActualTotal(
        //   parseFloat(globalCheckoutDetails?.item_total) +
        //     parseFloat(finalDeliveryCharge)
        // );
      } else {
        sessionStorage.setItem(
          "finallyAppliedPromoCode",
          formik.values.promocode
        );
        setPromoCodeMessage("");
        formik.setFieldValue("promocode", "");
        setPromocodeManualDetails(applyPromoCodeResponse.data.data);
        setManualApplyPromocode(1);
        if (
          Object.keys(applyPromoCodeResponse?.data?.data)[0] ===
          "promocode_details"
        ) {
          setManualRestPromo(1);
          setManualDelPromo(0);
        } else if (
          Object.keys(applyPromoCodeResponse?.data?.data)[0] ===
          "delivery_charge_promotion"
        ) {
          setManualDelPromo(1);
          setManualRestPromo(0);
        }
        setDisCountAutoApplyPromo(0);
        setDeliveryDiscount(0);
        sessionStorage.setItem(
          "PromoCode",
          applyPromoCodeResponse?.data?.status
        );
        // globalCheckoutCallback();
      }

      //if((location.pathname!="/payment") && (sessionStorage.getItem("ManualPromo")!="undefined") &&  (parseFloat(disCountAutoApplyPromo).toFixed(2))>0){

      // }
    } catch (e) {}
  };

  useEffect(() => {
    if (
      window.location.pathname === "/payment" &&
      sessionStorage.getItem("manualpromovalues") != undefined
    ) {
      setTimeout(() => {
        autoTriggerManualPromo();
      }, 800);
    }
  }, [globalCheckoutDetails]);

  const handleClickApply = () => {
    if (disCountAutoApplyPromo != 0) {
      sessionStorage.setItem(
        "ManualPromo",
        parseFloat(disCountAutoApplyPromo).toFixed(2)
      );
    }
  };

  const variantDetails = (obj) => {
    let concatVariantString = "";
    if (obj.variants.length > 0) {
      obj.variants.map((data) => {
        concatVariantString =
          concatVariantString +
          `${data.variant_name}(${data.variant_type_name}) `;
      });
      return concatVariantString;
    }
    return "";
  };
  const [handleInput, setHandleInput] = useState();
  const handleInstructionChange = (e) => {
    setHandleInput(e.target.value);
    sessionStorage.setItem("instructionInput", e.target.value);
  };
  const [timeSlotFlag, setTimeSlotFlag] = useState(false);
  const getTimeSlotFlag = (flag) => {
    setTimeSlotFlag(flag);
  };

  const OrderTypeSession = sessionStorage.getItem("selfPickup");
  // const manualDeliveryChargePromo=()=>{
  //   let finalDeliveryCharge = 0;

  //   let deliveryChargePromotionData =
  //   promocodeManualDetails?.delivery_charge_promotion;
  // let deliveryDiscountData =
  //   promocodeManualDetails?.delivery_charge_promotion?.discount_value;

  // let deliveryChargePromoTypeData =
  //   promocodeManualDetails?.delivery_charge_promotion?.promo_type;
  // let maxDeliveryDiscountData =
  //   promocodeManualDetails?.delivery_charge_promotion?.max_discount;
  // let maxDeliveryDiscountDeliveryDataVal =
  //   promocodeManualDetails?.delivery_charge_promotion?.max_delivery_discount;

  // if (
  //   deliveryChargePromotionData !== null &&
  //   deliveryDiscountData !== null &&
  //   deliveryDiscountData !== ""
  // ) {
  //   if (deliveryChargePromoTypeData === "1") {
  //     if (finalDeliveryCharge > 0) {
  //       finalDeliveryCharge = finalDeliveryCharge - deliveryDiscountData;
  //     } else {
  //       finalDeliveryCharge = 0;
  //     }
  //     setJustifyDelivery(deliveryDiscountData);
  //   } else if (deliveryChargePromoTypeData === "2") {
  //     let dicountedPerctageValue = 0;
  //     if (finalDeliveryCharge > 0) {
  //       setJustifyDelivery((finalDeliveryCharge * deliveryDiscountData) / 100)
  //       if (maxDeliveryDiscountData > 0) {
  //         if (finalDeliveryCharge > maxDeliveryDiscountData) {
  //           dicountedPerctageValue =
  //             finalDeliveryCharge - maxDeliveryDiscountData;
  //           finalDeliveryCharge = dicountedPerctageValue;
  //         } else {
  //           dicountedPerctageValue = finalDeliveryCharge - justifyDelivery;
  //           finalDeliveryCharge = dicountedPerctageValue;
  //         }
  //       } else {
  //         dicountedPerctageValue = finalDeliveryCharge - justifyDelivery;
  //         finalDeliveryCharge = dicountedPerctageValue;
  //       }
  //       if (
  //         maxDeliveryDiscountDeliveryDataVal &&
  //         maxDeliveryDiscountDeliveryDataVal !== null &&
  //         parseInt(maxDeliveryDiscountDeliveryDataVal) !== 0
  //       ) {
  //         //it will be double
  //         if (
  //           finalDeliveryCharge >
  //           parseFloat(maxDeliveryDiscountDeliveryDataVal)
  //         ) {
  //           finalDeliveryCharge = maxDeliveryDiscountDeliveryDataVal;
  //         } else {
  //           finalDeliveryCharge = dicountedPerctageValue;
  //         }
  //       }
  //     } else {
  //       finalDeliveryCharge = 0;
  //     }
  //     setJustifyDelivery(deliveryDiscountData);
  //   }
  // } else {
  //   finalDeliveryCharge = 0;
  // }

  // }

  const [deliveryValue, setDeliveryValue] = useState(0);
  const [finTotal, setFinTotal] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [deliveryDiscount, setDeliveryDiscount] = useState(0);

  // useEffect(() => {
  //   globalCheckoutCallback();
  // }, [sessionStorage.getItem("userLocation")]);


  const handlePromoCode = () => {
    sessionStorage.setItem(
      "lalamove_track_link",
      globalCheckoutDetails?.lalamove_track_link
    );

    // basic promo required details
    let promoCodeAuto =
      globalCheckoutDetails?.promocode_details?.[0]?.is_auto_apply;
    let deliveryPromoAuto =
      globalCheckoutDetails?.delivery_charge_promotion?.[0]?.is_auto_apply;
    let deliveryByRestaurant = globalCheckoutDetails?.delivery_handled_by;
    let deliveryDistanceKm = globalCheckoutDetails?.distance_in_km;
    let perKmCharge = globalCheckoutDetails?.per_km_charge;

    //subtotal before discount
    let subtotal = globalCheckoutDetails?.item_total;

    //basic delivery charge calculation - before discount delivery charge
    let deliveryCharge = 0;
    let LalaAmoutMerchant = globalCheckoutDetails?.lalamove_order_amount;
    if (deliveryByRestaurant === "1") {
      if (deliveryDistanceKm !== "" || deliveryDistanceKm !== null) {
        if (OrderTypeSession === "2") {
          deliveryCharge = 0;
        } else {
          // deliveryCharge = deliveryDistanceKm * perKmCharge;
          deliveryCharge = globalCheckoutDetails?.restaurant_delivery_charge;
        }
      } else {
        deliveryCharge = 0;
      }
    } else if (deliveryByRestaurant === "2") {
      if (LalaAmoutMerchant !== "" || LalaAmoutMerchant !== null) {
        deliveryCharge = LalaAmoutMerchant;
      } else {
        deliveryCharge = 0;
      }
    }


    setDeliveryValue(deliveryCharge);

    

    // let promoAppliedTo =
    //   promocodeManualDetails != null &&
    //   Object.keys(promocodeManualDetails) == "delivery_charge_promotion"
    //     ? "delivery"
    //     : Object.keys(promocodeManualDetails) == "promocode_details"
    //     ? "subtotal"
    //     : globalCheckoutDetails?.delivery_charge_promotion?.is_auto_apply
    //     ? "delivery"
    //     : globalCheckoutDetails?.promocode_details?.[0]?.is_auto_apply
    //     ? "subtotal"
    //     : null;

    let deliveryChargePromotion;
    let deliveryDiscount;
    let deliveryChargePromoType;
    let maxDeliveryDiscount;
    let maxDeliveryDiscountDelivery;
    if (
      promocodeManualDetails != null &&
      promocodeManualDetails != undefined &&
      Object.keys(promocodeManualDetails) == "delivery_charge_promotion" &&
      promocodeManualDetails?.delivery_charge_promotion?.level_id != "8"
    ) {
      deliveryChargePromotion =
        promocodeManualDetails?.delivery_charge_promotion;
      deliveryDiscount = parseFloat(
        promocodeManualDetails?.delivery_charge_promotion?.discount_value
      );
      deliveryChargePromoType =
        promocodeManualDetails?.delivery_charge_promotion?.promo_type;
      maxDeliveryDiscount =
        promocodeManualDetails?.delivery_charge_promotion?.max_discount;
      maxDeliveryDiscountDelivery =
        promocodeManualDetails?.delivery_charge_promotion
          ?.max_delivery_discount;
    } else if (
      globalCheckoutDetails?.delivery_charge_promotion?.level_id != "8"
    ) {
      deliveryChargePromotion =
        globalCheckoutDetails?.delivery_charge_promotion;
      deliveryDiscount = parseFloat(
        globalCheckoutDetails?.delivery_charge_promotion?.discount_value
      );
      deliveryChargePromoType =
        globalCheckoutDetails?.delivery_charge_promotion?.promo_type;
      maxDeliveryDiscount = parseFloat(
        globalCheckoutDetails?.delivery_charge_promotion?.max_discount
      );
      maxDeliveryDiscountDelivery =
        globalCheckoutDetails?.delivery_charge_promotion?.max_delivery_discount;
    }

    //delivery promo details
    if (
      deliveryChargePromotion !== null &&
      deliveryDiscount !== null &&
      deliveryDiscount !== ""
    ) {
      if (deliveryChargePromoType === "1") {
        //flat delivery promo
        if (deliveryCharge > 0) {
          deliveryCharge = deliveryCharge - deliveryDiscount;
          setDeliveryDiscount(deliveryDiscount);
        } else {
          deliveryCharge = 0;
        }
        setDeliveryValue(deliveryCharge);
      } else if (deliveryChargePromoType === "2") {
        let dicountedPerctageValue = 0;
        let discountedDelivery;
        if (deliveryCharge > 0) {
          discountedDelivery = (deliveryCharge * deliveryDiscount) / 100;
         
          if (maxDeliveryDiscount > 0) {
            if (deliveryCharge > maxDeliveryDiscount) {
              dicountedPerctageValue = deliveryCharge - maxDeliveryDiscount;
              deliveryCharge = dicountedPerctageValue;
            } else {
              dicountedPerctageValue = deliveryCharge - discountedDelivery;
              deliveryCharge = dicountedPerctageValue;
            }
          } else {
            dicountedPerctageValue = deliveryCharge - discountedDelivery;
            deliveryCharge = dicountedPerctageValue;
          }
          if (
            maxDeliveryDiscountDelivery &&
            maxDeliveryDiscountDelivery !== null &&
            parseInt(maxDeliveryDiscountDelivery) !== 0
          ) {
            //it will be double
            if (deliveryCharge > parseFloat(maxDeliveryDiscountDelivery)) {
              deliveryCharge = maxDeliveryDiscountDelivery;
              setDeliveryDiscount(`MAX-${maxDeliveryDiscountDelivery}`);
            } else {
              
              deliveryCharge = dicountedPerctageValue;
              setDeliveryDiscount(discountedDelivery);
            }
          }
        } else {
          deliveryCharge = 0;
        }
        setDeliveryValue(deliveryCharge);
      }
      // setJustifyDelivery(deliveryDiscount);
    } else {
      deliveryCharge = 0;
    }

    //RESTAURANT PROMO DETAILS STARTS HERE

    let promocode_detailsPromotionData;
    let promocode_detailsPromotionDataDiscountData;
    let promocode_detailsPromoType;
    let promocode_detailsDiscountData;
    let maxDeliveryDiscountDeliveryData;
    if (
      promocodeManualDetails != null &&
      promocodeManualDetails != undefined &&
      Object.keys(promocodeManualDetails) == "promocode_details"
    ) {
      promocode_detailsPromotionData =
        promocodeManualDetails?.promocode_details?.[0];
      promocode_detailsPromotionDataDiscountData =
        promocodeManualDetails?.promocode_details?.[0]?.discount_value;

      promocode_detailsPromoType =
        promocodeManualDetails?.promocode_details?.[0]?.promo_type;
      promocode_detailsDiscountData = parseFloat(
        promocodeManualDetails?.promocode_details?.[0]?.max_discount
      );
      maxDeliveryDiscountDeliveryData =
        promocodeManualDetails?.promocode_details?.[0]?.max_delivery_discount;
    } else if (
      promocodeManualDetails != null &&
      promocodeManualDetails != undefined &&
      Object.keys(promocodeManualDetails) == "delivery_charge_promotion" &&
      promocodeManualDetails?.delivery_charge_promotion?.level_id == 8
    ) {
      promocode_detailsPromotionData =
        promocodeManualDetails?.delivery_charge_promotion;
      promocode_detailsPromotionDataDiscountData =
        promocodeManualDetails?.delivery_charge_promotion?.discount_value;

      promocode_detailsPromoType =
        promocodeManualDetails?.delivery_charge_promotion?.promo_type;
      promocode_detailsDiscountData = parseFloat(
        promocodeManualDetails?.delivery_charge_promotion?.max_discount
      );
      maxDeliveryDiscountDeliveryData =
        promocodeManualDetails?.delivery_charge_promotion
          ?.max_delivery_discount;
    } else if (
      globalCheckoutDetails?.delivery_charge_promotion?.level_id == "8"
    ) {
      promocode_detailsPromotionData =
        globalCheckoutDetails?.delivery_charge_promotion;
      promocode_detailsPromotionDataDiscountData =
        globalCheckoutDetails?.delivery_charge_promotion?.discount_value;

      promocode_detailsPromoType =
        globalCheckoutDetails?.delivery_charge_promotion?.promo_type;
      promocode_detailsDiscountData = parseFloat(
        globalCheckoutDetails?.delivery_charge_promotion?.max_discount
      );
      maxDeliveryDiscountDeliveryData =
        globalCheckoutDetails?.delivery_charge_promotion?.max_delivery_discount;
    } else {
      
      promocode_detailsPromotionData =
        globalCheckoutDetails?.promocode_details?.[0];
      promocode_detailsPromotionDataDiscountData =
        globalCheckoutDetails?.promocode_details?.[0]?.discount_value;

      promocode_detailsPromoType =
        globalCheckoutDetails?.promocode_details?.[0]?.promo_type;
      promocode_detailsDiscountData = parseFloat(
        globalCheckoutDetails?.promocode_details?.[0]?.max_discount
      );
      maxDeliveryDiscountDeliveryData =
        globalCheckoutDetails?.promocode_details?.[0]?.max_delivery_discount;
    }


    if (
      promocode_detailsPromotionData !== null &&
      promocode_detailsPromotionDataDiscountData !== null &&
      promocode_detailsPromotionDataDiscountData !== ""
    ) {
      if (promocode_detailsPromoType == "1") {
        setDisCountAutoApplyPromo(promocode_detailsPromotionDataDiscountData);
        if (subtotal > 0) {
          subtotal = subtotal - promocode_detailsPromotionDataDiscountData;
        } else {
          return subtotal;
        }
        setFinTotal(subtotal);
      } else if (promocode_detailsPromoType == "2") {
        let subtotalDiscount =
          (globalCheckoutDetails?.item_total *
            promocode_detailsPromotionDataDiscountData) /
          100;
        if (
          promocode_detailsDiscountData > 0 &&
          subtotalDiscount > promocode_detailsDiscountData
        ) {
          subtotalDiscount = promocode_detailsDiscountData;
        }
        setDisCountAutoApplyPromo(subtotalDiscount);
        subtotal = parseFloat(subtotal) - parseFloat(subtotalDiscount);
       
      }
    }

    let finalSubtotal = subtotal ? subtotal : 0.0;
    let finalDeliveryTotal = deliveryCharge ? deliveryCharge : 0.0;

    let FinalActuaTotal =
      parseFloat(finalSubtotal) +
      (finalDeliveryTotal > 0 ? parseFloat(finalDeliveryTotal) : 0);

    
    setActualTotal(parseFloat(FinalActuaTotal).toFixed(2));
    setFinalDataState(parseFloat(FinalActuaTotal).toFixed(2));
    sessionStorage.setItem(
      "ActualAutoTotal",
      parseFloat(FinalActuaTotal).toFixed(2)
    );
    // let finalSubTotal1 = globalCheckoutDetails?.item_total
    //   ? parseFloat(globalCheckoutDetails?.item_total)
    //   : 0.0;
    // let finalSubTotal =
    //   finalSubTotal1 -
    //   (manualApplyPromocode === "2" &&
    //   promocode_detailsPromotionDataDiscountData
    //     ? parseFloat(promocode_detailsPromotionDataDiscountData)
    //     : parseFloat(finalPromoDiscount));

    // setDisCountAutoApplyPromo(
    //   manualApplyPromocode === "2" && promocode_detailsPromotionDataDiscountData
    //     ? promocode_detailsPromotionDataDiscountData
    //     : finalPromoDiscount
    // );
    // setShowSubTotalAutoApply(finalSubTotal);
    // setFinalDeliveryCharge(finalDeliveryCharge);

    // let total1 = parseFloat(finalSubTotal).toFixed(2);
    // let total2 = parseFloat(
    //   Math.round(finalDeliveryCharge * 100) / 100
    // ).toFixed(2);
    // let FinalActuaTotal =
    //   parseFloat(total1) + (parseFloat(total2) > 0 ? parseFloat(total2) : 0);
    // setActualTotal(FinalActuaTotal.toFixed(2));
    // setFinalDataState(FinalActuaTotal.toFixed(2));

    // if (location.pathname !== "/payment") {
    //   sessionStorage.setItem("ActualAutoTotal", FinalActuaTotal.toFixed(2));
    // }
  };

  // const callManualApplyPromocode = async () => {
  //   if (!isRestaurentPage) {
  //     if (sessionStorage.getItem("finallyAppliedPromoCode")) {
  //       let applyPromoCodeValuesObject = {
  //         promo_code: sessionStorage.getItem("finallyAppliedPromoCode"),
  //         restaurant_id: sessionStorage.getItem("restaurantData")
  //           ? sessionStorage.getItem("restaurantData")
  //           : null,
  //         item_total: cartValue?.item_total,
  //         order_type: selfPickUpValue() ? selfPickUpValue() : "1",
  //         pickup_time: sessionStorage.getItem("pickupFrom")
  //           ? sessionStorage.getItem("pickupFrom")
  //           : singaporeTimeConverter(),
  //       };

  //       try {
  //         let applyPromoCodeResponse = await applyPromoCodeApi(
  //           applyPromoCodeValuesObject
  //         );
  //         if (applyPromoCodeResponse?.data?.status === 201) {
  //         } else {
  //           setPromocodeManualDetails(applyPromoCodeResponse.data.data);
  //         }
  //       } catch (e) {}
  //     }
  //   }
  // };

  useEffect(() => {
    if (globalCheckoutDetails || promocodeManualDetails) {
      // handleDiscounts();
      handlePromoCode();
    }
  }, [globalCheckoutDetails, promocodeManualDetails]);

  useEffect(() => {
  
  }, [globalCheckoutDetails]);

  sessionStorage.setItem(
    "globalCheckoutDetails",
    JSON.stringify(globalCheckoutDetails)
  );

  const location = useLocation();
  const params = new URLSearchParams();

  const NavigateToRestaurant = () => {
    // let restId = sessionStorage.getItem("restaurantData");
    let cartId = sessionStorage.getItem("restaurant_product_inCart");
    sessionStorage.setItem("restaurantData", cartId);
    sessionStorage.setItem(
      "identifier_key",
      globalCheckoutDetails?.restaurant_product_inCart?.rest_identifier
    );
    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;

    navigate({
      pathname: `/restaurants/${RestUrl}`,
      search: params.toString(), // '?name=John&age=32
    });
    // if(location.pathname!=`/restaurants/${RestUrl}`)
    // {
    //   navigate({
    //     pathname: `/restaurants/${RestUrl}`,
    //     search: params.toString() // '?name=John&age=32
    //     })
    //     window.location.reload(true);

    //  };

    // if (location.pathname === "/payment") {
    //   navigate({
    //     pathname: `/restaurants/${RestUrl}`,
    //     search: params.toString(), // '?name=John&age=32
    //   });
    // } else  {
    //   navigate({
    //     pathname: `/restaurants/${RestUrl}`,
    //     search: params.toString(), // '?name=John&age=32
    //   });

    // }
  };
  sessionStorage.setItem(
    "Dc_Amount",
    JSON.stringify(
      deliveryValue > 0 ? (Math.round(deliveryValue * 100) / 100).toFixed(2) : 0
    )
  );
  sessionStorage.setItem(
    "promo",
    parseFloat(disCountAutoApplyPromo).toFixed(2)
  );
  if (location.pathname != "/payment") {
    sessionStorage.setItem("itemTotal", globalCheckoutDetails?.item_total);
  }
  const [isMobile, setIsMobile] = useState(false);
  let width = window.innerWidth;

  useEffect(() => {
    if (width > 769 && width < 992) {
      setIsMobile(true);
    }
    // trendingDatailsDataApi();
  }, [width]);

  const handleRemovePromo = () => {
    sessionStorage.removeItem("manualpromovalues");
    setPromocodeManualDetails("");
    setDeliveryDiscount(0);
    setDisCountAutoApplyPromo(0);
    setManualApplyPromocode(0);
    setManualRestPromo(0);
    setManualDelPromo(0);
    globalCheckoutCallback();
  };

  // useEffect(() => {
  //   if (actualTotal > 0) {
  //     handleRemovePromo();
  //   }
  // }, [actualTotal]);

  useEffect(() => {
    if (window.location.pathname != "/payment") {
      if (globalCheckoutDetails?.item_total > 0) {
        handleRemovePromo();
      }
    }
  }, [selfPickUpValue(), globalCheckoutDetails?.item_total]);

  useEffect(() => {
    if (manualApplyPromocode > 0 && manualRestPromo > 0) {
      if (disCountAutoApplyPromo > 0) {
        sessionStorage.setItem("subtotalPromo", disCountAutoApplyPromo);
      }
      sessionStorage.setItem(
        "subtotalPromoId",
        promocodeManualDetails?.promocode_details?.[0]?.id
      );
    } else {
      if (disCountAutoApplyPromo > 0) {
        sessionStorage.setItem("subtotalPromo", disCountAutoApplyPromo);
      }
      sessionStorage.setItem(
        "subtotalPromoId",
        globalCheckoutDetails?.promocode_details?.[0]?.id
      );
    }
  }, [disCountAutoApplyPromo]);

  return (
    <>
      <Card
        className={
          isMobile == true && location.pathname == "/payment"
            ? "cartpopup_card cartpopup_cardPayment"
            : "cartpopup_card"
        }
      >
        <Card.Body>
          <CartItemPopupHeader />
          {/* <div className="cutlery-content">
            <small style={{ fontWeight: "500", fontSize: "12px" }}>
              Consider using your own cutlery to reduce plastic use
            </small>
          </div> */}
          {sessionStorage.getItem("restaurant_product_inCart") ||
          sessionStorage.getItem("restaurantData") ? (
            <LoginView
              getTimeSlotFlag={getTimeSlotFlag}
              globalCheckoutCallback={globalCheckoutCallback}
              globalCheckoutDetails={globalCheckoutDetails}
              productItems={productItems}
            />
          ) : null}
          <div className="order-text" onClick={() => NavigateToRestaurant()}>
            {globalCheckoutDetails?.restaurant_product_inCart?.rest_name ? (
              <p>
                <b>
                  {`Your Order from the ${globalCheckoutDetails?.restaurant_product_inCart?.rest_name}`}
                </b>
              </p>
            ) : null}
            {globalCheckoutDetails?.restaurant_product_inCart
              ?.rest_pin_address ? (
              <p>
                <b>{`(${globalCheckoutDetails?.restaurant_product_inCart?.rest_pin_address}
                  ${globalCheckoutDetails?.restaurant_product_inCart?.rest_street_address}
                  ${globalCheckoutDetails?.restaurant_product_inCart?.rest_unit_number}
                  ${globalCheckoutDetails?.restaurant_product_inCart?.rest_postal_code})`}</b>
              </p>
            ) : null}
          </div>
          {!window.location.href.includes("/payment") ? (
            <div className="clear-all">
              <small onClick={emptyCartApiValuesApi}>Clear All</small>
            </div>
          ) : null}
          {checkoutLoading ? (
            <div className="ldgnCartItem">
              <img src={CartLoading} />
              <p className="mb-0">Loading Your Cart Items</p>
            </div>
          ) : (
            <div className="cart-scroll-container">
              {globalCheckoutDetails?.cart_products &&
                globalCheckoutDetails?.cart_products.map(
                  (checkoutdataObj, index) => {
                    return (
                      <>
                        <div className="item-card-cart">
                          <div className="image-cart-div">
                            <small
                              className="foodTitle"
                              style={{ fontWeight: "600", fontSize: "100%" }}
                            >
                              {checkoutdataObj.product_name}
                            </small>
                            <p
                              className={"price mb-0 ms-1"}
                              // checkoutdataObj.offer_price > 0
                              //   ? "striking-text"
                              //   :
                            >
                              {checkoutdataObj?.offer_price > 0
                                ? "S$" + checkoutdataObj?.offer_price
                                : "S$" + checkoutdataObj?.price}
                            </p>
                            {/* {checkoutdataObj.offer_price > 0 ? (
                          <p className="price">
                            ${checkoutdataObj.offer_price}
                          </p>
                        ) : null} */}
                            <p className="itemDescription">
                              {variantDetails(checkoutdataObj)}
                            </p>
                          </div>
                          <div className="button-cart-div">
                            <div>
                              <img
                                src={checkoutdataObj.product_image}
                                alt=""
                                className={
                                  location.pathname != "/payment"
                                    ? "itemImage ms-2 "
                                    : "itemImagePayment"
                                }
                              ></img>
                            </div>
                            {!window.location.href.includes("/payment") ? (
                              <div className="add-btn">
                                <Button id={index} disabled={loading}>
                                  <div
                                    className="in-de-btn"
                                    onClick={() =>
                                      handleDecrement(checkoutdataObj)
                                    }
                                  >
                                    -
                                  </div>
                                  <div className="count">
                                    {checkoutdataObj.product_quantity}
                                  </div>
                                  <div
                                    className="in-de-btn"
                                    onClick={() => {
                                      handleIncrement(checkoutdataObj);
                                    }}
                                  >
                                    +
                                  </div>
                                </Button>
                              </div>
                            ) : (
                              <div className="count chkOutCount">
                                Qty: {checkoutdataObj.product_quantity}
                              </div>
                            )}
                          </div>
                        </div>
                        <hr className="itemCartProductDivider" />
                      </>
                    );
                  }
                )}
            </div>
          )}

          <div>
            <input
              id="instructionText"
              name="instructionText"
              value={
                sessionStorage.getItem("instructionInput")
                  ? sessionStorage.getItem("instructionInput")
                  : handleInput
              }
              placeholder="Add Special Instructions (optional)"
              className="SpecialInstructions mb-2"
              onChange={(e) => {
                handleInstructionChange(e);
              }}
              disabled={location.pathname == "/payment"}
            ></input>
          </div>
          {isRestaurentPage ? (
            <div className="cont-promo">
              <b>Promo Code</b>
              <div className="promo-container">
                <div className="input-promo">
                  <input
                    className="promocode"
                    placeholder=" Type Promo Code"
                    id="promocode"
                    name="promocode"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    {...formik.getFieldProps("promocode")}
                  ></input>
                  <p className="error-msg">{promocodeMessage}</p>
                </div>
                <div className="btn-promo">
                  <Button
                    className="Apply_button"
                    onClick={() => {
                      applyPromoCodeApiValuesApi();
                      handleClickApply();
                    }}
                    disabled={timeSlotFlag}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          {globalCheckoutDetails?.item_total > 0 ? (
            <>
              <hr className="horizontalLine" />
              <div className="total-container">
                <div>
                  <small className="amount_details">Item Total</small>
                </div>
                <div>
                  <small className="amount_details">
                    S${globalCheckoutDetails?.item_total}
                  </small>
                </div>
              </div>
              {/* ||
                sessionStorage.getItem("ManualPromo") */}
              {disCountAutoApplyPromo != 0 ? (
                <>
                  <div className="discountPromoWrapper">
                    <div className="total-container">
                      <div>
                        <small className="amount_details redColor">
                          Promotion Discount Applied <br />
                          {manualApplyPromocode > 0 && manualRestPromo > 0 ? (
                            <>
                              <b>
                                {
                                  promocodeManualDetails?.promocode_details?.[0]
                                    ?.code_name
                                }{" "}
                              </b>
                              -{" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={handleRemovePromo}
                              >
                                <u>Remove Promo</u>
                              </span>
                            </>
                          ) : (
                            <>
                              <b>
                                {
                                  globalCheckoutDetails?.promocode_details?.[0]
                                    ?.code_name
                                }{" "}
                              </b>
                            </>
                          )}
                        </small>
                      </div>

                      <div>
                        <small className="amount_details redColor">
                          S$-
                          {parseFloat(disCountAutoApplyPromo).toFixed(2)}
                          {/* {parseFloat(disCountAutoApplyPromo).toFixed(2) > 0
                          ? parseFloat(disCountAutoApplyPromo).toFixed(2)
                          : sessionStorage.getItem("ManualPromo")} */}
                        </small>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {/* <div>
                  <small className="amount_details ">Item Total</small>
                </div>
                <div>
                  <small className="amount_details ">
                    ${parseFloat(showSubTotalAutoApply).toFixed(2)}
                  </small>
                </div> */}
              {/* <div>
              <small className="amount_details"> Actual Delivery fee</small>
            </div>
            <div>
              <small className="amount_details">
                $
                {fixedDeliveryCharge
                  ? Math.round(fixedDeliveryCharge * 100) / 100
                  : 0}
              </small>
            </div> */}
              {/* {justifyDelivery && justifyDelivery != 0 ? (
                  <>
                    <div>
                      <small className="amount_details redColor">
                        Discount value
                      </small>
                    </div>
                    <div>
                      <small className="amount_details redColor">
                        $ -{Math.round(justifyDelivery * 100) / 100}
                      </small>
                    </div>
                  </>
                ) : null} */}
              <div className="total-container">
                {sessionStorage.getItem("selfPickup") !== "2" ? (
                  <>
                    <div>
                      <small className="amount_details ">Delivery Charge</small>
                    </div>

                    <div>
                      <small className="amount_details ">
                        {deliveryValue > 0
                          ? "S$" +
                            (Math.round(deliveryValue * 100) / 100).toFixed(2)
                          : "Free Delivery"}
                      </small>
                    </div>
                  </>
                ) : null}
              </div>
              {deliveryDiscount != 0 &&
              manualApplyPromocode > 0 &&
              manualDelPromo > 0 ? (
                <div className="discountPromoWrapper">
                  <div className="total-container">
                    <div>
                      <small className="amount_details redColor">
                        Promotion Discount Applied <br />
                        <b>
                          {
                            promocodeManualDetails?.delivery_charge_promotion
                              ?.code_name
                          }{" "}
                        </b>
                        -{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleRemovePromo}
                        >
                          <u>Remove Promo</u>
                        </span>
                      </small>
                    </div>

                    <div>
                      {typeof deliveryDiscount == "string" ? (
                        <small className="amount_details redColor">
                          {deliveryDiscount}
                        </small>
                      ) : (
                        <small className="amount_details redColor">
                          S$-
                          {parseFloat(deliveryDiscount).toFixed(2)}
                          {/* {parseFloat(disCountAutoApplyPromo).toFixed(2) > 0
                          ? parseFloat(disCountAutoApplyPromo).toFixed(2)
                          : sessionStorage.getItem("ManualPromo")} */}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              <hr className="horizontalLine" />
            </>
          ) : null}
          <div className="total-container">
            <div>
              <p
                className="GrandTotalProductList"
                style={{ fontWeight: "600" }}
              >
                Grand Total{" "}
              </p>
            </div>
            <div>
              <p
                className="GrandTotalProductList"
                style={{ fontWeight: "600" }}
              >
                S${actualTotal}
              </p>
            </div>
          </div>
          <div>
            {isRestaurentPage ? (
              <>
                <Button
                  className="checkout_button ms-4"
                  onClick={() =>
                    handleClickProfile(
                      cartValue,
                      globalCheckoutDetails?.item_total
                    )
                  }
                  disabled={timeSlotFlag || actualTotal == 0.0}
                >
                  Go To CheckOut
                </Button>
                <p className="error-msg">{minOrderMessage}</p>
                {timeSlotFlag ? (
                  <p className="error-msg">
                    Kindly select valid estimated date and time
                  </p>
                ) : null}
              </>
            ) : null}
          </div>
        </Card.Body>
      </Card>
      <Forms showForms={showForms} closeFormsPopUp={closeFormsPopUp} />
    </>
  );
}
export default CartItems;
