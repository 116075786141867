import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { processPaymentThreedsApi } from "../../../services/PaymentService";
import CheckoutForm from "./CheckoutForm";
import "./StripePayment.css";
import { DependencyList, EffectCallback } from "react";

import { stripe_key } from "../../../constants/Utils";
import { stripeKey } from "../../../services/URl/Url";
import { useSelector } from "react-redux";
import { base_url, getOtpLoginApi } from "../../../services/Landingservice";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

export default function StripePayment({
  payAmount,
  cartValue,
  globalCheckoutDetails,
  isWallet,
  handleClose,
  timeSlotFlag,
  cb,
  deps,
}) {
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const [responseStatus, setResponseStatus] = useState();
  // const [dataFetchedRef, setDataFetchedRef] = useState(false);
  const dataFetchedRef = useRef(false);

  const paymentAmountMobile = useSelector(
    (state) => state.counter.payAmounMobile
  );
 
  sessionStorage.setItem("mobile", JSON.stringify(paymentAmountMobile));

  const getLoginOtpApi = async () => {
    let postLoginOtpObj = {
      contact: "98989898",
      app_id: "2",
    };
    try {
      let baseResponse = await getOtpLoginApi(postLoginOtpObj);
    } catch (e) {}
  };
  const processPaymentThreedsApiValues = async () => {
    let postPaymentDetailsApiObject = {
      total_amount: payAmount,
    };

    try {
      let walletDetailsApiResponse = await processPaymentThreedsApi(
        postPaymentDetailsApiObject
      );

      setClientSecret(walletDetailsApiResponse.data.data.clientSecret);
      sessionStorage.setItem(
        "clientSecret",
        walletDetailsApiResponse.data.data.clientSecret
      );
      setPublishableKey(walletDetailsApiResponse.data.data.publishableKey);
      setResponseStatus(walletDetailsApiResponse?.data?.status);
    } catch (e) {}
  };

  const stripePromise = loadStripe(publishableKey);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
 

  const [timesRun, setTimesRun] = useState(0);
  const counter = useRef(0);
  const effectCalled = useRef(false);
  useEffect(() => {
    if (effectCalled.current) {

      return;
    } else {
      counter.current += 1;

      setTimesRun(counter.current);
      processPaymentThreedsApiValues();
     

      effectCalled.current = true;
      
    }
  }, []);

  return (
    <div className="App mt-5 mb-3">
      {/* <button onClick={handle}>ok</button> */}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {responseStatus === 200 ? (
            <CheckoutForm
              cartValue={cartValue}
              globalCheckoutDetails={globalCheckoutDetails}
              isWallet={isWallet}
              payAmount={payAmount}
              handleClose={handleClose}
              // timeSlotFlag={timeSlotFlag}
            />
          ) : null}
        </Elements>
      )}
    </div>
  );
}
