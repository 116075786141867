import { React, useEffect, useState } from "react";
import "./Terms.css";
import { TermsAndConditionApi } from "../../../services/ProfilePageServices";
function Terms() {
  const [termsData, setTermsData] = useState([]);

  useEffect(() => {
    const termsConditionValuesApi = async () => {
      let postTermsConditionObject = {
        page_key: "termsandconditions",
        app_id: "2",
      };

      try {
        let TermsAndConditionResponse = await TermsAndConditionApi(
          postTermsConditionObject
        );
        setTermsData(TermsAndConditionResponse.data.data);
      } catch (e) {}
    };
    termsConditionValuesApi();
  }, []);

  return (
    <>
      <div>
        <div className="title-container">
          <h2>Terms and Conditions</h2>
        </div>
        <div
          className="term-condition"
          dangerouslySetInnerHTML={{ __html: termsData?.page_value }}
        ></div>
      </div>
    </>
  );
}

export default Terms;
