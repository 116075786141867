import { React, useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import OTPInput from "otp-input-react";
import * as yup from "yup";
import { useFormik } from "formik";
import swal from "sweetalert";
import "./Login.css";
import { getUserType } from "../../../../constants/Utils";
import { NavLink } from "react-router-dom";
import {
  getOtpLoginApi,
  LoginVerifyContinueApi,
  forgotApi,
  getCountryCodeApi,
} from "../../../../services/Landingservice";

import { useNavigate } from "react-router-dom";

import MuiAlert from "@material-ui/lab/Alert";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Login({
  getLoginResponse,
  handleCloseFormsPopUp,
  handleOpenLocation,
  setForgotPasswordClicked,
  forgotPasswordClicked

}) {
  const [timerCount, setTimeCount] = useState(null);
  const [OTP, setOTP] = useState("");
  const [showError, setShowError] = useState("");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isGetOtp, setIsGetOtp] = useState(false);
  const [inputType, setInputType] = useState("email");
  const [isMaxlengthApplied, setIsMaxlengthApplied] = useState(true);
  const [togglePass, setTogglePass] = useState(false);
  const [countryCode,setCountryCode]=useState([]);

  // const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  let loginBtnRef = useRef(null);
  const navigate = useNavigate();
  
 

  const [alertMessage, setAlertMessage] = useState("");


  const [alertType, setAlertType] = useState("");

  const handleForgotPasswordClick = () => {
    
    setInputType("email")
    setForgotPasswordClicked(true);
    setAlertMessage('');
    setAlertType('');
  };   
  useEffect(() => {
    if (isSubmitClicked && OTP && OTP.length < 4) {
      setShowError("Please Enter a valid Otp!");
    } else {
      setShowError();
    }

    if (OTP.length === 4) {
      loginBtnRef.current.focus();
    }
  }, [OTP]);

  let timerOn = true;

  function timer(remaining) {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    let TimerCounter = m + ":" + s;
    remaining -= 1;

    setTimeCount(TimerCounter);

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    }

    if (!timerOn) {
      // Do validate stuff here
      return;
    }
  }




  const handleSubmiter = () => {
    setIsSubmitClicked(true);
    if (OTP.length < 4) {
      setShowError("Please Enter a valid Otp!");
    } else {
      setShowError();
      getLoginVerifyApi();
    }
  };

// get country Code
const getCountryCode=async()=>{
  try {
    if(countryCode.length===0){
      let getCountryCodeResponse = await getCountryCodeApi();
      if(getCountryCodeResponse.data.status===200){
        setCountryCode(getCountryCodeResponse.data.data);
      }
    } 
  } catch (error) {
    console.log(error);
  }
}

  //SignUp Module GetOtp API :-
  const getLoginOtpApi = async () => {
    let postLoginOtpObj = {
      contact: formik.values.getotpNumber,
      app_id: "2"
    };

    if (inputType === 'email') {
      postLoginOtpObj.password = formik.values.password;
    }

    console.log("postLoginOtpObj",postLoginOtpObj);
    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {
        let loginApiResponse = await getOtpLoginApi(postLoginOtpObj);
        if (loginApiResponse.data.status === 201 && loginApiResponse.data.message ==="OTP verification is pending, click ok to verify"
        ) {
          swal({
            title: "Error!",
            text: loginApiResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,

            icon: "error",
            className: "popuptetx",
          });
        }
        else if (loginApiResponse.data.status === 200) {
          setIsGetOtp(true);
          timer(15);
          if (
            loginApiResponse.data.status === 200 &&
            loginApiResponse.data.data?.is_otp_verified == "1"
          ) {
            swal({
              title: "Success!",
              text: loginApiResponse.data.message,
              type: "success",
              timer: 2500,
              button: false,
              confirmButtonText: "OK",
              className: "popuptetx",
              confirmButtonColor: "#8CD4F5",
              icon: "success",
            });
          

          }
        } else {
          swal({
            title: "Error!",
            text: loginApiResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,

            icon: "error",
            className: "popuptetx",
          });
        }
      }
    } catch (e) {}
  };

//SignUp Module without Otp API :-
  const LoginApi = async () => {
    let postLoginOtpObj = {
      email: formik.values.getotpNumber,
      app_id: "2",
      password : formik.values.password
    };



    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {
        let loginApiResponse = await LoginVerifyContinueApi(postLoginOtpObj);
        // if (loginApiResponse.data.status === 200) {
        //   // setIsGetOtp(true);
        //   timer(15);
          

        // } 
        if (
          loginApiResponse.data.status === 200 
          // &&
          // loginApiResponse.data.data?.is_otp_verified == "1"
        ) {
          // console.log(" loginApiResponse.data.status === 200");
          // swal({
          //   title: "Success!",
          //   text: loginApiResponse.data.message,
          //   type: "success",
          //   timer: 2500,
          //   button: false,
          //   confirmButtonText: "OK",
          //   className: "popuptetx",
          //   confirmButtonColor: "#8CD4F5",
          //   icon: "success",
          // });

          setAlertType("success")
          setAlertMessage(loginApiResponse.data.message)
          navigate("/home")
          window.location.reload();
        }
        else {
          // swal({
          //   title: "Error!",
          //   text: loginApiResponse.data.message,
          //   type: "Error",
          //   timer: 2500,
          //   button: false,

          //   icon: "error",
          //   className: "popuptetx",
          // });
          setAlertType("error")
          setAlertMessage(loginApiResponse.data.message)
        }
      }
    } catch (e) {}
  };

  //Login Module Login Complete API :-
  const getLoginVerifyApi = async () => {
    let getUser = "";
    if (sessionStorage.getItem("otpResponse")) {
      getUser = JSON.parse(sessionStorage.getItem("otpResponse"))?.id;
    }
    let postLoginVerifyObject = {
      contact: formik.values.getotpNumber,
      code: OTP,
      device_id: "",
      device_type: "",
      device_token: "",
      app_id: "2",
      guest_userId: getUser ? getUser : "",
    };

    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {
        let loginApiVerifyResponse = await LoginVerifyContinueApi(
          postLoginVerifyObject
        );
        let RestUrl = sessionStorage.getItem("identifier_key")
          ? sessionStorage.getItem("identifier_key")
          : null;

        if (
          window.location.href.includes("/payment") ||
          window.location.href.includes("/home") ||
          window.location.href.includes(`/restaurants/${RestUrl}`)
        ) {
          window.location.reload(true);
        }

        if (loginApiVerifyResponse.data.status === 200) {
          handleCloseFormsPopUp();
          getLoginResponse(loginApiVerifyResponse);
          if (getUserType() === 2) {
            setTimeout(() => {
              handleOpenLocation(true);
            }, 1500);
          }
          navigate("/home")
        } else {
          swal({
            title: "Error!",
            text: loginApiVerifyResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,
            icon: "error",
            className: "popuptetx",
          });
        }
      }
    } catch (e) {}
  };

  // const formik = useFormik({
  //   initialValues: {
  //     getotpNumber: "",
  //   },
  //   validationSchema: yup.object({
  //     getotpNumber: yup
  //       .string()
  //       .matches(/^[0-9\b]+$/, "Please enter digits only")
  //       .min(8, "Please enter valid mobile number!")
  //       .required("Enter your mobile number!"),
  //   }),
  // });

  const formik = useFormik({
    initialValues: {
      getotpNumber: "",
      password: "",
      getForgetNumber:"",
      getForgetemail:"",
    },
    validationSchema: yup.object({
      getotpNumber: yup
        .string()
        .test(
          'phoneOrEmail',
          // 'Please enter a valid phone number or email address',
          'Please enter a valid email address',

          value => {
            const isValidPhoneNumber = /^[0-9\b]+$/.test(value) && value.length === 8;
            const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
            return isValidPhoneNumber || isValidEmail;
          }
        )
        .required("Please enter your email address!"),
      // password: yup.string().when('getotpNumber', {
      //   is: value => value && value.includes('@'),
      //   then: yup.string().required('Please enter your password'),
      // }),
      password: yup
        .string()
        .test(
          'password',
          // 'Please enter a valid phone number or email address',
          'Please enter password',

          value => {
            const pass = value&&value.length !== 0;
            return pass;
          }
        )
        .required("Please enter your email address!")
    }),
  });


  
  
  

  // const handleKeyDown = (event) => {
  //   const inputValue = event.target.value;
  //   const key = event.key;
  
  //   console.log("input key",inputValue.substring(8).includes('@'));
  //   console.log("input key",key);


  //   if (inputValue.length === 8 && inputType === 'tel' && key !== 'Backspace' && inputValue.substring(8).includes('@')) {
  //     console.log("input key",key);
  //     setInputType('email'); 
  //   }
  //   if (inputValue.length > 8 && inputType === 'tel' && key !== 'Backspace'  && inputValue.substring(8).includes('@')) {
     
  //     setInputType('email'); 
  //   }
  // };


  // const formik = useFormik({
  //   initialValues: {
  //     getotpNumber: "",
  //     password: "", 
  //   },
  //   validationSchema: yup.object({
  //     getotpNumber: yup
  //       .string()
  //       .when('password', {
  //         is: (password) => !!password,
  //         then: yup.string().email('Invalid email address'),
  //         otherwise: yup.string().test(
  //           'phoneOrEmail',
  //           'Please enter a valid phone number',
  //           value => /^[0-9\b]+$/.test(value) && value.length === 8
  //         ),
  //       })
  //       .required("Enter your mobile number or email address!"),
  //     password: yup.string(),
  //   }),
  // });
  
  
  
  
  
  
  

  // useEffect(() => {
  //   // Check if the current input value looks like an email
  //   setInputType('email');
  //   // if (formik.values.getotpNumber.includes('@')) {
  //   //   setInputType('email');
  //   // } 
  //   // // else {
  //   //   setInputType('tel');
  //   // }
  // // }, [formik.values.getotpNumber]);
  // },[]);

  useEffect(() => {
    setInputType('email');
    getCountryCode();
  }, []);
  

  const resetPaswordMail= async ()=>{

    if (formik.values.getForgetNumber&&formik.values.countryCode) {
      // If not provided, set an error message
      setAlertType('error');
      setAlertMessage('Please select a country.');
      return;
    }

    if (!formik.values.getForgetNumber&&!formik.values.getForgetemail) {
      // If not provided, set an error message
      setAlertType('error');
      setAlertMessage('Please provide your email or phone number.');
      return;
    }
   
    let postforgotObj = {
      email_mobile: formik.values.getForgetNumber||formik.values.getForgetemail,
      app_id: "2",
    };
    console.log("postforgotObj",postforgotObj);

    try {
      
      let forgotApiVerifyResponse = await forgotApi(
        postforgotObj
      );
     
      console.log("loginApiVerifyResponse",forgotApiVerifyResponse);
      if (forgotApiVerifyResponse.data.status === 200) {
        // handleCloseFormsPopUp();
  
        // swal({
        //   title: "Success!",
        //   text: forgotApiVerifyResponse.data.message,
        //   type: "success",
        //   timer: 2500,
        //   button: false,
        //   confirmButtonText: "OK",
        //   className: "popuptetx",
        //   confirmButtonColor: "#8CD4F5",
        //   icon: "success",
        // });
       setAlertType('success');
       setAlertMessage(forgotApiVerifyResponse.data.message);
      //  setForgotPasswordClicked(false);
      }else{
        
        // swal({
        //   title: "Error!",
        //   text: forgotApiVerifyResponse.data.message,
        //   type: "Error",
        //   timer: 2500,
        //   button: false,
        //   icon: "error",
        //   className: "popuptetx",
        // });

        setAlertType('error');
        setAlertMessage(forgotApiVerifyResponse.data.message);
      }
      
    } catch (error) {
      
    }

  }

  // const resetPaswordPhone= ()=>{
   
  //   let postforgotObj = {
  //     emailornumber: formik.values.getotpNumber,
  //     app_id: "2",
  //   };
  //   console.log("postforgotObj",postforgotObj);
  //   console.log("formik.values",formik.values);

  //     }

      // const handleInputChange = (event) => {
      //   const inputValue = event.target.value;
    
      //   console.log("input value",inputValue);
      
      //   if (inputValue.length >= 8 && inputValue.substring(9).includes('@')) {
      //     setInputType('email');
      //   } else {
      //     setInputType('tel');
      //   }
      
      //   formik.handleChange(event);
      // };

      const handleInputChange = (event) => {
        const inputValue = event.target.value.trim(); // Remove leading/trailing whitespace
      
        console.log("input value", inputValue);
      
        // if (inputValue.includes('@')) {
        //   setInputType('email');
        // }
        //  else {
        //   setInputType('tel');
        // }
        formik.handleChange(event);
      };
      

  // useEffect(() => {
  //   // Check if the current input value looks like an email
  //   if (formik.values.getotpNumber && formik.values.getotpNumber.includes('@')) {
  //     setInputType('email');
  //     setIsMaxlengthApplied(false);
  //   } else {
  //     setInputType('tel');
  //     setIsMaxlengthApplied(true);
  //   }
  // }, [formik.values.getotpNumber]);
  
useEffect(()=>{
  setAlertMessage('');
  setAlertType('');
},[forgotPasswordClicked])

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="loginPopUpLanding">
          <div className="change_text">
            <span>Sign up</span> <b>or log in to your account</b>
          </div>
          <p className="change_text desc-login ">
            <small className="line-two">
              {/* Mobile Numbers of users by sending OTP verification code during
              registaration,login and contact form submissions. */}
              Welcome! Forgotten your password? No worries! Simply click on "Forgot Password" below the login form to reset it. We're here to assist you in regaining access to your account smoothly
            </small>
            
          </p>

          <p className="change_text desc-login">
          {/* <small className="line-two">{alertMessage}</small> */}
          {/* <MuiAlert value={alertMessage}/> */}
         
          <MuiAlert severity={alertType}>{alertMessage}</MuiAlert>
          {/* {forgotPasswordClicked && (
            <MuiAlert severity={alertType}>{alertMessage}</MuiAlert>
          )} */}


      </p>
         
      {!forgotPasswordClicked && (
      <>
        <div>
            <div className="LocationInput input-group prefix">
              {/* {inputType === 'tel' ? (
                <span className="input-group-addon">+65</span>
              ) : null} */}
              <input
                type={inputType}
               
                className="getMobNumber form-control"
                id="getotpNumber"
               
                name="getotpNumber"
                placeholder={"Email Address"}

                // placeholder={inputType === 'tel' ? "Phone Number" : "Email"}
                aria-label={inputType === 'tel' ? "Phone Number" : "Email"}
                aria-describedby="basic-addon1"
             
                isInvalid={formik.touched.getotpNumber && formik.errors.getotpNumber}
                {...formik.getFieldProps("getotpNumber")}

             
              />
            </div>
            {formik.touched.getotpNumber && formik.errors.getotpNumber && (
              <div className="error-style">{formik.errors.getotpNumber}</div>
            )}


          </div>
          <div style={{marginBottom:"16px"}}></div>
          {inputType === 'email' && (
            <div>
               <div className="LocationInput input-group prefix">
              <input
                type={togglePass?"text":"password"}
                className="getMobNumber form-control"
                id="password"
           
                name="password"
                // placeholder="password"
                placeholder={"Password"}

                aria-label="Password"
                aria-describedby="basic-addon1"
                isInvalid={formik.touched.password && formik.errors.password}

                {...formik.getFieldProps("password")}
              /> 
              <div className="toggle-pass" onClick={()=>{setTogglePass(!togglePass)}}>
                {togglePass?<FaRegEye />:<FaRegEyeSlash />}
              </div>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="error-style">{formik.errors.password}</div>
              )}
            </div>
          )}
          
          <div className="get-otp-btn">
            {/* <Button
              className="getNumber get_otp_button_login"
              type="submit"
              onClick={getLoginOtpApi}
              disabled={
                timerCount !== "00:00" && timerCount !== null ? true : false
              }
            >
              {timerCount === null ? "Get OTP" : "Resend OTP"}
            </Button> */}

            {inputType === 'email' ? (
              <Button
                className="getNumber get_otp_button_login"
                type="submit"
                onClick={LoginApi}
              >
                Sign In
              </Button>
            ) : (
              <Button
                className="getNumber get_otp_button_login"
                type="submit"
                onClick={getLoginOtpApi}
                disabled={timerCount !== "00:00" && timerCount !== null}
              >
                {timerCount === null ? "Get OTP" : "Resend OTP"}
              </Button>
            )}
<NavLink href="#" style={{textAlign:"center", position:"absolute", margin:"40px 64px -42px -194px"}} onClick={handleForgotPasswordClick}>Forgot password?</NavLink>
            
            {isGetOtp ? (
              <>
                <div className="change_text desc-login">
                  <p className="otp loginOtp">Please enter the OTP received</p>
                </div>
                <div className="change_text  desc-login otpformat">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    OTPLength={4}
                    otpType="number"
                    className="otp_inputs_Login otpLoginField"
                    disabled={false}
                  />
                  <div className="" style={{ color: "red", fontSize: "1vw" }}>
                    {showError}
                  </div>
                  {timerCount !== null && timerCount !== "00:00" ? (
                    <div>
                      <small className="TimeLeftText">
                        Time left {timerCount}
                      </small>{" "}
                      <span id="timer"></span>
                    </div>
                  ) : null}
                </div>
                <div className="get-otp-btn">
                  <Button
                    ref={loginBtnRef}
                    className="verify mt-3 ms-4 mb-5"
                    type="submit"
                    onClick={() => {
                      handleSubmiter();
                    }}
                  >
                    Verify
                  </Button>
                </div>
              </>
            ) : null}
          </div>
      </>)}


      {forgotPasswordClicked && (
      <>
<div>
            <div className="LocationInput input-group prefix">
              <div className="cntrynumbr">
              <select  style={{appearance:"none"}} value={formik.touched.countryCode} name="countryCode" onChange={handleInputChange}>
                  {countryCode&&countryCode.length>0&&countryCode.map((item,idx)=>{
                   return <option value={item.id} key={idx}>{item.phonecode}</option>
                  })}
                </select>
             
              <input
                type={"tel"}
                className="getMobNumber form-control"
                id="getForgetNumber"
                // maxLength={inputType === 'tel' ? "8" : ""}
                
                name="getForgetNumber"
                placeholder="Phone Number"
                aria-label={"Phone Number"}
                aria-describedby="basic-addon1"
                // {...formik.getFieldProps("getotpNumber")}
                value={formik.values.getForgetNumber}
                onBlur={formik.handleBlur} 
                // onChange={handleInputChange}
                onChange={(e)=>{if((/^\d+$/.test(e.target.value)||e.target.value==="")&&e.target.value.length<11)handleInputChange(e)}}
                // onKeyDown={handleKeyDown}
              />
              </div>
                {/* <span className="input-group-addon">+65</span> */}
              
            </div>
            {/* {formik.touched.getotpNumber && formik.errors.getotpNumber && ( */}
              {/* //<div className="error-style">{formik.errors.getotpNumber}</div> */}
            {/* //)} */}


            {/* {formik.touched.getotpNumber && formik.errors.getotpNumber && formik.values.getotpNumber && (
             <div className="error-style">{formik.errors.getotpNumber}</div>
              )} */}

{formik.touched.getForgetNumber && formik.errors.getForgetNumber && formik.values.getForgetNumber === '' && (
  <div className="error-style">{formik.errors.getForgetNumber}</div>
)}


          </div>
          <div className="or-div">OR</div>
        <div>
            <div className="LocationInput input-group prefix">
              {/* {inputType === 'tel' ? (
                <span className="input-group-addon">+65</span>
              ) : null} */}
              <input
                type={inputType}
                className="getMobNumber form-control"
                id="getForgetemail"
                // maxLength={inputType === 'tel' ? "8" : ""}
                
                name="getForgetemail"
                placeholder=" Email address"
                aria-label={"Phone Number"}
                aria-describedby="basic-addon1"
                // {...formik.getFieldProps("getotpNumber")}
                value={formik.values.getForgetemail}
                onBlur={formik.handleBlur} 
                onChange={handleInputChange}
                // onKeyDown={handleKeyDown}
              />
            </div>
            {/* {formik.touched.getotpNumber && formik.errors.getotpNumber && ( */}
              {/* //<div className="error-style">{formik.errors.getotpNumber}</div> */}
            {/* //)} */}


            {/* {formik.touched.getotpNumber && formik.errors.getotpNumber && formik.values.getotpNumber && (
             <div className="error-style">{formik.errors.getotpNumber}</div>
              )} */}

{formik.touched.getForgetemail && formik.errors.getForgetemail && formik.values.getForgetemail === '' && (
  <div className="error-style">{formik.errors.getForgetemail}</div>
)}


          </div>
          {/* <div style={{marginBottom:"16px"}}></div> */}

          
          <div className="get-otp-btn">
            {/* <Button
              className="getNumber get_otp_button_login"
              type="submit"
              onClick={getLoginOtpApi}
              disabled={
                timerCount !== "00:00" && timerCount !== null ? true : false
              }
            >
              {timerCount === null ? "Get OTP" : "Resend OTP"}
            </Button> */}

            {inputType === 'email' ? (
              <Button
                className="getNumber get_otp_button_login"
                type="submit"
                onClick={resetPaswordMail}
              >
                Submit
              </Button>
            ) : (
              <Button
                className="getNumber get_otp_button_login"
                type="submit"
                onClick={resetPaswordMail}
                // disabled={timerCount !== "00:00" && timerCount !== null}
              >
                {timerCount === null ? "Submit" : "Resend OTP"}
              </Button>
            )}
{/* <NavLink href="#" style={{textAlign:"center", position:"absolute", margin:"40px 64px -42px -194px"}} onClick={handleForgotPasswordClick}>Forgot password?</NavLink> */}
            

          </div>
      </>)}

        </Row>
      </Form>
    </>
  );
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getLoginResponse: (data) => dispatch(getLoginResponse(data)),
//   };
// };
// connect(null, mapDispatchToProps)
export default Login;
