import { React, useState, useEffect } from "react";
import { Button, Collapse, Fade } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { RiShoppingBag3Line, RiNotification3Line } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import "./DesiEatsImage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { listAllNotificationApi } from "../../../services/Landingservice";
import NotificationPopup from "../PopUp/NotificationPopup";
import SearchPopup from "../PopUp/SearchPopUp";
import CartPopup from "../PopUp/CartPopup";
import { sessionLocationData } from "../../../constants/Utils";
import ThreeDotsImage from "../../../Asserts/landingpage/Circled Menu.png";
import Location from "../Location/Location";
import { cartValueApi } from "../../../services/CartCheckOutServices";
function ToggleMenuButton({ itemIdState, foodIdState }) {
  //Get values for hear about us API
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [cartApi, setCartApi] = useState();
  const cartValueValuesApi = async (data) => {
    try {
      let cartValueResponse = await cartValueApi();
      setCartApi(cartValueResponse?.data?.data?.items);
      sessionStorage.setItem(
        "restaurantData",
        cartValueResponse?.data?.data?.rest_id
      );
    } catch (e) {}
  };

  const showLocationPopup = () => setShowLocation(true);
  const LocationPopUp = (value) => {
    setShowLocation(value);
  };

  let navigate = useNavigate();

  const getNotificationsApi = async () => {
    let getNotificationsObject = {
      page: "1",
      limit: "50",
    };
    try {
      let notificationsApiApiResponse = await listAllNotificationApi(
        getNotificationsObject
      );
      setNotifications(notificationsApiApiResponse.data.data);
    } catch (e) {}
  };
  let lengthOfAddress = Object?.keys(
    sessionLocationData() ? sessionLocationData() : "null"
  )?.length;
  //toggle button open close
  const navigateToHome = () => {
    if (
      JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&
      Object.keys(JSON.parse(sessionStorage.getItem("userLocation"))).length ==
        0
    ) {
      showLocationPopup();
    } else if (
      sessionLocationData() === undefined ||
      sessionLocationData()?.pin_address === "" ||
      sessionLocationData() === null ||
      lengthOfAddress === 0 ||
      sessionLocationData() === ""
    ) {
    } else {
      navigate("/home");
    }
  };

  //SearchPopup close

  const [toggleButton, setToggleButton] = useState();
  const handleSearch = (value) => {
    if (
      JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&
      Object.keys(JSON.parse(sessionStorage.getItem("userLocation"))).length ==
        0
    ) {
      showLocationPopup();
      setToggleButton(value);
    } else {
      // setShowSearch(true);
      navigate("/search", {
        state: {
          business_type: itemIdState != null ? itemIdState : 0,
          color: "green",
        },
      });
    }
  };
  const closeSearchPopUp = (value) => {
    setShowSearch(value);
  };
  const [countCart, setCountCart] = useState();
  const countCartData = (value) => {
    setCountCart(value);
  };
  //NotificationPopup close
  let sessLoc = sessionStorage.getItem("userLocation")
    ? Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))?.length !=
      0
    : null;
  let LocationForms = sessionStorage.getItem("userLocation")
    ? Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))?.length
    : 0;

  const handleNotification = () => {
    if (
      JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&
      Object.keys(JSON.parse(sessionStorage.getItem("userLocation")))?.length ==
        0
    ) {
      showLocationPopup();
    } else {
      setShowNotification(true);
      if (sessLoc) {
        getNotificationsApi();
      }
    }
  };
  const closeNotificationPopUp = (value) => {
    setShowNotification(value);
  };

  //CartPopup close
  const handleCart = () => {
    if (
      JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&
      Object.keys(JSON.parse(sessionStorage.getItem("userLocation"))).length ==
        0
    ) {
      showLocationPopup();
    } else {
      setShowCart(true);
      // if (sessLoc) {
      //   cartValueValuesApi();
      // }
    }
  };
  useEffect(() => {
    if (
      sessionStorage.getItem("userLocation") &&
      location.pathname != `/restaurants/${RestUrl}`
    ) {
      cartValueValuesApi();
      // setTimeout(()=>{
      //   checkoutPostDetailsValuesApi();
      // },500)
    }
  }, [sessionStorage.getItem("userLocation")]);

  const closeCartPopUp = (value) => {
    setShowCart(value);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // useEffect(() => {
  //   if (window.innerWidth > 700) {
  //     setScreenWidth(true);
  //   }
  // }, []);
  const location = useLocation();
  let RestUrl = sessionStorage.getItem("identifier_key")
    ? sessionStorage.getItem("identifier_key")
    : null;
  return (
    <>
      <div className="toggle-container">
        <div className="button-container">
          {screenWidth > 800 ? (
            <div id="example-collapse-text" className="button-toggle ">
              {location.pathname != "/" ? (
                <Button className="menuButton" onClick={navigateToHome}>
                  <AiOutlineHome />
                </Button>
              ) : null}
              <Button
                disabled={
                  window?.location?.href?.includes("/search") ||
                  LocationForms == 0
                    ? true
                    : false
                }
                className="menuButton"
                onClick={handleSearch}
              >
                <FiSearch />
              </Button>
              <Button
                className="menuButton"
                onClick={handleNotification}
                disabled={LocationForms == 0}
              >
                <RiNotification3Line />
              </Button>
              {location.pathname !== `/restaurants/${RestUrl}` ? (
                <>
                  <Button
                    className="menuButton cartApiBtn"
                    onClick={handleCart}
                    disabled={LocationForms == 0}
                  >
                    <RiShoppingBag3Line />
                    {cartApi > 0 ? (
                      <span className="badge123">{cartApi}</span>
                    ) : null}
                  </Button>
                </>
              ) : null}
            </div>
          ) : null}
          {screenWidth <= 800 ? (
            <>
              {/* <Collapse in={open} dimension="width">
                <div id="example-collapse-text" className="button-toggle ">
                  <Button className="menuButton" onClick={navigateToHome}>
                    <AiOutlineHome />
                  </Button>
                  <Button
                    disabled={
                      window?.location?.href?.includes("/search") ? true : false
                    }
                    className="menuButton"
                    onClick={handleSearch}
                  >
                    <FiSearch />
                  </Button>
                  <Button className="menuButton" onClick={handleNotification}>
                    <RiNotification3Line />
                  </Button>
                  <Button className="menuButton" onClick={handleCart}>
                    <RiShoppingBag3Line />
                  </Button>
                </div>
              </Collapse> */}

              <Button
                className="menu"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              >
                <img
                  src={ThreeDotsImage}
                  alt=""
                  className="ThreeDotsIcon"
                ></img>
              </Button>
              <Collapse in={open} dimension="width">
                <div id="example-collapse-text" className="button-toggle ">
                  {location.pathname != "/" ? (
                    <Button className="menuButton" onClick={navigateToHome}>
                      <AiOutlineHome />
                    </Button>
                  ) : null}
                  <Button
                    disabled={
                      window?.location?.href?.includes("/search") ||
                      LocationForms == 0
                    }
                    className="menuButton"
                    onClick={handleSearch}
                  >
                    <FiSearch />
                  </Button>
                  <Button
                    className="menuButton"
                    onClick={handleNotification}
                    disabled={LocationForms == 0}
                  >
                    <RiNotification3Line />
                  </Button>
                  {location.pathname !== `/restaurants/${RestUrl}` ? (
                    <>
                      <Button
                        className="menuButton cartApiBtn"
                        onClick={handleCart}
                        disabled={LocationForms == 0}
                      >
                        <RiShoppingBag3Line />
                        {cartApi > 0 ? (
                          <span className="badge123">{cartApi}</span>
                        ) : null}
                      </Button>
                    </>
                  ) : null}
                </div>
              </Collapse>
            </>
          ) : null}
        </div>
      </div>

      <SearchPopup
        show={showSearch}
        closePopUp={closeSearchPopUp}
        LocationPopUp={LocationPopUp}
        itemIdState={itemIdState}
        foodIdState={foodIdState}
        beverageTermsData={handleSearch}
      />
      <NotificationPopup
        show={showNotification}
        closePopUp={closeNotificationPopUp}
        notifications={notifications}
      />

      {showCart ? (
        <CartPopup
          show={showCart}
          closePopUp={closeCartPopUp}
          cartValueValuesApi={cartValueValuesApi}
          cartApi={cartApi}
        />
      ) : null}

      <Location showLocation={showLocation} LocationPopUp={LocationPopUp} />
    </>
  );
}

export default ToggleMenuButton;
