import React, { useEffect, useState } from "react";
import { Card, Form, FormControl, ModalTitle } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import PaymentPopup from "./PaymentPopup";
import * as yup from "yup";

import { useFormik } from "formik";
function AddMoneyPopup({ closePopUp, show, refreshData }) {
  const formik = useFormik({
    initialValues: {
      addWallet: "",
    },
    validationSchema: yup.object({
      addWallet: yup
        .string()
        .matches(/^[0-9\b]+$/, "Please Enter Digits Only")
        .min(1, "Mininum 1 characters")
        .required("Enter Walllet Amount!"),
    }),
  });

  const handleClose = () => {
    closePopUp(false);
    setAmount("");
  };

  const addMoneyClose = () => {
    closePopUp(false);
    setAmount("");
    refreshData();
  };

  const [amount, setAmount] = useState("");
  const [shows, setShow] = useState(false);
  const handleShow = () => {
    if (
      Object.keys(formik.errors).length === 0 &&
      Object.keys(formik.touched).length !== 0
    ) {
      setShow(true);
    }
  };

  const closePopUps = (value) => {
    setShow(value);
  };

  const handleOnChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <ModalTitle>Add Money</ModalTitle>
        </Modal.Header>

        <Modal.Body>
          {/* <Row>
            <Col lg="4" />
            <Col lg="6">
              <b>Add Money</b>
            </Col>
          </Row> */}
          <Row>
            <Form onSubmit={formik.handleSubmit}>
              <Col lg="12" className="mb-4">
                <b>Amount</b>
                <br />
                <FormControl
                  className="AddMoneyInput mt-2"
                  placeholder="Enter Amount"
                  onChange={handleOnChange}
                  {...formik.getFieldProps("addWallet")}
                ></FormControl>
                {formik.touched.addWallet && formik.errors.addWallet && (
                  <div className="error-style">{formik.errors.addWallet}</div>
                )}
              </Col>
            </Form>
          </Row>
          <Row>
            <Col lg="3" />
            <Col lg="5">
              <Button className="theme-btn" onClick={handleShow}>
                Proceed To Pay
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <PaymentPopup
        show={shows}
        closePopUp={closePopUps}
        amount={formik.values.addWallet}
        refreshData={addMoneyClose}
      />
    </>
  );
}

export default AddMoneyPopup;
