import { React, useState, useEffect, useRef } from "react";
import * as yup from "yup";
import OTPInput from "otp-input-react";
import { useFormik } from "formik";
import { Col, Row, Button, Form } from "react-bootstrap";
import { IoRefreshOutline } from "react-icons/io5";
import Location from "../../Location/Location";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  getOtpSignUpApi,
  signupVerifyContinueApi,
  getHearAboutUsValue,
  getCountryCodeApi,
} from "../../../../services/Landingservice";
import "./SignUp.css";
import { handleEnter } from "../../../../constants/Utils";
import { handleUpArrowEvent } from "../../../../constants/Utils";

import MuiAlert from "@material-ui/lab/Alert";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Signup({
  getSignUpResponse,
  handleCloseFormsPopUp,
  handleOpenLocation,
}) {
  const [OTP, setOTP] = useState("");
  const [timerCount, setTimeCount] = useState(null);
  const [showLocation, setShowLocation] = useState(false);
  const [userList, setUserList] = useState([]);
  const [hearAboutInputText, setHearAboutInputText] = useState("");
  const [showError, setShowError] = useState("");
  const [hearAbout, sethearAbout] = useState("");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showInputError, setShowInputError] = useState("");
  const [isGetSignUpOtp, setIsGeSignUptOtp] = useState(false);
  const [togglePass, setTogglePass] = useState(false);
  const [toggleConfirmPass, setToggleConfirmPass] = useState(false);
  const [countryCode, setCountryCode] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [mobileCode,setMobileCode]=useState("")

  const [alertType, setAlertType] = useState("");

  let hearRef = useRef(null);

  let timerOn = true;

  function timer(remaining) {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    let TimerCounter = m + ":" + s;
    remaining -= 1;

    setTimeCount(TimerCounter);

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    }

    if (!timerOn) {
      // Do validate stuff here
      return;
    }
  }

  useEffect(() => {
    if (isSubmitClicked && OTP && OTP.length < 4) {
      setShowError("Please enter a valid otp!");
    } else {
      setShowError();
    }
    if (OTP.length === 4) {
      hearRef.current.focus();
    }
  }, [OTP]);

  //Get values for hear about us API

  useEffect(() => {
    const getHearAboutusApi = async () => {
      try {
        let hearAboutUsApiResponse = await getHearAboutUsValue();
        // setUserList()
        setUserList(hearAboutUsApiResponse.data.data);
      } catch (e) {}
    };
    getHearAboutusApi();
  }, []);

  const showLocationPopup = () => setShowLocation(true);

  const LocationPopUp = (value) => {
    setShowLocation(value);
  };

  const handleHearAboutValue = (event) => {
    setHearAboutInputText(event.target.value);
  };

  //SignUp Module GetOtp API :-
  const getSignUpOtpApi = async () => {
    let getUser = "";
    if (sessionStorage.getItem("otpResponse")) {
      getUser = JSON.parse(sessionStorage.getItem("otpResponse"))?.id;
    }
    let postObj = {
      fullname: formik.values.fullName,
      email: formik.values.email,
      mobile: formik.values.phoneNumber,
      password: formik.values.passWord,
      app_id: "2",
      guest_userId: getUser ? getUser : "",
    };

    console.log("post obj==", postObj);

    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0
      ) {
        let signUpApiResponse = await getOtpSignUpApi(postObj);
        if (signUpApiResponse.data.status === 200) {
          timer(15);
          // swal({
          //   title: "Success!",
          //   text: signUpApiResponse.data.message,
          //   type: "success",
          //   timer: 1500,
          //   button: false,
          //   confirmButtonText: "OK",
          //   className: "popuptetx",
          //   confirmButtonColor: "#8CD4F5",
          //   icon: "success",
          // });
          swal({
            title:
              "A verification link has been sent to your email. Please verify your email before login",
            // text: "Success",
            type: "success",
            timer: 3000,
            button: false,
            confirmButtonText: "OK",
            className: "popuptetx",
            confirmButtonColor: "#8CD4F5",
            icon: "success",
          });
          setIsGeSignUptOtp(true);
          setTimeout(() => {
            //document.getElementById("inputone").focus();
          }, 1501);
        } else {
          swal({
            title: "Error!",
            text: signUpApiResponse.data.message,
            type: "Error",
            timer: 2500,
            button: false,

            icon: "error",
            className: "popuptetx",
          });
        }
      }
    } catch (e) {}
  };

  //-----------------------------------------------------------
  const hearFunction = () => {
    if (hearAboutInputText === "others") {
    }
  };

  //Signup form validation--------------------------------

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      passWord: "",
      confirmPassword: "",
      rememberMe: false,
    },
    validationSchema: yup.object({
      fullName: yup
        .string()
        .matches(/^[a-zA-Z0-9 ]+$/, "No special charcters allowed!")
        // .min(8, "Mininum 8 characters")
        .required("Please enter your name!"),
      email: yup
        .string()
        .email("Please enter valid email address!")
        .required("Email field is required!"),
      phoneNumber: yup
        .string()
        .matches(/^[0-9\b]+$/, "Please enter digits only")
        .min(8, "Please enter valid mobile number!")
        .required("Enter your mobile number!"),
      // passWord: yup
      //   .string()
      //   // .matches(/^[0-9\b]+$/, "Please enter digits only")
      //   .min(8, "Password should be at least 8 characters")
      //   .matches(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      //   ).required("Enter your Password!"),
      passWord: yup.string().required("Enter your Password!"),
      confirmPassword: yup
        .string()
        .oneOf(
          [yup.ref("passWord"), null],
          "Password and Confirm Password  must match"
        ) // Ensure confirmPassword matches passWord
        .required("Please enter confirm your password"),
    }),
    onSubmit: (values) => {},
  });

  //SignUp Module SignUp Complete API :-
  //--------------------------------------
  const getSignUpVerifyApi = async () => {
    let getUser = "";

    if (sessionStorage.getItem("otpResponse")) {
      getUser = JSON.parse(sessionStorage.getItem("otpResponse"))?.id;
    }
if(mobileCode===""){
  setAlertMessage("Please choose the country code");
          setAlertType("error");
          return;
}
    let postObject = {
      fullname: formik.values.fullName,
      email: formik.values.email,
      mobile: formik.values.phoneNumber,
      device_id: "",
      device_type: "2",
      device_token: "",
      hear_about_us: hearAbout !== "others" ? "" : hearAboutInputText,
      hear_about_us_val: hearAbout,
      app_id: "2",
      // code: OTP,
      password: formik.values.passWord,
      user_type: "1",
      mobile_code:mobileCode,
      guest_userId: getUser ? getUser : "",
    };
console.log(postObject)
    try {
      if (
        Object.keys(formik.errors).length === 0 &&
        Object.keys(formik.touched).length !== 0 &&
        // Object.keys(OTP).length > 3 &&
        checked === true &&
        (hearAbout !== "Other" || hearAboutInputText)
        &&
        (mobileCode !== "" || mobileCode)
        //   checked === true &&
        //
      ) {
        // console.log("hearAboutInputText",hearAboutInputText);
        // console.log("postObject",postObject);

        let signUpApiResponse = await signupVerifyContinueApi(postObject);
        // console.log("signUpApiResponse",signUpApiResponse);

        if (signUpApiResponse.data.status === 200) {
          // swal({
          //   title: "Success!",
          //   text: signUpApiResponse.data.message,
          //   type: "success",
          //   timer: 3500,
          //   button: false,
          //   className: "popuptetx",
          //   icon: "success",
          // });
          formik.resetForm();

          setHearAboutInputText();
          sethearAbout();
          setMobileCode("")
          setAlertMessage(signUpApiResponse.data.message);
          setAlertType("success");
          setChecked(false);
          // handleCloseFormsPopUp();
          // showLocationPopup();

          // setTimeout(() => {
          //   handleOpenLocation(true);
          // }, 1500);
          getSignUpResponse(signUpApiResponse);
        } else if (signUpApiResponse.data.status === 201) {
          setAlertMessage(signUpApiResponse.data.message);
          setAlertType("info");
          // swal({
          //   title: "Error!",
          //   text: signUpApiResponse.data.message,
          //   type: "Error",
          //   timer: 2500,
          //   button: false,
          //   icon: "error",
          //   className: "popuptetx",
          // });
        } else {
          // swal({
          //   title: "Error!",
          //   text: signUpApiResponse.data.message,
          //   type: "Error",
          //   timer: 2500,
          //   button: false,
          //   icon: "error",
          //   className: "popuptetx",
          // });

          setAlertMessage(signUpApiResponse.data.message);
          setAlertType("error");
        }
      }
    } catch (e) {}
  };

  const handleSubmiter = () => {
    setIsSubmitClicked(true);
    // handleOpenLocation(true);
    if (
      hearAbout === "Other" &&
      (hearAboutInputText === "" || hearAboutInputText === "undefined")
    ) {
      setShowInputError("Please enter value");
      return;
    } else {
      setShowInputError();
      setShowError();
      getSignUpVerifyApi();
    }
    // if (OTP.length < 4) {
    //   setShowError("Please Enter Valid OTP");
    // } else {
    //   setShowError();
    //   getSignUpVerifyApi();

    // }
  };
  //HearAbout Us Console log values
  const handle = (e) => {
    sethearAbout(e.target.value);
  };
  useEffect(() => {}, [hearAbout]);
  //Terms and Condition select Box
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState("");

  const handleRememberMe = () => {
    setChecked(!checked);
  };
  const handleChecked = () => {
    if (checked === true) {
      setCheckedError();
    } else {
      setCheckedError("Please Accept Terms & Conditions");
    }
    if (!hearAbout) {
      setShowInputError("Please select how you heard about us.");
      return;
    } else {
      setShowInputError("");
    }
  };

  // get country Code
  const getCountryCode = async () => {
    try {
      if (countryCode.length === 0) {
        let getCountryCodeResponse = await getCountryCodeApi();
        if (getCountryCodeResponse.data.status === 200) {
          setCountryCode(getCountryCodeResponse.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);
  // {console.log(formik.getFieldProps("phoneNumber"),"Avijit")}
  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="SignUpForm">
        <Row>
          <div className="change_text singupLoginText mb-2">
            <b>Sign up or</b> <span>log in to your account</span>
          </div>
          <p className="change_text desc-login">
            {/* <small className="line-two">{alertMessage}</small> */}
            {/* <MuiAlert value={alertMessage}/> */}

            {/* <MuiAlert severity="success">Sample Success Message</MuiAlert> */}
            <MuiAlert severity={alertType}>{alertMessage}</MuiAlert>

            {/* {forgotPasswordClicked && (
            <MuiAlert severity={alertType}>{alertMessage}</MuiAlert>
          )} */}
          </p>
          <div className="signup-container">
            <div>
              <Form.Control
                // placeholder="Enter Your Name"
                placeholder={
                  formik.errors.fullName
                    ? formik.errors.fullName
                    : "Enter Your Name"
                }
                type="text"
                id="fullName"
                name="fullName"
                {...formik.getFieldProps("fullName")}
                className="InputBoxSignUp mb-2"
                onKeyDown={handleEnter}
                onKeyUp={handleUpArrowEvent}
                isInvalid={formik.touched.fullName && formik.errors.fullName}
              ></Form.Control>

              {/* {formik.touched.fullName && formik.errors.fullName && (
                <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
                  {formik.errors.fullName}
                </div>
              )} */}
              {/* <Form.Control.Feedback type="invalid">
          {formik.touched.fullName && formik.errors.fullName}
        </Form.Control.Feedback> */}
            </div>
            <div>
              <Form.Control
                id="email"
                name="email"
                // placeholder="Enter Your Email id"
                placeholder={
                  formik.errors.email
                    ? formik.errors.email
                    : "Enter Your Email id"
                }
                className="InputBoxSignUp mb-2"
                {...formik.getFieldProps("email")}
                onKeyDown={handleEnter}
                onKeyUp={handleUpArrowEvent}
                isInvalid={formik.touched.email && formik.errors.email}
              ></Form.Control>
              {/* {formik.touched.email && formik.errors.email && (
                <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
                  {formik.errors.email}
                </div>
              )} */}
            </div>
            <div>
              <div className="InputBoxSignUp">
                <div className="cntrynumbr mb-2">
                <select
                  style={{ appearance: "none" }}
                  value={mobileCode}
                  name="mobilecode"
                  className=""
                  onChange={(e)=>{setMobileCode(e.target.value)}}
                >
                  <option value={""}>
                         Select
                        </option>
                  {countryCode &&
                    countryCode.length > 0 &&
                    countryCode.map((item, idx) => {
                      return (
                        <option value={item.phonecode} key={idx}>
                          {item.phonecode}
                        </option>
                      );
                    })}
                </select>

                <Form.Control
                  type={"tel"}
                  // className="getMobNumber form-control"
                  className="form-control"
                  id="phoneNumber"
                  // maxLength={inputType === 'tel' ? "8" : ""}
                    value={formik.values.phoneNumber}
                  name="phoneNumber"
                  placeholder={
                    formik.errors.phoneNumber
                      ? formik.errors.phoneNumber
                      : "Enter Your mobile Number"
                  }
                  // value={""}
                  aria-label={"Phone Number"}
                  aria-describedby="basic-addon1"
                  // {...formik.getFieldProps("getotpNumber")}
                  // value={formik.values.getForgetNumber}
                  // onBlur={formik.handleBlur}
                  // onChange={handleInputChange}
                  // onKeyDown={handleKeyDown}
                  // onKeyDown={(e)=>{return false}}
                  // onChange={(e)=>{return}}
                  {...formik.getFieldProps("phoneNumber")}
                  onChange={(e)=>{if((/^\d+$/.test(e.target.value)||e.target.value==="")&&e.target.value.length<11)formik.setFieldValue("phoneNumber", e.target.value)}}
                  // onKeyUp={(e)=>{if(/^\d+$/.test(e.target.value))handleUpArrowEvent(e)}}
                  isInvalid={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  ></Form.Control>
                </div>
                 {/* {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="ErrorTextSignup mt-1" style={{ color: "red" }}>
                  {formik.errors.phoneNumber}
                </div>
              )} */}
              </div>
              {/* <Form.Control
                id="phoneNumber"
                name="phoneNumber"
                // placeholder="Enter Your mobile Number"
                placeholder={formik.errors.phoneNumber ? formik.errors.phoneNumber : "Enter Your mobile Number"}
                className="InputBoxSignUp mb-2"
                maxLength="8"
                onKeyDown={handleEnter}
                {...formik.getFieldProps("phoneNumber")}
                onKeyUp={handleUpArrowEvent}
                isInvalid={formik.touched.phoneNumber && formik.errors.phoneNumber}
              ></Form.Control> */}
              {/* {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="ErrorTextSignup mt-1" style={{ color: "red" }}>
                  {formik.errors.phoneNumber}
                </div>
              )} */}
            </div>
            <div className="InputBoxSignUp" style={{ position: "relative" }}>
              <Form.Control
                // placeholder="Enter Your Password"
                placeholder={
                  formik.errors.passWord || formik.errors.confirmPassword
                    ? formik.errors.passWord || formik.errors.confirmPassword
                    : "Enter Your Password"
                }
                type={togglePass ? "text" : "password"}
                id="passWord"
                name="passWord"
                {...formik.getFieldProps("passWord")}
                className="mb-2"
                onKeyDown={handleEnter}
                isInvalid={
                  (formik.touched.passWord && formik.errors.passWord) ||
                  (formik.touched.confirmPassword &&
                    formik.errors.confirmPassword)
                }
                onKeyUp={handleUpArrowEvent}
              ></Form.Control>
              <div
                className="toggle-pass sign-up-pass"
                onClick={() => {
                  setTogglePass(!togglePass);
                }}
              >
                {togglePass ? <FaRegEye /> : <FaRegEyeSlash />}
              </div>
              {/* {formik.touched.passWord && formik.errors.passWord && (
                <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
                  {formik.errors.passWord}
                </div>
              )}
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
  <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
    {formik.errors.confirmPassword}
  </div>
)} */}
            </div>

            <div className="InputBoxSignUp" style={{ position: "relative" }}>
              {/* <Form.Control
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
          /> */}
              <Form.Control
                // placeholder="Enter Your Password"
                placeholder={
                  formik.errors.confirmPassword
                    ? formik.errors.confirmPassword
                    : "Enter confirm Password"
                }
                type={toggleConfirmPass ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
                className="mb-2"
                onKeyDown={handleEnter}
                isInvalid={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                onKeyUp={handleUpArrowEvent}
              ></Form.Control>
              <div
                className="toggle-pass sign-up-pass"
                onClick={() => {
                  setToggleConfirmPass(!toggleConfirmPass);
                }}
              >
                {toggleConfirmPass ? <FaRegEye /> : <FaRegEyeSlash />}
              </div>
              {/* <Form.Control.Feedback type="invalid">
            {formik.errors.confirmPassword}
          </Form.Control.Feedback> */}
              {/* {formik.touched.confirmPassword && formik.errors.passWord && (
                <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
                  {formik.errors.confirmPassword}
                </div>
              )} */}

              {/* {formik.touched.confirmPassword && formik.errors.confirmPassword && (
  <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
    {formik.errors.confirmPassword}
  </div>
)}

{formik.touched.confirmPassword && formik.errors.passWord && (
  <div className="ErrorTextSignup mb-2" style={{ color: "red" }}>
    {formik.errors.passWord}
  </div>
)} */}
            </div>
          </div>
          {/* <div className="get-otp-btn">
            <Button
              lg="3"
              className="getNumber getOtpSignup get_otp_button_login"
              type="submit"
              onClick={getSignUpOtpApi}
              disabled={
                timerCount !== "00:00" && timerCount !== null ? true : false
              }
            >
              <small>{timerCount === null ? "Submit" : "Resend OTP"}</small>
            </Button>
 {isGetSignUpOtp ? null : (
  <Button
    lg="3"
    className="getNumber getOtpSignup get_otp_button_login"
    type="submit"
    onClick={getSignUpVerifyApi}
    disabled={timerCount !== "00:00" && timerCount !== null}
  >
    <small>Continue</small>
  </Button>
)}

          </div> */}
          <div className="userSignUpDatas">
            <div className="hear-abt">
              <p className="write_here_text ms-2">How did you hear about us?</p>
            </div>
            <div className="signup-container selectBoxSignUp">
              <Form.Select
                name="about"
                className="select_shared_source"
                value={hearAbout}
                aria-label="Default select example"
                onChange={handle}
                ref={hearRef}
              >
                <i class="fa-solid fa-caret-down"></i>{" "}
                {/* {userList?.map((item, index) => (
      <option value={item.name} key={item.id}>
        {item.name}
      </option>
    ))} */}
                <option value="" disabled hidden>
                  Please Select an option
                </option>{" "}
                {/* Default option */}
                {userList?.map((item, index) => (
                  <option value={item.name} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              {/* {hearAbout === "Other" || hearAbout === "" ? (
    <>
      <Form.Control
        className="HearAbout_Write_text"
        type="text"
        id="hearAboutInputText"
        name="hearAboutInputText"
        placeholder="Write here"
        value={hearAboutInputText}
        onChange={handleHearAboutValue}
      />

      <div>
        <p style={{ color: "red" }} className="ErrorTextSignup">
          {" "}
          {showInputError}
        </p>
      </div>
    </>
  ) : null} */}
              {hearAbout === "Other" ? (
                <>
                  <Form.Control
                    className="HearAbout_Write_text"
                    type="text"
                    id="hearAboutInputText"
                    name="hearAboutInputText"
                    placeholder="Write here"
                    value={hearAboutInputText}
                    onChange={handleHearAboutValue}
                  />

                  <div>
                    <p style={{ color: "red" }} className="ErrorTextSignup">
                      {" "}
                      {showInputError}
                    </p>
                  </div>
                </>
              ) : hearAbout === "" ? (
                <div>
                  <p style={{ color: "red" }} className="ErrorTextSignup">
                    Please select how you heard about us.
                  </p>
                </div>
              ) : null}
            </div>
            <div className="signup-container mt-2">
              <div className="CheckBoxContainerSignUp">
                <div>
                  <Form.Check
                    type="checkbox"
                    checked={checked}
                    onChange={handleRememberMe}
                    name="rememberMe"
                    id="rememberMe"
                    className="ms-2 "
                  />
                </div>
                <div className=" signUpTerms">
                  <small className="signTextLanding">
                    By creating an account.I accept the
                    <a
                      href="/terms_condition"
                      target="_blank"
                      className="termsConditionLogin ms-5"
                    >
                      Terms &{" "}
                      <small className="ConditionPrivacyTerms">
                        Conditions & Privacy Policy
                      </small>
                    </a>
                  </small>
                  <p
                    style={{ color: "red" }}
                    className="AcceptErrorTextSignup mt-1"
                  >
                    {checked === false ? checkedError : null}
                  </p>
                </div>
              </div>
              <div className="get-otp-btn">
                <Button
                  type="submit"
                  className="signup_button_form ms-2"
                  onClick={() => {
                    handleChecked();
                    handleSubmiter();
                    // handleChecked();
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </Row>
        {/* {isGetSignUpOtp ? (
          <>
            <div className="userSignUpDatas">
              <div className="change_text desc-login">
                <p className="otp signupotpText">
                  Please enter the OTP received
                </p>
              </div>
              <div className="change_text desc-login otpformat">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  className="otp_inputs_Login signuppotp"
                />
                <p style={{ color: "red" }}> {showError}</p>
                {timerCount !== null && timerCount !== "00:00" ? (
                  <div>
                    <small className="TimerCountValue">
                      Time left {timerCount}{" "}
                    </small>
                    <span id="timer"></span>
                  </div>
                ) : null}
              </div>

              <div className="hear-abt">
                <p className="write_here_text ms-2">
                  How did you hear about us?
                </p>
              </div>
              <div className="signup-container selectBoxSignUp">
                <Form.Select
                  name="about"
                  className="select_shared_source"
                  value={hearAbout}
                  aria-label="Default select example"
                  onChange={handle}
                  ref={hearRef}
                >
                  <i class="fa-solid fa-caret-down"></i>{" "}
                  {userList?.map((item, index) => (
                    <option value={item.name} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                {hearAbout === "Other" || hearAbout === "" ? (
                  <>
                    <Form.Control
                      className="HearAbout_Write_text"
                      type="text"
                      id="hearAboutInputText"
                      name="hearAboutInputText"
                      placeholder="Write here"
                      value={hearAboutInputText}
                      onChange={handleHearAboutValue}
                    />

                    <div>
                      <p style={{ color: "red" }} className="ErrorTextSignup">
                        {" "}
                        {showInputError}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="signup-container mt-2">
                <div className="CheckBoxContainerSignUp">
                  <div>
                    <Form.Check
                      type="checkbox"
                      checked={checked}
                      onChange={handleRememberMe}
                      name="rememberMe"
                      id="rememberMe"
                      className="ms-2 "
                    />
                  </div>
                  <div className=" signUpTerms">
                    <small className="signTextLanding">
                      By creating an account.I accept the
                      <a
                        href="/terms_condition"
                        target="_blank"
                        className="termsConditionLogin ms-5"
                      >
                        Terms &{" "}
                        <small className="ConditionPrivacyTerms">
                          Conditions & Privacy Policy
                        </small>
                      </a>
                    </small>
                    <p
                      style={{ color: "red" }}
                      className="AcceptErrorTextSignup mt-1"
                    >
                      {checked === false ? checkedError : null}
                    </p>
                  </div>
                </div>
                <div className="get-otp-btn">
                  <Button
                    type="submit"
                    className="signup_button_form ms-2"
                    onClick={() => {
                      handleSubmiter();
                      handleChecked();
                    }}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : null} */}
      </Form>

      {/* <Form onSubmit={formik.handleSubmit}></Form> */}
      {/* address modal show code */}
      {/* <Location showLocation={showLocation} LocationPopUp={LocationPopUp} /> */}
    </>
  );
}
// const mapDispatchToProps = (dispatch) => {
//   return {
//     getSignUpResponse: (data) => dispatch(getSignUpResponse(data)),
//   };
// };
// connect(null, mapDispatchToProps)()
export default Signup;
