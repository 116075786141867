import React from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import SearchItems from "./SearchItems";
import "./Search.css";
import {
  searchAutocomplete,
  searchByKeywordApi,
} from "../../services/SearchPageServices";
import { useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { deliverLatLngData } from "../../constants/Utils";
import { singaporeTimeConverter } from "../../constants/Utils";
import { useLocation } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useFormik } from "formik";
import Loader from "../Loaders/Loader";

function Search() {
  const [searchResp, setSearchResp] = useState([]);
  const [searchKey, setSearchKey] = useState();
  const [searchItems, setSearchItems] = useState([]);
  const [recentClick, setRecentClick] = useState("");
  const [dropValue, setDropValue] = useState({
    id: 0,
    label: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const searchValue = useSelector((state) => state.counter.searchValue);
  const { state } = useLocation();

  //initial search options api call

  const searchAutocompleteValuesApi = async () => {
    let postsearchAutocompleteObject = {
      business_type: state?.business_type,
      food_type: state?.food_type ? state?.food_type : 0,
      keyword: searchKey ? searchKey : null,
    };

    try {
      let searchAutocompleteResponse = await searchAutocomplete(
        postsearchAutocompleteObject
      );

      const searchList = [];
      if (searchAutocompleteResponse?.data?.data) {
        searchAutocompleteResponse?.data?.data.map((item, i) => {
          let obj = {
            label: item.display_name,
            id: i + 1,
          };
          searchList.push(obj);
        });
      }
      setLoading(false);
      setSearchResp(searchList);
    } catch (e) {}
  };

  useEffect(() => {
    searchAutocompleteValuesApi();
    setLoading(true);
  }, []);

  //initial search options api call end

  useEffect(() => {
    if (searchValue) {
      setSearchKey(searchValue);
    }
  }, [searchValue]);

  const searchFormik = useFormik({
    initialValues: {
      page: "0",
      limit: "10",
      business_type: sessionStorage.getItem("isBevarageVeg")
        ? sessionStorage.getItem("isBevarageVeg")
        : "0", //food, gro, bev, dinein
      food_type: sessionStorage.getItem("isBevarageVeg")
        ? sessionStorage.getItem("selected_tab_search")
          ? sessionStorage.getItem("selected_tab_search")
          : "0"
        : "0", // Rest or homemade
      keyword: null,

      date_timestamp: singaporeTimeConverter(),
      lat: deliverLatLngData()?.lat,
      lng: deliverLatLngData()?.lng,
    },
    onSubmit: async (values) => {
      setSearchLoading(true);
      try {
        let searchByKeywordResponse = await searchByKeywordApi(values);
        setSearchItems(searchByKeywordResponse?.data?.data);
        setSearchStatus(searchByKeywordResponse?.data?.status);
        let data = names;
        if (sessionStorage.getItem("searchKey") != undefined) {
          let tempKey = sessionStorage.getItem("searchKey");
          if (data.length > 0) {
            let existIndex = data?.findIndex((ele) => ele == tempKey);
            if (existIndex < 0) {
              data.push(
                sessionStorage.getItem("searchKey")
                  ? sessionStorage.getItem("searchKey")
                  : null
              );
            } else {
              var element = data[existIndex];
              data.splice(existIndex, 1);
              data.splice(data.length, 0, element);
            }
          } else {
            data.push(
              sessionStorage.getItem("searchKey")
                ? sessionStorage.getItem("searchKey")
                : null
            );
          }
        }

        setNames(data);
        sessionStorage.setItem("searchHistory", JSON.stringify(data));
        setSearchLoading(false);
        setRecentClick("");
      } catch (e) {}
    },
  });

  useEffect(() => {
    setSearchKey(searchFormik.values.keyword);

    if (searchKey !== "undefined") {
      sessionStorage.setItem("searchKey", searchFormik.values.keyword);
    }
  }, [searchFormik.values.keyword]);

  const [names, setNames] = useState(
    sessionStorage.getItem("searchHistory")
      ? JSON.parse(sessionStorage.getItem("searchHistory"))
      : []
  );

  const [searchStatus, setSearchStatus] = useState();
  // const searchByKeywordValuesApi = async (item) => {
  //   if (searchKey !== "undefined") {
  //     let postSearchByKeywordObject = {
  //       page: "0",
  //       limit: "10",
  //       business_type: sessionStorage.getItem("isBevarageVeg")
  //         ? sessionStorage.getItem("isBevarageVeg")
  //         : "0", //food, gro, bev, dinein
  //       food_type: sessionStorage.getItem("isBevarageVeg")
  //         ? sessionStorage.getItem("selected_tab_search")
  //           ? sessionStorage.getItem("selected_tab_search")
  //           : "0"
  //         : "0", // Rest or homemade
  //       keyword: searchKey ? searchKey : null,

  //       date_timestamp: singaporeTimeConverter(),
  //       lat: deliverLatLngData()?.lat,
  //       lng: deliverLatLngData()?.lng,
  //     };

  //     try {
  //       let searchByKeywordResponse = await searchByKeywordApi(
  //         postSearchByKeywordObject
  //       );
  //       setSearchItems(searchByKeywordResponse?.data?.data);
  //       setSearchStatus(searchByKeywordResponse?.data?.status);
  //       let data = names;
  //       if (sessionStorage.getItem("searchKey") != undefined) {
  //         let tempKey = sessionStorage.getItem("searchKey");
  //         if (data.length > 0) {
  //           let existIndex = data?.findIndex((ele) => ele == tempKey);
  //           if (existIndex < 0) {
  //             data.push(
  //               sessionStorage.getItem("searchKey")
  //                 ? sessionStorage.getItem("searchKey")
  //                 : null
  //             );
  //           } else {
  //             var element = data[existIndex];
  //             data.splice(existIndex, 1);
  //             data.splice(data.length, 0, element);
  //           }
  //         } else {
  //           data.push(
  //             sessionStorage.getItem("searchKey")
  //               ? sessionStorage.getItem("searchKey")
  //               : null
  //           );
  //         }
  //       }

  //       setNames(data);
  //       sessionStorage.setItem("searchHistory", JSON.stringify(data));
  //     } catch (e) {}
  //   }
  // };

  // const handleOptionSelect = (event, newValue, type) => {
  //   setMovies(newValue);
  //   setSearchKey(newValue.label);

  //   if (searchKey !== "undefined") {
  //     sessionStorage.setItem("searchKey", newValue.label);
  //   }

  //   if (type === "auto") {
  //     setTimeout(() => {
  //       searchByKeywordValuesApi();
  //     }, 1500);
  //   }
  // };

  useEffect(() => {
    if (searchResp && searchResp.length > 0 && recentClick != "") {
      let keyIndex = searchResp.findIndex((ele) => ele.label == recentClick);
      if (keyIndex >= 0) {
        setDropValue(searchResp[keyIndex]);
        searchFormik.setFieldValue("keyword", searchResp[keyIndex].label);
        setTimeout(() => {
          searchFormik.handleSubmit();
        }, 500);
      }
    }
  }, [recentClick, searchResp]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <Container>
        <form onSubmit={searchFormik.handleSubmit}>
          <div className="srchOtrWrpr d-flex align-items-center">
            <div className="search">
              <Autocomplete
                open={selectOpen}
                noOptionsText={"No Data Found"}
                // disablePortal
                id="searchKey"
                disableClearable={true}
                value={dropValue}
                options={searchResp}
                getOptionLabel={(option) => option.label}
                onChange={(_, value) => (
                  setDropValue(value),
                  searchFormik.setFieldValue("keyword", value.label)
                )}
                onInputChange={(_, value) => {
                  if (value.length === 0) {
                    if (selectOpen) setSelectOpen(false);
                  } else if (recentClick === "") {
                    if (!selectOpen) setSelectOpen(true);
                  }
                }}
                onClose={() => setSelectOpen(false)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: "",
                    }}
                    placeholder="Search Product & Restuarants"
                  />
                )}
              />
            </div>
            <Button
              type="submit"
              className="searchPageButton"
              disabled={!searchFormik.values.keyword || searchLoading}
            >
              {searchLoading ? (
                <CircularProgress size={20} />
              ) : (
                <AiOutlineSearch className="SearchIconSE" />
              )}
            </Button>
          </div>
          {/* <Row>
            <Col lg="8" sm="8" md="8" xs="8"></Col>
            <Col lg="3" sm="3" md="3" xs="3">
              
            </Col>
          </Row> */}
        </form>

        <Row className="scrollbutton">
          <Card className="cardsearch">
            <SearchItems
              names={names}
              searchStatus={searchStatus}
              searchDatas={searchItems}
              searchByKeywordValuesApi={searchFormik.handleSubmit}
              searchKey={searchKey}
              setRecentClick={setRecentClick}
            />
          </Card>
        </Row>
      </Container>
    </>
  );
}

export default Search;
