import { React, useState, useEffect } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import { useSelector, useDispatch } from "react-redux";
import "./Location.css";
import { useFormik } from "formik";
import { addressId } from "../../../constants/Utils";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { getUserType } from "../../../constants/Utils";
import swal from "sweetalert";


  import {VerifyAccountEmailApi
  } from "../../../services/Landingservice";




const VerifyAccount = ({ resetForm, LocationPopUp, showLocation, tabValueData }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CloseLocationPopUp = async (e) => {
    e.preventDefault();
    LocationPopUp(false); //callback function
    const formData = {
        email: profileEmail, 
        app_id: "2",
      };

      try {
        let emailVerifyResponse = await VerifyAccountEmailApi(formData)

        if (emailVerifyResponse.status === 200) {
            swal({
        // title: "A verification link has been sent to your email. Please verify your email to continue",
            title: "Success",
            text: emailVerifyResponse?.data?.message,
            type: "success",
            timer: 6000,
            button: false,
            confirmButtonText: "OK",
            className: "popuptetx",
            confirmButtonColor: "#8CD4F5",
            icon: "success",
      });
        } else {
          swal({
            title: "Error",
            text: emailVerifyResponse?.data?.message,
            type: "error",
            timer: 6000,
            button: false,
            confirmButtonText: "OK",
            className: "popuptetx",
            confirmButtonColor: "#8CD4F5",
            icon: "error",
          });
        }
      } catch (error) {
        
      }

    

    // console.log(formData,"formData");

  };

 


  const [profileEmail, setProfileEmail] = useState("");

  useEffect(() => {
    const storedEmail = JSON.parse(sessionStorage.getItem("otpResponse"))?.email;
    if (storedEmail) {
      setProfileEmail(storedEmail);
    }
  }, []);

  const [tabValue, setTabValue] = useState("");


  const navigateToHome = () => {
    // navigate("/home");
    navigate("/");

  };






  


  




  
  

  return (
     <>
      <Modal
        className="locationPopupModal"
        show={showLocation}
        onHide={CloseLocationPopUp}
        animation={true}
      >
        <Modal.Header className="locationPopUp_Header border-0" closeButton>
          <Col lg="10">
            <Modal.Title className="ms-2 mt-1">
              <b>Please Click On Update to Verify Your Account</b>
            </Modal.Title>
            <hr width="80%" className="ms-2"></hr>
          </Col>
        </Modal.Header>
        <Form
        
          id="users"
          className="locationPopupForm"
        >
          <Modal.Body>
            <Container>
              <Row>
                <div className="input-style">
                  <Form.Label>
                    Email
                  </Form.Label>
                  <Form.Control
                        name="profileEmail"
                        id="profileEmail"
                        type="email"      
                        className="form-control inputLocation2 mt-2 mb-2 "
                        placeholder="Email"
                        value={profileEmail}
                        readOnly
                      ></Form.Control>
                </div>
             
              </Row>
            </Container>
        

            <Container>
              <Row>
                <Button
                  className={
                    getUserType() !== 2 && getUserType() !== null
                      ? "confirm_btn locationConfirmBtn"
                      : "confirm_btn locationConfirmBtn  mt-4"
                  }
                  type="submit"
                  onClick={CloseLocationPopUp}
                >
                   Update or Confirm
                </Button>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  )
}

export default VerifyAccount