import React from "react";
import Search from "../../components/Searchpage/Search";
import SearchHeader from "../../components/Searchpage/SearchHeader";
function SearchContainer() {
  return (
    <>
      <SearchHeader />
      <Search />
    </>
  );
}
export default SearchContainer;
