import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import {
  addRemoveProductCartApi,
  checkoutDetailsPostApi,
  customizeItemsApi,
} from "../../../../services/CartCheckOutServices";
import { useSelector, useDispatch } from "react-redux";
import { cartQuantityChange } from "../../../../containers/app/features/CounterSlice";
import "./Popular.css";
import moment from "moment";
import "moment-timezone";

import {
  deliverLatLngData,
  getName,
  getUserData,
  selfPickUpValue,
  sessionLocationData,
  singaporeTimeConverter,
} from "../../../../constants/Utils";
import "./TabPanel.css";
import VariantPopUp from "./VariantPopUp";
import FloatingMenu from "./FlotingMenu";
import Location from "../../../Landing/Location/Location";
import { json } from "react-router-dom";
import Loader from "../../../Loaders/Loader";
function TabPanel({ children, value, index }) {
  //storing onjects in props
  return <div>{value === index && <>{children}</>}</div>;
}

//implementing proptypes to identifying
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
//identify function
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({
  ProductData,
  isToggle,
  promoManualCode,
  filterProductItems,
  globalCheckoutDetails,
  globalCheckoutCallback,
  isVariant,
  setaddRemoveVariantCount,
}) => {
  const [isVariantAvailable, setisVariantAvailable] = useState(false);
  // const closeVariantPopUp = () => {
  //   setisVariantAvailable(false);
  // };

  const [productValues, setProductValues] = useState();
  const [itemvalues, setItemvalues] = useState();
  const [value, setValue] = useState(0);
  const [productItems, setProductItems] = useState();
  const [checkOutDetails, setCheckOutDetails] = useState();
  const [showLocation, setShowLocation] = useState(false);

  let findProductindex = globalCheckoutDetails?.cart_products?.findIndex(
    (ele) => ele.product_id === productValues?.product_id
  );

  const showLocationPopup = () => setShowLocation(true);
  const LocationPopUp = (value) => {
    setShowLocation(value);
  };

  let userName = getName() ? getName()[0] : null;
  const dispatch = useDispatch();
  const CartQuantityChange = useSelector(
    (state) => state.counter.cartQuantityChange
  );
  const CartQuantityChangeViceVersa = useSelector(
    (state) => state.counter.cartQuantityChangeViceVersa
  );

  let filterProduct = filterProductItems?.category_list;
  useEffect(() => {
    dispatch(cartQuantityChange(CartQuantityChange + 1));
  }, [globalCheckoutDetails]);

  useEffect(() => {
    setProductItems(ProductData?.category_list);
  }, [ProductData]);
  const [prodCartVal, setProdCartVal] = useState();
  let prodCart = sessionStorage.getItem("restaurant_product_inCart");
  let restHomeId = sessionStorage.getItem("restaurantData");
  let restId = prodCart;
  if (prodCart != undefined && restHomeId != undefined) {
    restId = restHomeId;
  } else {
    restId = prodCart;
  }
  const checkoutPostDetailsValuesApi = async (data) => {
    setProdCartVal(prodCart);

    let postcheckoutDetailsObjectValuesObject = {
      restaurant_id:
        prodCart && prodCart != "undefined"
          ? prodCart
          : sessionStorage.getItem("restaurantData")
          ? sessionStorage.getItem("restaurantData")
          : null,
      order_type: selfPickUpValue() ? selfPickUpValue() : "1",
      pickup_time: sessionStorage.getItem("pickupFrom")
        ? sessionStorage.getItem("pickupFrom")
        : singaporeTimeConverter(),
      latitude: deliverLatLngData()?.lat,
      longitude: deliverLatLngData()?.lng,
      pin_address: sessionLocationData()?.pin_address,
      delivery_name: userName ? userName : null,
      delivery_email: getUserData()?.email,
      delivery_mobile: getUserData()?.mobile,
      unit_number: sessionLocationData()?.unit_number,
      street_address: sessionLocationData()?.street_address,
      postal_code: sessionLocationData()?.postal_code,
      date_timestamp: singaporeTimeConverter(),
    };

    try {
      if (postcheckoutDetailsObjectValuesObject.restaurant_id) {
        let checkoutPostDetailsResponse = await checkoutDetailsPostApi(
          postcheckoutDetailsObjectValuesObject
        );
        setCheckOutDetails(
          checkoutPostDetailsResponse?.data?.data?.cart_products
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (ProductData) {

      cartQuantityUpdate(
        globalCheckoutDetails?.cart_products
          ? globalCheckoutDetails?.cart_products
          : []
      );
    }
  }, [globalCheckoutDetails]);

  useEffect(() => {
    if (CartQuantityChangeViceVersa) {
      globalCheckoutCallback();
    }
  }, [CartQuantityChangeViceVersa]);

  const cartQuantityUpdate = (productQuantityList) => {

    if (productQuantityList && productQuantityList.length > 0 && ProductData) {
      let updatedData = { ...ProductData };
      updatedData?.category_list?.forEach((obj) => {
        obj.product_list?.forEach((obj2) => {
          obj2.counter = 0;
          productQuantityList?.forEach((productQuantityObj) => {
            if (
              obj2.product_id === productQuantityObj.product_id &&
              obj.category_id === productQuantityObj.category_id
            ) {
              obj2.counter = productQuantityObj.product_quantity;
            
            }
          });
        });
      });
      setProductItems(updatedData?.category_list);
    }
  };
  sessionStorage.setItem("variantState", isVariantAvailable);
  // dispatch(checkOutDetailsFunc(checkOutAllDetails?.data?.data));
  //   useEffect(()=>{
  //     const handleVariant=()=>{
  //     }
  //     handleVariant()
  //   },[])

  useEffect(() => {
    if (isToggle) {
      let newArray = [...filterProduct];
      let resultArray = [];
      newArray?.map((obj, i) => {
        const list = obj.product_list;
        let result = list?.filter((obj2) => {
          return obj2.is_product_veg == "1";
        });
        let object = {
          ...obj,
          product_list: result,
        };
        resultArray.push(object);
      });
      setProductItems(resultArray);
    } else {
      setProductItems(filterProduct);
    }
  }, [isToggle]);
  const [variantDatas, setVariantDatas] = useState();
  const [defaultVariant, setDefaultVariant] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkVariant, setCheckVariant] = useState(false);
  const customizeItemsValuesApi = async (data) => {
    let postcustomizeItemsValuesValuesObject = {
      page: "0",
      limit: "10",
      product_id: data.product_id ? data.product_id : "2374",
    };

    try {
      let customizeItemsApiResponse = await customizeItemsApi(
        postcustomizeItemsValuesValuesObject
      );
      setVariantDatas(customizeItemsApiResponse?.data?.data);
    } catch (e) {}
  };
  const addRemoveProductCartApiValuesApi = async (data) => {
    let postpostProductListingValuesObjectValuesObject = {
      product_id: data?.product_id,
      restaurant_id: sessionStorage.getItem("restaurantData")
        ? sessionStorage.getItem("restaurantData")
        : null,
      is_variant_selected: "2",
      prod_quantity: data.counter,
    };
    setLoading(true);
    try {
      let addRemoveProductCartApiValuesApiValuesResponse =
        await addRemoveProductCartApi(
          postpostProductListingValuesObjectValuesObject
        );
      
      // alert("product added to cart");

      if (addRemoveProductCartApiValuesApiValuesResponse) {
        globalCheckoutCallback();
        setTimeout(() => {
          setCheckVariant(true);
        }, 1100);

        setTimeout(() => {
          setLoading(false);
        }, 1200);
      }
    } catch (e) {}
  };
  // let restId = sessionStorage.getItem("restaurantData")
  //   ? sessionStorage.getItem("restaurantData")
  //   : null;
  // let productId = sessionStorage.getItem("restaurant_product_inCart")
  //   ? sessionStorage.getItem("restaurant_product_inCart")
  //   : null;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("setTabValue", newValue);
  };

  const handleVariant = (data, item, mode) => {
    setProductValues(data);
    setItemvalues(item);
    if (data.is_variant_avl === 1) {
      setisVariantAvailable(true);
      customizeItemsValuesApi(data);
    } else {
      if (mode === "Add") {
        handleAdd(data, item);
      } else if (mode === "Increment") {
        handleIncrement(data, item);
      } else if (mode === "Decrement") {
        handleDecrement(data, item);
      }
    }
  };
  const handleAdd = (data, item) => {
    // setPromoFunc(promoManualCode)
    // sessionStorage.removeItem("ManualPromo");
    if (
      JSON.parse(sessionStorage.getItem("isDirectLanding")) == 1 &&
      Object.keys(JSON.parse(sessionStorage.getItem("userLocation"))).length ==
        0
    ) {
      showLocationPopup();
    } else {
      setProductItems(() =>
        productItems.map((obj, i) => {
          sessionStorage.setItem(
            "identifierInCart",
            globalCheckoutDetails?.restaurant_detail?.rest_identifier
          );
          if (obj.category_id === item.category_id) {
            obj.product_list.forEach((obj2) => {
              if (obj2.product_id === data.product_id) {
                if (
                  sessionStorage.getItem("variantState") == true &&
                  sessionStorage.getItem("popupVarientOpen") == 1
                ) {
                  sessionStorage.setItem("popupVarientOpen", 1);
                  obj2.counter = 0;
                  addRemoveProductCartApiValuesApi(obj2);
                  
                } else {
                  obj2.counter = 1;
                  addRemoveProductCartApiValuesApi(obj2);
                  
                }
              }
            });
            return obj;
          }
          return obj;
        })
      );
    }
  };
  // };
  const handleIncrement = (data, item) => {
    sessionStorage.removeItem("ManualPromo");

    setProductItems((f) =>
      f.map((obj, i) => {
        if (obj.category_id === item.category_id) {
          obj.product_list.forEach((obj2) => {
            if (obj2.product_id === data.product_id) {
              obj2.counter = parseInt(obj2.counter) + 1;

              addRemoveProductCartApiValuesApi(obj2);
              
            }
          });
          return obj;
        }

        return obj;
      })
    );
  };
  const handleDecrement = (data, item) => {
    sessionStorage.removeItem("ManualPromo");

    setProductItems(() =>
      productItems.map((obj, i) => {
        if (obj.category_id === item.category_id) {
          obj.product_list.forEach((obj2) => {
           
            if (obj2.product_id === data.product_id) {
              obj2.counter = parseInt(obj2.counter) - 1;
              addRemoveProductCartApiValuesApi(obj2);
              
            }
          });
          return obj;
        }
        return obj;
      })
    );
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const isSticky = (e) => {
    const header = document.querySelector(".tab-container");
    const scrollTop = window.scrollY;
    if (windowWidth > 1200 && windowWidth < 1600) {
      scrollTop >= 800
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    } else if (windowWidth > 992 && windowWidth < 1200) {
      scrollTop >= 600
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    } else if (windowWidth > 576 && windowWidth < 801)
      scrollTop >= 500
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    else if (windowWidth > 801 && windowWidth < 992)
      scrollTop >= 600
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    else if (windowWidth > 450 && windowWidth < 576)
      scrollTop >= 700
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    else if (windowWidth > 376 && windowWidth < 450)
      scrollTop >= 650
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    else if (windowWidth > 320 && windowWidth < 376)
      scrollTop >= 600
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
    else if (windowWidth > 1600 && windowWidth < 2000)
      scrollTop >= 960
        ? header.classList.add("tabSticky")
        : header.classList.remove("tabSticky");
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  let time = moment()?.tz("Asia/Singapore").format("M-D-YYYY hh:mm:ss ");
  let singaporeTime = new Date(time);

  // let day=new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(time)
  let datass = sessionStorage.getItem("setTabValue")
    ? JSON.parse(sessionStorage.getItem("setTabValue"))
    : 0;
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="tab-container">
        <Tabs
          value={datass}
          variant="scrollable"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {productItems?.map((item, index) => (
            <Tab
              label={item?.category_name.toLowerCase()}
              className="tabstextdate"
              inputProps={{ style: { textTransform: "lowercase" } }}
              value={index}
            >
              {index}
            </Tab>
          ))}
        </Tabs>
      </div>

      {productItems?.map((item, index) => {
        return (
          <TabPanel index={index} value={datass}>
            <div className="category-theme">
              <div className="cat-name">
                {/* <b>{item.category_name}</b> */}
              </div>
              <div className="item-card-container">
                {item.product_list.map((data, index) => {
                  return (
                    <div
                      className={
                        data.is_product_avl === 0
                          ? "not-allow-cursor item-card-tabpanel"
                          : "item-card-tabpanel"
                      }
                      // onClick={() => {
                      //
                      // }}
                    >
                      <div className="crdInrWrpr">
                        <div className="dishImage">
                          <img src={data.product_image} alt="" />
                        </div>
                        <div className="dshDtls">
                          <p className="dshTtle">{data.product_name}</p>
                          <p className="dshDscpt">{data.product_description}</p>
                          <div className="prcContnr">
                            <div className="offer-price">
                              <p>
                                S$
                                {data.offer_price > 0
                                  ? data.offer_price
                                  : data.price}
                              </p>
                            </div>
                            {data.is_product_avl === 0 ? (
                              <div className="currently-unavailable">
                                Currently Unavailable
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="btnWrpr">
                        
                        <div className="add-btn">
                          {(data?.counter && data?.counter > 0) ||
                          data?.cart?.[0]?.product_quantity > 0 ? (
                            <Button id={index}>
                              <div
                                className="in-de-btn"
                                disabled={
                                  data?.counter === 0 || data?.counter < 0
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  handleVariant(data, item, "Decrement");
                                  // handleDecrement(data, item);
                                }}
                              >
                                -
                              </div>
                              <div className="count">
                                {" "}
                                {data?.counter > 0
                                  ? data?.counter
                                  : data.cart?.[0]?.product_quantity}{" "}
                              </div>
                              <div
                                className="in-de-btn"
                                onClick={() => {
                                  handleVariant(data, item, "Increment");
                                  // handleIncrement(data, item);
                                }}
                              >
                                +
                              </div>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                handleVariant(data, item, "Add");
                                // handleAdd(data, item);
                              }}
                              disabled={
                                data.is_product_avl === 0 ||
                                sessionStorage.getItem("identifier_key") ==
                                  undefined
                                  ? true
                                  : false
                              }
                              className={
                                data.is_product_avl === 0
                                  ? "not-allow-cursor"
                                  : ""
                              }
                            >
                              <div className="add-tab-btn">Add +</div>
                            </Button>
                          )}
                        </div>
                      </div>
                      {/* <div className="card1">
                        {data.is_product_veg === "1" ? (
                          <FaCircle className="itemTypeVeg" />
                        ) : (
                          <FaCircle className="itemTypeNonVeg" />
                        )}
                      </div> */}
                      {/* <div className="card2">
                        <small className="foodTitleProductList"></small>
                        <div className="itemDescriptionRestaurant">
                          {data.product_description}
                        </div>
                        <div className="price-offer-container">
                          <div className="offer-price">
                            <div className={"price"}>
                              S$
                              {data.offer_price > 0
                                ? data.offer_price
                                : data.price}
                            </div>
                          </div>
                          {data.is_product_avl === 0 ? (
                            <div className="currently-unavailable">
                              Currently Unavailable
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="btn-div-cont card3">
                        <div className="add-btn">
                          {(data?.counter && data?.counter > 0) ||
                          data?.cart?.[0]?.product_quantity > 0 ? (
                            <Button id={index}>
                              <div
                                className="in-de-btn"
                                disabled={
                                  data?.counter === 0 || data?.counter < 0
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  handleVariant(data, item, "Decrement");
                                  // handleDecrement(data, item);
                                }}
                              >
                                -
                              </div>
                              <div className="count">
                                {" "}
                                {data?.counter > 0
                                  ? data?.counter
                                  : data.cart?.[0]?.product_quantity}{" "}
                              </div>
                              <div
                                className="in-de-btn"
                                onClick={() => {
                                  handleVariant(data, item, "Increment");
                                  // handleIncrement(data, item);
                                }}
                              >
                                +
                              </div>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                handleVariant(data, item, "Add");
                                // handleAdd(data, item);
                              }}
                              disabled={
                                data.is_product_avl === 0 ||
                                sessionStorage.getItem("identifier_key") ==
                                  undefined
                                  ? true
                                  : false
                              }
                              className={
                                data.is_product_avl === 0
                                  ? "not-allow-cursor"
                                  : ""
                              }
                            >
                              <div className="add-tab-btn">Add</div>
                            </Button>
                          )}
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </div>
              {item.product_list?.length === 0 ? (
                <p className="no-data dataNotFoundTabPanel">No data found</p>
              ) : null}
            </div>
          </TabPanel>
        );
      })}
      <div className="floating-menu">
        {/* <FloatingMenu
          productItems={productItems}
          handleChange={handleChange}
          value={value}
        /> */}
      </div>
      {variantDatas ? (
        <VariantPopUp
          productValues={productValues}
          itemvalues={itemvalues}
          variantDatas={variantDatas}
          isVariantAvailable={isVariantAvailable}
          closeVariantPopUp={setisVariantAvailable}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          handleAdd={handleAdd}
          setaddRemoveVariantCount={setaddRemoveVariantCount}
          globalCheckoutDetails={globalCheckoutDetails}
          checkVariant={checkVariant}
          setCheckVariant={setCheckVariant}
          defaultVariant={defaultVariant}
          setDefaultVariant={setDefaultVariant}
          globalCheckoutCallback={globalCheckoutCallback}
        />
      ) : null}

      <Location showLocation={showLocation} LocationPopUp={LocationPopUp} />
    </>
  );
};

export default BasicTabs;
