import { React, useState, useEffect } from "react";
import { Col, Row, Button, Card } from "react-bootstrap";
import "./Myorders.css";
import { myOrdersApi } from "../../../services/ProfilePageServices";
import { MyOrders } from "../../../constants/ProfilePageResponse";
import OrderTrackPopUp from "../Popup/MyOrder/MyOrder/OrderTrackPopUp";
import {
  viewMoreOrderDetailApi,
  trackOrdersApi,
} from "../../../services/ProfilePageServices";
import { getUrl } from "../../../services/URl/Url";
import { useLocation, useNavigate } from "react-router-dom";
import RatingsAddPopup from "../../RestaurentView/CartItemsList/Tabpanelitems/RatingsAddPopup";
function Myorders() {
  const [show, setShow] = useState(false);
  const [myOrdersData, setMyOrdersData] = useState([]);
  const [ratingData, setRatingData] = useState(false);
  const ratingsAddUpData = (value) => {
    setRatingData(value);
  };
  const closePopUp = (value) => {
    setShow(value);
  };
  useEffect(() => {
    const myOrdersValuesApi = async () => {
      let postMyOrderObject = {
        selected_tab: "1",
        page: "0",
        limit: "10",
      };

      try {
        let myOrdersResponse = await myOrdersApi(postMyOrderObject);
        setMyOrdersData(myOrdersResponse.data.data);
       
        // window.location.reload(true); //refresh the page
      } catch (e) {}
    };
    myOrdersValuesApi();
  }, []);
  const [viewMoreOrder, setViewMoreOrder] = useState();
  const [trackOrderDetail, setTrackOrderDetail] = useState();

  const viewMoreOrderDetailValuesApi = async (item) => {
    let postviewMoreOrderDetailObj = {
      order_id: item.id,
    };

    try {
      let viewMoreOrderDetailApidataResponse = await viewMoreOrderDetailApi(
        postviewMoreOrderDetailObj
      );
      setViewMoreOrder(viewMoreOrderDetailApidataResponse.data.data);
    } catch (e) {}
  };
  const trackOrderDetailValuesApi = async (item) => {
    let postTrackOrderDetailObj = {
      order_id: item.id,
    };
    try {
      let trackOrderDetailResponse = await trackOrdersApi(
        postTrackOrderDetailObj
      );
      setTrackOrderDetail(trackOrderDetailResponse.data.data[0]);
    } catch (e) {}
  };
  const [variantData, setVariantData] = useState();

  const handle = () => {
    viewMoreOrder?.product_detail?.map((item, index) => {
      item?.variants?.map((obj, index) => {
        setVariantData(obj);
        return obj;
      });
    });
  };
  const handleShow = (item) => {
    setShow(true);
    viewMoreOrderDetailValuesApi(item);
    trackOrderDetailValuesApi(item);
    handle();
  };
  const handleDateConvert = (obj) => {
    var d = new Date(obj.order_date_time * 1000);

    const resultDate =
      d.getDate() +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getFullYear() +
      "   at   " +
      d.getHours() +
      ":" +
      d.getMinutes().toString().padStart(2, 0);

    return resultDate;
  };
  const now = new Date();

  const formatter = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    hour12: false,
    minute: "numeric",
    month: "numeric",
    day: "numeric",
    year: "numeric",
    second: "numeric",
    timeZone: "Asia/Singapore",
  });
  const [isMobile, setIsMobile] = useState(false);

  //   const singapore=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',    timeZone: "Asia/Singapore",
  // })

  

  // const toTimestamp = (strDate) => {
  //   const dt = new Date(strDate).getTime();
  //   return dt / 1000;
  // }

  
  // var myDate = new Date(singaporeTime); // Your timezone!

 
  //   var myEpoch = myDate.getTime()/1000.0;

  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    }
  }, []);
  let navigate = useNavigate();
  const location = useLocation();

  const NavigateToRestaurant = (item) => {
    
    let restId = sessionStorage.getItem("restaurantData");
    let cartId = sessionStorage.getItem("restaurant_product_inCart");
    
    sessionStorage.setItem("restaurantData", item.restaurant_id);
    sessionStorage.setItem("identifier_key", item.rest_identifier);

    let RestUrl = sessionStorage.getItem("identifier_key")
      ? sessionStorage.getItem("identifier_key")
      : null;

    if (location.pathname === "/profile") {
      navigate(`/restaurants/${RestUrl}`);
    } else if (location.pathname === "/restaurants" && restId !== cartId) {
      window.location.reload(true);
    }
  };
  const [myOrdersReview, setMyOrdersReview] = useState();
  const showRatingPopupData = (item) => {
    setRatingData(true);
    setMyOrdersReview(item);
  };

  return (
    <>
      <div className="order-scroll">
        {myOrdersData?.map((item, index) => (
          <div className="order-global-container">
            <div
              className="order-container restaurant_redirect"
              onClick={() => NavigateToRestaurant(item)}
            >
              <div className="order-img">
                <img
                  src={item.mobile_banner_image}
                  alt=""
                  width="60%"
                  height="60%"
                />
              </div>
              <div className="order-title">
                <div className="rest-name">
                  <b>{item.rest_name}</b>
                </div>
                <div className="addr-name">
                  <p>Address : {item.rest_pin_address}</p>
                </div>
              </div>
            </div>

            <div className="order-container shedule-content">
              {/* {!isMobile ? (
 <> */}
              <div>
                <div>Order No</div>
                <div className="Details myOrdersWidth">{item.order_number}</div>
              </div>
              <div>
                <div>Item</div>
                <div className="Details myOrdersWidth">
                  {item.item_quantity}
                </div>
              </div>
              <div>
                <div>Total Amount</div>
                <div className="Details myOrdersWidth">
                  ${item.total_amount}
                </div>
              </div>
              <div>
                <div>Delivery Address</div>
                {/* <div className="Details address-text">{item.delivery_address}</div> */}
                <marquee
                  Scrollamount={3}
                  className="Details myOrdersWidth"
                  behavior="scroll"
                  direction="left"
                >
                  {item.delivery_address}
                </marquee>
              </div>
              <div>
                <div>Order Date & Time</div>
                
                  <div className="Details myOrdersWidth">{item.order_time}</div>
              </div>
              {/* </>):(
                  <div className="orderDetailProfile">
                  <div>Order No</div>
                  <div className="Details">{item.order_number}</div>
                </div>
              )} */}
            </div>
            <div className="order-container schedule-time">
              <div className="time1">
                <div className="EstimatedTimeProfile">{item.order_type_display_name}</div>
                <div className="Details myOrdersWidth EstimatedTimeProfile">
                  {item.schedule_time}
                </div>
              </div>
              <div className="time1">
                <div className="EstimatedTimeProfile">Category</div>
                <div className="Details myOrdersWidth EstimatedTimeProfile">
                  {item.order_type_name}
                </div>
              </div>
              <div className="time1">
                <div className="EstimatedTimeProfile">Order Status</div>
                <div className="Details myOrdersWidth EstimatedTimeProfile">
                 <span>{(item.order_status ==0) ? 'Placed and Pending':(item.order_status ==1) ? 'Accepted' : (item.order_status ==2) ? 'Rejected': (item.order_status ==3) ? 'Dispatched': (item.order_status ==4) ? 'Cancelled': (item.order_status ==5) ? 'Completed': (item.order_status ==6) ? 'Preparing': (item.order_status ==7) ? 'Ready': (item.order_status ==9) ? 'Delete':''}</span>
                </div>
              </div>
              {item.order_status != "5" ? (
                <div>
                  <Button
                    className="track-order"
                    onClick={() => handleShow(item)}
                  >
                    Track Order
                  </Button>
                </div>
              ) : (
                <div className="ButtonMyOrderReview">
                  <Button
                    className="Re-orderButton ms-3"
                    onClick={() => NavigateToRestaurant(item)}
                  >
                    Reorder
                  </Button>
                  <Button
                    className="WrirReviewButton ms-3"
                    onClick={() => showRatingPopupData(item)}
                  >
                    Write Review
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
        {myOrdersData && myOrdersData.length == 0 ? (
          <div className="order-container">No data found</div>
        ) : null}
      </div>

      <OrderTrackPopUp
        viewMoreOrder={viewMoreOrder}
        trackOrderDetail={trackOrderDetail}
        show={show}
        closePopUp={closePopUp}
        variantData={variantData}
      />
      <RatingsAddPopup
        ratingData={ratingData}
        ratingsAddUpData={ratingsAddUpData}
        myOrdersReview={myOrdersReview}
        // productItems={productItems}
      />
    </>
  );
}

export default Myorders;
