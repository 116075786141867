import { React, useState, useEffect } from "react";
import profile from "../../Asserts/Cartview/profile.png";
import HorizantalBorder from "./HorizantalBorder";
import { GiWallet } from "react-icons/gi";
import { HiShoppingBag } from "react-icons/hi";
import { ProfiledetailApi } from "../../services/ProfilePageServices";
import DineinCart from "./Orders/DineIn/DineinCart";
import Myorders from "./Orders/Myorders";
import { useLocation } from "react-router-dom";
import { updateProfiledetailApi } from "../../services/ProfilePageServices";
import Wallets from "./Wallet/Wallets";
import Address from "./Address/Address";
import Terms from "./TermsCondition/Terms";
import MyorderTrackPopup from "./Popup/MyOrder/MyorderTrackPopup";
import Favourite from "./Favourite/Favourite";
import { GrContactInfo, GrMap ,GrLock  } from "react-icons/gr";
import { getUserData } from "../../constants/Utils";
import { getName } from "../../constants/Utils";
import "./ProfilePage.css";
import ProfileData from "./Popup/MyOrder/ProfileData";
import { AiFillMessage, AiFillStar, AiOutlineMenu } from "react-icons/ai";
import { MdCall, MdFavorite } from "react-icons/md";
import { IoWalletSharp } from "react-icons/io5";
import { BsFillExclamationSquareFill } from "react-icons/bs";
import { Collapse, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import ProfileRatingData from "./ProfileRatingData";
import ChangePassword from "./Change Password/ChangePassword";
function CartView({ addressView, payNowWallet }) {
  let userName = getUserData()?.fullname;
  //Active class
  const [active, setActive] = useState(true);

  const location = useLocation();

  // show myorder or dine in
  const [showorder, setShowOrder] = useState(true);
  const [myorder, setMyorder] = useState();
  const [tabValue, setTabValue] = useState("1");
  const [open, setOpen] = useState(false);
  const handleClicked = (value) => {
    setTabValue(value);
  };
  const {state} = useLocation();

  useEffect(()=>{
   if (state?.passKey==1){
    setMyorder("2")

   }else{
    setMyorder("1")

   }
  },[])
  
  const handleClick = (value) => {
    setMyorder(value);
  };
  sessionStorage.setItem("myOrdersState",myorder)
  const [showLocation, setShowLocation] = useState(false);
  const showLocationPopup = () => setShowLocation(true);

  const LocationPopUp = (value) => {
    setShowLocation(value);
  };
  const [profileDetailsData, setProfileDetailsData] = useState([]);
  const [profileName, setProfileName] = useState(JSON.parse(sessionStorage.getItem("otpResponse"))?.fullname)
  const [isProfileUpdate,setisProfileUpdate]=useState()
  const ProfiledetailValuesApi = async () => {
    let postProfiledetailObject = {
      page:0,
      limit:1000
    };

    try {
      let ProfiledetailResponse = await ProfiledetailApi(
        postProfiledetailObject
      );
      setProfileDetailsData(ProfiledetailResponse.data.data);
      setProfileName(ProfiledetailResponse.data.data?.user_details?.fullname)
      const data = window.sessionStorage.getItem("otpResponse");
      const newData = JSON.parse(data);
      sessionStorage.setItem(
        "otpResponse",
        JSON.stringify({
          ...newData,
          fullname: ProfiledetailResponse.data.data?.user_details?.fullname,
        })
      );
    } catch (e) {}
  };
  useEffect(() => {
    ProfiledetailValuesApi();
    if (addressView) {
      setTabValue("3");
    }

    if (payNowWallet) {
      setTabValue("2");
    }
  }, []);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({}));

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
 

  return (
    <>
      {showorder === (tabValue === "1") ? (
        <div className="profile-heading-container ">
          <div className="profile-heading-titles mt-5">
            <p
              className={active === (myorder === "1") ? "active" : ""}
              onClick={() => handleClick("1")}
            >
              My Orders
            </p>
            <p
              className={active === (myorder === "2") ? "active" : ""}
              onClick={() => handleClick("2")}
            >
              Dine In
            </p>
          </div>
        </div>
      ) : null}
      <div className="profile-container">
        <div className={tabValue!=="1"?"profile-left-container profile-left-orders":"profile-left-container"}>
          <div>
            <img
              className="profile-icon"
              src={profile}
              width="100%"
              alt=""
            />
            <div className="user-detail" onClick={showLocationPopup}>
              {profileName === null ? "Sign Up" : profileName}
            </div>
            <div className="email-data" style={{fontSize:"14px",width:"auto"}} onClick={showLocationPopup}>{getUserData()?.email}</div>
          </div>
          <div
            className={
              active === (tabValue === "1")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("1")}
          >
            <HiShoppingBag />
            <p>My Order</p>
          </div>

          <div
            className={
              active === (tabValue === "2")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("2")}
          >
            <IoWalletSharp />
            <p>Wallet</p>
          </div>
          <div
            className={
              active === (tabValue === "3")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("3")}
          >
            <GrMap />
            <p>Address</p>
          </div>

          <div
            className={
              active === (tabValue === "7")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("7")}
          >
            <GrLock  />
            <p>Change Password</p>
          </div>

          <div
            className={
              active === (tabValue === "4")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("4")}
          >
            <MdFavorite />
            <p>Favourite</p>
          </div>
          <div
            className={
              active === (tabValue === "5")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("5")}
          >
            <AiFillStar />
            <p>Reviews</p>
          </div>

          <div
            className={
              active === (tabValue === "6")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("6")}
          >
            <BsFillExclamationSquareFill />
            <p>Terms & Condition</p>
          </div>
        </div>
        <div className="profile-left-container-mobile">
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
            

          >
            <div>
              <img
                className="profile-icon"
                src={profile}
                width="100%"
                alt="profile"
              />
              <div className="user-detail" onClick={showLocationPopup}>
                {userName ? userName : userName === null ? "Sign Up" : null}
              </div>
              <div className="email-data">{getUserData()?.email}</div>
              <div>
                <AiOutlineMenu onClick={handleExpandClick} />
              </div>
            </div>
          </ExpandMore>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div
              className={
                active === (tabValue === "1")
                  ? "active profile-items"
                  : "profile-items"
              }
              onClick={() => handleClicked("1")}
            >
              <HiShoppingBag />
              <p>My Order</p>
            </div>

            <div
              className={
                active === (tabValue === "2")
                  ? "active profile-items"
                  : "profile-items"
              }
              onClick={() => handleClicked("2")}
            >
              <IoWalletSharp />
              <p>Wallet</p>
            </div>
            <div
              className={
                active === (tabValue === "3")
                  ? "active profile-items"
                  : "profile-items"
              }
              onClick={() => handleClicked("3")}
            >
              <GrMap />
              <p>Address</p>
            </div>
            <div
            className={
              active === (tabValue === "7")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("7")}
          >
            <GrLock  />
            <p>Change Password</p>
          </div>

            <div
              className={
                active === (tabValue === "4")
                  ? "active profile-items"
                  : "profile-items"
              }
              onClick={() => handleClicked("4")}
            >
              <MdFavorite />
              <p>Favourite</p>
            </div>

            <div
            className={
              active === (tabValue === "5")
                ? "active profile-items"
                : "profile-items"
            }
            onClick={() => handleClicked("5")}
          >
            <AiFillStar />
            <p>Reviews</p>
          </div>
            <div
              className={
                active === (tabValue === "6")
                  ? "active profile-items"
                  : "profile-items"
              }
              onClick={() => handleClicked("6")}
            >
              <BsFillExclamationSquareFill />
              <p>Terms & Condition</p>
            </div>
          </Collapse>
        </div>
        {showorder === (tabValue === "1") ? (
          <div className="profile-heading-container-mobile">
            <div className="profile-heading-titles">
              <p
                className={active === (myorder === "1") ? "active" : ""}
                onClick={() => handleClick("1")}
              >
                My Orders
              </p>
              <p
                className={active === (myorder === "2") ? "active" : ""}
                onClick={() => handleClick("2")}
              >
                Dine In
              </p>
            </div>
          </div>
        ) : null}
        <div className={tabValue!=="1"?"profile-right-container profile-left-orders":"profile-right-container"}>
          {tabValue === "1" && myorder === "1" ? <Myorders /> : null}
          {tabValue === "1" && myorder === "2" ? <DineinCart /> : null}
          {tabValue === "2" ? <Wallets /> : null}
          {tabValue === "3" ? <Address addressView={addressView} /> : null}
          {tabValue === "7" ? <ChangePassword /> : null}
          {tabValue === "4" ? <Favourite /> : null}
          {tabValue === "5" ? <ProfileRatingData  profileDetailsData={profileDetailsData} /> : null}

          {tabValue === "6" ? <Terms /> : null}
          {tabValue === "8" ? null : null}
          {myorder === 2 ? <DineinCart /> : null}
        </div>
      </div>

      <ProfileData
        showLocation={showLocation}
        LocationPopUp={LocationPopUp}
        profileDetailsData={profileDetailsData}
        ProfiledetailValuesApi={ProfiledetailValuesApi}
      />
    </>
  );
}

export default CartView;
