import React from "react";
import Login from "../../components/PaymentandLogin/LoginPayment";
function Payment() {
  return (
    <>
      <Login />
    </>
  );
}

export default Payment;
