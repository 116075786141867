import { React, useRef, useState } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import profile from "../../../../Asserts/Cartview/profile.png";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { getUserData, getName } from "../../../../constants/Utils";
import DeleteAccountPopUp from "./DeleteAccountPopUp";
import "./ProfileData.css";
import { useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import { updateProfiledetailApi } from "../../../../services/ProfilePageServices";
function ProfileData({
  LocationPopUp,
  showLocation,
  profileDetailsData,
  ProfiledetailValuesApi,
}) {
  const inputFile = useRef(null);
  const [isEditable, setIsEditable] = useState(false);
  const [profileImage, setProfileImage] = useState(profile);
  const CloseLocationPopUp = () => {
    LocationPopUp(false); //callback function
    setIsEditable(false);

  };
  const cancelEditPopup=()=>{
    setIsEditable(false);

  }
  let userName = getUserData()?.fullname;
 
  const [showDeleteData, setShowDeleteData] = useState(false);
  const showDeleteAccountPopupData = () => setShowDeleteData(true);

  const DeleteAccountPopUpData = (value) => {
    setShowDeleteData(value);
  };
  let profileDataValues = profileDetailsData?.user_details;

  const handleEdit = () => {
    setIsEditable(true);
  };

  const [inputs, setInputs] = useState({
    fullname: "",
    mobile: "",
    email: "",
  });

  useEffect(() => {
    setInputs({
      fullname: userName,
      mobile: getUserData()?.mobile,
      email: getUserData()?.email,
    });
  }, []);

  const handleOnChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdate = async () => {
    setIsEditable(false);
    let updateprofileDataResp = await updateProfiledetailApi(inputs);
    if (updateprofileDataResp?.data?.status === 200) {
      ProfiledetailValuesApi();
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <>
      <Modal
        className="profileContainer"
        show={showLocation}
        onHide={CloseLocationPopUp}
        animation={true}
      >
        <Modal.Header
          className="modal-header border-0"
          closeButton
        ></Modal.Header>
        <Row className="ProfileDataItems">
          <Col lg="4">
            <img
              src={profileImage}
              width="100%"
              alt=""
              className="ms-4 profileImg"
            />
            <Row>
              <Col lg="6" className="ms-3 icons">
                <MdEdit
                  className="EditProfileData"
                  onClick={() => handleEdit()}
                />
                <MdDelete
                  className="DeleteProfileData ms-3"
                  onClick={showDeleteAccountPopupData}
                />
                {/* <FiUpload
                  className="EditProfileData ms-3"
                  onClick={() => handleUpload()}
                /> */}
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  onChange={onImageChange}
                  style={{ display: "none" }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg="1" />
          <Col lg="7" className="ProfileDataProfile">
            <p>
              Name :{" "}
              {!isEditable ? (
                <span>
                  {inputs.fullname
                    ? inputs.fullname
                    : inputs.fullname === null
                    ? "Sign Up"
                    : userName}
                </span>
              ) : (
                <input
                  value={inputs.fullname}
                  name="fullname"
                  onChange={(e) => handleOnChange(e)}
                />
              )}
            </p>
            <p>
              Mobile Number :{" "}
              {!isEditable ? (
                <span>
                  {inputs.mobile
                    ? inputs.mobile
                    : profileDetailsData?.user_details?.mobile}
                </span>
              ) : (
                <input
                  value={
                    inputs.mobile
                      ? inputs.mobile
                      : profileDetailsData?.user_details?.mobile
                  }
                  name="mobile"
                  disabled={true}
                  onChange={(e) => handleOnChange(e)}
                />
              )}
            </p>
            <p>
              Email id :{" "}
              {!isEditable ? (
                <span>{inputs.email}</span>
              ) : (
                <input
                  value={inputs.email}
                  name="email"
                  disabled={true}
                  onChange={(e) => handleOnChange(e)}
                />
              )}
            </p>
            {isEditable ? (
              <Row className="updateButton">
                <div className="EditButtonProfilepop">
                <Button onClick={() => handleUpdate()} className="mx-3">Update</Button>
                <Button onClick={() => cancelEditPopup()}>Cancel</Button>
                </div>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Modal>
      <DeleteAccountPopUp
        showDeleteData={showDeleteData}
        DeleteAccountPopUpData={DeleteAccountPopUpData}
      />
    </>
  );
}

export default ProfileData;
