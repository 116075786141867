import { React, useState, useEffect } from "react";
import { Col, Row, Badge, Card } from "react-bootstrap";
import BasicTabs from "../CartItemsList/Tabpanelitems/TabPanel";
import "./CartItemBody.css";
import { useSelector } from "react-redux";
import { deliverLatLngData } from "../../../constants/Utils";
import Switch from "@mui/material/Switch";
import RatingStart from "../../../Asserts/RestaurentList/RatingStar.png";
import { FiSearch } from "react-icons/fi";
import moment from "moment";
import "moment-timezone";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { whishListPostApi } from "../../../services/HomePageServices";
import RatingsAddPopup from "./Tabpanelitems/RatingsAddPopup";
function CartItemBody({
  globalCheckoutDetails,
  globalCheckoutCallback,
  setaddRemoveVariantCount,
  addressData,
  productListingValuesApi,
  isVariant,
  productItems,
  filterProductItems,
  getGuestUserApi,
  restStatus,
  promoManualCode,
  loader,
}) {
  //Product Listing Data API :-
  //-----------------------------
  const [isToggle, setIsToggle] = useState();
  const [ratingData, setRatingData] = useState(false);
  const ratingsAddUpData = (value) => {
    setRatingData(value);
  };
  const showRatingPopupData = () => setRatingData(true);

  // const [productItems, setProductItems] = useState([]);
  // const [filterProductItems, setFilterProductItems] = useState([]);
  let cartRedirected = JSON.parse(sessionStorage.getItem("isCartRedirect"));


  useEffect(() => {
    if (
      sessionStorage.getItem("isDirectLanding") == 1 ||
      sessionStorage.getItem("isDirectLanding") == undefined
    ) {
      getGuestUserApi();
    }
  }, []);
  // useEffect(() => {
  //   setTimeout(() => {
  //     productListingValuesApi();
  //   }, 500);
  // }, [deliverLatLngData()?.lat]);

  const toggleSwitch = (event) => {
    setIsToggle(event.target.checked);
  };
  const [noData, setNoData] = useState();
  useEffect(() => {
    if (isVariant) {
      setNoData(Object.keys(isVariant)?.length);
    }
  }, [isVariant]);


  const handleFavourites = async (isfavourite) => {
    let postData = {
      restaurant_id: productItems?.restaurant_id,
      action_type: isfavourite,
    };
    let wishListResp = await whishListPostApi(postData);
    if (wishListResp) {
      productListingValuesApi();
    }
  };

  // const handleRatings = () => {
  //   ratingsAddUpData()

  // };

  return (
    <>
      {/*--------------------------------BODY Content------------------------*/}
      {loader ? (
        <p className="loadingTextLoader">Loading Please Wait...</p>
      ) : (
        <>
          {restStatus?.status == 200 ? (
            <>
              {productItems?.banner_image !== "" ? (
                <Row className="mt-5">
                  <Col className="ItemBanner mt-5">
                    <img
                      src={productItems?.banner_image}
                      alt=" "
                      className="ItemBanner "
                    />
                  </Col>

                  <Badge
                    className="Favourite_Badge_ProductListing"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {productItems.isWishList === "1" ? (
                      <AiFillHeart
                        className="favourite"
                        onClick={() => handleFavourites(2)}
                      />
                    ) : (
                      <AiOutlineHeart
                        className="favourite"
                        onClick={() => handleFavourites(1)}
                      />
                    )}
                  </Badge>
                </Row>
              ) : null}

              <Card
                className={
                  productItems?.banner_image !== ""
                    ? "cartitem_card"
                    : "cartitem_card  cartItemCardSpace"
                }
              >
                <div className="res-name-cont">
                  <div>
                    <h3>{productItems?.rest_name}</h3>
                    <div className="DescriptionProduct">
                      <b>{productItems?.res_description}</b>
                    </div>
                    <p className="address-size" style={{ fontWeight: "600" }}>
                      {productItems?.rest_unit_number
                        ? productItems?.rest_unit_number + ","
                        : null}
                      {productItems?.rest_pin_address
                        ? productItems?.rest_pin_address + ","
                        : null}
                      {productItems?.rest_street_address
                        ? productItems?.rest_street_address + ","
                        : null}
                      {productItems?.rest_postal_code
                        ? productItems?.rest_postal_code
                        : null}
                    </p>
                  </div>
                  <div className="VegOnlyDivProduct">
                    {sessionStorage.getItem("isBevarageVeg") != 3 ? (
                      <p>
                        {<Switch onChange={(e) => toggleSwitch(e)} />}
                        <small className="VegOnlyRestaurant">Veg Only</small>
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="abt-res">
                  <div
                    className="rating-res"
                    onClick={() => {
                      showRatingPopupData();
                    }}
                  >
                    <small>
                      <img
                        src={RatingStart}
                        alt="RatingCount"
                        className="Restaurent_Rating_star"
                      />
                    </small>
                    <small className="Rating_out_of">
                      {productItems?.avg_rating}/5
                      <br />
                    </small>
                    <small className="Rating_Count">Rating</small>
                  </div>
                  <div>
                    <div className="Ratings_verticalLine mt-2 "></div>
                  </div>
                  <div className="rating-res">
                    <p className="open_Hours">
                      Open Hours {productItems?.open_time} - {""}
                      {productItems?.close_time}
                      <br />
                      <span className="shopTimingText">
                        {/* Break Hours-{productItems?.break_start_time} AM -
                {productItems?.break_end_time} PM */}
                        Shop Timing
                      </span>
                    </p>
                  </div>
                  <div>
                    <div className="Ratings_verticalLine"></div>
                  </div>
                  <div className="rating-res">
                    <small className="delivery_Time">
                      {productItems?.del_prep_time}
                      <small className="kilometer_verticalLine">
                        {" "}
                        {productItems?.distance} KM
                      </small>
                    </small>
                    <br />
                    <small className="Delivery_Time">Preparation Time</small>
                  </div>
                </div>

                <div className="badge-res">
                  <div className="badge-cont-res">
                    <Badge className="delivery_Badge1">
                      Delivery Handled By&nbsp;
                      {productItems?.delivery_handled_by === "1"
                        ? productItems?.rest_name
                        : " Mr Merchant"}
                    </Badge>
                  </div>
                  <div className="badge-cont-res">
                    <Badge className="delivery_Badge2">
                      Minimum Order Value:S${productItems?.min_order_value}
                    </Badge>
                  </div>
                </div>

                <BasicTabs
                  ProductData={productItems}
                  filterProductItems={filterProductItems}
                  isToggle={isToggle}
                  globalCheckoutDetails={globalCheckoutDetails}
                  globalCheckoutCallback={globalCheckoutCallback}
                  isVariant={isVariant}
                  setaddRemoveVariantCount={setaddRemoveVariantCount}
                  productListingValuesApi={productListingValuesApi}
                  promoManualCode={promoManualCode}
                />
                <RatingsAddPopup
                  ratingData={ratingData}
                  ratingsAddUpData={ratingsAddUpData}
                  productItems={productItems}
                  productListingValuesApi={productListingValuesApi}
                />
              </Card>
            </>
          ) : (
            <p className="NodataFoundText">
              <FiSearch /> No data Found
            </p>
          )}
        </>
      )}
    </>
  );
}

export default CartItemBody;
